import React, { createContext, useState, useMemo } from "react";
import { Loading } from "./base";
import { getTabs } from "../lib/api/tab-access";

const defaultTabAccess = {
  "Dashboard / Executive Summary": [],
  "Dashboard / New": [],
  "Dashboard / Used": [],
  "Dashboard / Parts": [],
  "Dashboard / Service": [],
  "Dashboard / Body Shop": [],
  "Dashboard / Custom": [],
  "Group Dashboard / Executive Summary": [],
  "Group Dashboard / New": [],
  "Group Dashboard / Used": [],
  "Group Dashboard / Parts": [],
  "Group Dashboard / Service": [],
  "Group Dashboard / Body Shop": [],
  "Reports / OEM Reports": [],
  "Reports / Other BM Tools": [],
  "Reports / Performance Analysis": [],
  "Reports / Standard Reports": [],
  "Submission / Upload Schedule": [],
  "Submission / Download Templates": [],
  "Submission / Submit Data": [],
  "Submission / Submission Status": [],
  "Insights / Interpretation": [],
  "Insights / Data Preparation": [],
  "Insights / BM Process": [],
  "Insights / Industry Insights": [],
  "About / Meet the Team": [],
  "About / About MSXI BM Solution": [],
  "Notifications / Messages": [],
  "Admin / Home": [],
};

export type TabAccess = {
  "Dashboard / Executive Summary": string[];
  "Dashboard / New": string[];
  "Dashboard / Used": string[];
  "Dashboard / Parts": string[];
  "Dashboard / Service": string[];
  "Dashboard / Body Shop": string[];
  "Dashboard / Custom": string[];
  "Group Dashboard / Executive Summary": string[];
  "Group Dashboard / New": string[];
  "Group Dashboard / Used": string[];
  "Group Dashboard / Parts": string[];
  "Group Dashboard / Service": string[];
  "Group Dashboard / Body Shop": string[];
  "Reports / OEM Reports": string[];
  "Reports / Other BM Tools": string[];
  "Reports / Performance Analysis": string[];
  "Reports / Standard Reports": string[];
  "Submission / Upload Schedule": string[];
  "Submission / Download Templates": string[];
  "Submission / Submit Data": string[];
  "Submission / Submission Status": string[];
  "Insights / Interpretation": string[];
  "Insights / Data Preparation": string[];
  "Insights / BM Process": string[];
  "Insights / Industry Insights": string[];
  "About / Meet the Team": string[];
  "About / About MSXI BM Solution": string[];
  "Notifications / Messages": string[];
  "Admin / Home": string[];
};

export type TabAccessContextState = {
  tabs: TabAccess;
  loading: Loading;
  hydrate();
};

export const TabAccessContext = createContext<TabAccessContextState>({
  tabs: defaultTabAccess,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const TabAccessProvider = ({ children }) => {
  const [tabs, setTabs] = useState<TabAccess>(defaultTabAccess);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const value = useMemo(
    () => ({
      tabs,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const data = await getTabs();
          setTabs(data);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setTabs,
    }),
    [tabs, loading],
  );
  return <TabAccessContext.Provider value={value}>{children}</TabAccessContext.Provider>;
};
