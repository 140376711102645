import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const BodyShop: { [index: string]: CustomDashboardType } = {
  "BODY SHOP > SALES > Body Shop Total Sales": {
    name: "BODY SHOP > SALES > Body Shop Total Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Total Sales",
    tableNumber: 113,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Total RO": {
    name: "BODY SHOP > SALES > Total RO",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Total RO",
    tableNumber: 113,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Repair Orders | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop RO / Day": {
    name: "BODY SHOP > SALES > Body Shop RO / Day",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop RO / Day",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Repair Orders pd | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > SALES > Body Shop RO / Technician / Day": {
    name: "BODY SHOP > SALES > Body Shop RO / Technician / Day",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop RO / Technician / Day",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Repair Orders pd p Productive | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > SALES > Body Shop Sales / RO": {
    name: "BODY SHOP > SALES > Body Shop Sales / RO",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Sales / RO",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales p RO | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Hours Sold / RO": {
    name: "BODY SHOP > SALES > Hours Sold / RO",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Hours Sold / RO",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Hours Sold p RO | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Effective Labour Rate / Hour": {
    name: "BODY SHOP > SALES > Effective Labour Rate / Hour",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Effective Labour Rate / Hour",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Effective Labour Rate | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Breakeven Days": {
    name: "BODY SHOP > SALES > Breakeven Days",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Breakeven Days",
    tableNumber: 115,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Breakeven Days | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Breakeven RO": {
    name: "BODY SHOP > SALES > Breakeven RO",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Breakeven RO",
    tableNumber: 115,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Breakeven ROs | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Labour Sales": {
    name: "BODY SHOP > SALES > Body Shop Labour Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Labour Sales",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Labour Sales (TLS) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Labour Retail Sales": {
    name: "BODY SHOP > SALES > Body Shop Labour Retail Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Labour Retail Sales",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Retail Labour sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Labour Warranty Sales": {
    name: "BODY SHOP > SALES > Body Shop Labour Warranty Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Labour Warranty Sales",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Warranty Labour sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Labour Internal Sales": {
    name: "BODY SHOP > SALES > Body Shop Labour Internal Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Labour Internal Sales",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Internal Labour sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Paint & Material Sales": {
    name: "BODY SHOP > SALES > Body Shop Paint & Material Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Paint & Material Sales",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Body Shop Paint Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Sublet Sales": {
    name: "BODY SHOP > SALES > Body Shop Sublet Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Sublet Sales",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Sublet net sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Oil & Lubricant Sales": {
    name: "BODY SHOP > SALES > Body Shop Oil & Lubricant Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Oil & Lubricant Sales",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Oil Lubricants net sale | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > SALES > Body Shop Other Sales": {
    name: "BODY SHOP > SALES > Body Shop Other Sales",
    department: "BODY SHOP",
    category: "SALES",
    label: "Body Shop Other Sales",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftersales Other Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > BODY SHOP MIX": {
    name: "BODY SHOP > MIX > BODY SHOP MIX",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "BODY SHOP MIX",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Body Shop Total Sales": {
    name: "BODY SHOP > MIX > Body Shop Total Sales",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Total Sales",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour Sales": {
    name: "BODY SHOP > MIX > Labour Sales",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour Sales",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Labour Sales (TLS) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Non-Labour Sales": {
    name: "BODY SHOP > MIX > Non-Labour Sales",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Non-Labour Sales",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Non Labour Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Body Shop Total GP": {
    name: "BODY SHOP > MIX > Body Shop Total GP",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Total GP",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour GP": {
    name: "BODY SHOP > MIX > Labour GP",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour GP",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Labour GP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Non-Labour GP": {
    name: "BODY SHOP > MIX > Non-Labour GP",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Non-Labour GP",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Non Labour GP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour GP%": {
    name: "BODY SHOP > MIX > Labour GP%",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour GP%",
    tableNumber: 118,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Labour GP % of TLS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour Retail GP %": {
    name: "BODY SHOP > MIX > Labour Retail GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour Retail GP %",
    tableNumber: 118,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Retail Labour GP % of RLS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour Warranty GP %": {
    name: "BODY SHOP > MIX > Labour Warranty GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour Warranty GP %",
    tableNumber: 118,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Warranty Labour GP % of WLS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Labour Internal GP %": {
    name: "BODY SHOP > MIX > Labour Internal GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Labour Internal GP %",
    tableNumber: 118,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Internal Labour GP % of ILS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Paint & Material GP %": {
    name: "BODY SHOP > MIX > Paint & Material GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Paint & Material GP %",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Body Shop Paint GP % of Body Shop Paint Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Sublet GP %": {
    name: "BODY SHOP > MIX > Sublet GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Sublet GP %",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Sublet GP % of Sublet net sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Oil & Lubricant GP %": {
    name: "BODY SHOP > MIX > Oil & Lubricant GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Oil & Lubricant GP %",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Oil Lubricants GP % of Net Oil Lubricants sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > MIX > Other GP %": {
    name: "BODY SHOP > MIX > Other GP %",
    department: "BODY SHOP",
    category: "GROSS PROFIT",
    label: "Body Shop Other GP %",
    tableNumber: 116,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftersales Other GP % of Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > BODY SHOP RETAINED": {
    name: "BODY SHOP > RETAINED > BODY SHOP RETAINED",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "BODY SHOP RETAINED",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > BODY SHOP Total GP": {
    name: "BODY SHOP > RETAINED > BODY SHOP Total GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "BODY SHOP Total GP",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > BODY SHOP Total Expenses": {
    name: "BODY SHOP > RETAINED > BODY SHOP Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "BODY SHOP Total Expenses",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > BODY SHOP Total PBT": {
    name: "BODY SHOP > RETAINED > BODY SHOP Total PBT",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "BODY SHOP Total PBT",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Total Expenses % GP": {
    name: "BODY SHOP > RETAINED > Total Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Total Expenses % GP",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Variable Expenses % GP": {
    name: "BODY SHOP > RETAINED > Variable Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Variable Expenses  % GP",
    tableNumber: 120,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Personnel Expenses % GP": {
    name: "BODY SHOP > RETAINED > Personnel Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Personnel  Expenses % GP",
    tableNumber: 121,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Other Semi Variable Expenses % GP": {
    name: "BODY SHOP > RETAINED > Other Semi Variable Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Other Semi Variable Expenses % GP",
    tableNumber: 122,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Fixed Expenses % GP": {
    name: "BODY SHOP > RETAINED > Fixed Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Fixed Expenses % GP",
    tableNumber: 123,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Interest Expenses % GP": {
    name: "BODY SHOP > RETAINED > Interest Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Interest Expenses % GP",
    tableNumber: 124,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Allocation of Admin  Expenses % GP": {
    name: "BODY SHOP > RETAINED > Allocation of Admin  Expenses % GP",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Allocation of Admin  Expenses % GP",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Variable Expenses% Total Expenses": {
    name: "BODY SHOP > RETAINED > Variable Expenses% Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Variable Expenses% Total Expenses",
    tableNumber: 120,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Personnel Expenses% Total Expenses": {
    name: "BODY SHOP > RETAINED > Personnel Expenses% Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Personnel Expenses% Total Expenses",
    tableNumber: 121,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Other Semi Var.Exp.% Total Expenses": {
    name: "BODY SHOP > RETAINED > Other Semi Var.Exp.% Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Other Semi Var.Exp.% Total Expenses",
    tableNumber: 122,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Fixed Expenses% Total Expenses": {
    name: "BODY SHOP > RETAINED > Fixed Expenses% Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Fixed Expenses% Total Expenses",
    tableNumber: 123,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Interest Expenses % Total Expenses": {
    name: "BODY SHOP > RETAINED > Interest Expenses % Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Interest Expenses % Total Expenses",
    tableNumber: 124,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Allocation of Admin Expenses % Total Expenses": {
    name: "BODY SHOP > RETAINED > Allocation of Admin Expenses % Total Expenses",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Allocation of Admin Expenses % Total Expenses",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > RETAINED > Body Shop Cost Recovery": {
    name: "BODY SHOP > RETAINED > Body Shop Cost Recovery",
    department: "BODY SHOP",
    category: "EXPENSES",
    label: "Body Shop Cost Recovery",
    tableNumber: 178,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Body Shop | All Brand | All Model |  | SUM",
  },
  "BODY SHOP > ACTIVITY > BODY SHOP ACTIVITY": {
    name: "BODY SHOP > ACTIVITY > BODY SHOP ACTIVITY",
    department: "BODY SHOP",
    category: "OPERATIONAL ASSETS",
    label: "BODY SHOP ACTIVITY",
    tableNumber: 125,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > ACTIVITY > BODY SHOP Annualised Sales": {
    name: "BODY SHOP > ACTIVITY > BODY SHOP Annualised Sales",
    department: "BODY SHOP",
    category: "OPERATIONAL ASSETS",
    label: "BODY SHOP Annualised Sales",
    tableNumber: 113,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Projected Annualised Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ACTIVITY > BODY SHOP Average Operational Assets": {
    name: "BODY SHOP > ACTIVITY > BODY SHOP Average Operational Assets",
    department: "BODY SHOP",
    category: "OPERATIONAL ASSETS",
    label: "BODY SHOP Average Operational Assets",
    tableNumber: 125,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Operational Assets | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > ACTIVITY > Body Shop WIP Value": {
    name: "BODY SHOP > ACTIVITY > Body Shop WIP Value",
    department: "BODY SHOP",
    category: "OPERATIONAL ASSETS",
    label: "Body Shop WIP Value",
    tableNumber: 125,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > WIP Value | Body Shop | All Brand | All Model | | CLOSE",
  },
  "BODY SHOP > ACTIVITY > Body Shop WIP RO": {
    name: "BODY SHOP > ACTIVITY > Body Shop WIP RO",
    department: "BODY SHOP",
    category: "OPERATIONAL ASSETS",
    label: "Body Shop WIP RO",
    tableNumber: 125,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > WIP RO | Body Shop | All Brand | All Model | | CLOSE",
  },
  "BODY SHOP > ROOA > ROOA": {
    name: "BODY SHOP > ROOA > ROOA",
    department: "BODY SHOP",
    category: "MRA MODEL",
    label: "BODY SHOP ROOA",
    tableNumber: 126,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Operational Assets (ROOA) | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > ROOA > MIX": {
    name: "BODY SHOP > ROOA > MIX",
    department: "BODY SHOP",
    category: "MRA MODEL",
    label: "BODY SHOP MIX",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROOA > RETAINED": {
    name: "BODY SHOP > ROOA > RETAINED",
    department: "BODY SHOP",
    category: "MRA MODEL",
    label: "BODY SHOP RETAINED",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROOA > ACTIVITY": {
    name: "BODY SHOP > ROOA > ACTIVITY",
    department: "BODY SHOP",
    category: "MRA MODEL",
    label: "BODY SHOP ACTIVITY",
    tableNumber: 125,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > ROS (Income Statement) > ROS": {
    name: "BODY SHOP > ROS (Income Statement) > ROS",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop ROS",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Sales": {
    name: "BODY SHOP > ROS (Income Statement) > Sales",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Sales",
    tableNumber: 113,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > PBT": {
    name: "BODY SHOP > ROS (Income Statement) > PBT",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop PBT",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Dept. Profit % Net Sales": {
    name: "BODY SHOP > ROS (Income Statement) > Dept. Profit % Net Sales",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Dept. Profit % Net Sales",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest % of TNS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > EBIT% (PBIT%)": {
    name: "BODY SHOP > ROS (Income Statement) > EBIT% (PBIT%)",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop EBIT% (PBIT%)",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT % of Sales | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
    name: "BODY SHOP > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Variable Selling Gross % Gorss Profit",
    tableNumber: 120,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Variable Selling Gross % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Selling Gross % Gross Profit": {
    name: "BODY SHOP > ROS (Income Statement) > Selling Gross % Gross Profit",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Selling Gross % Gross Profit",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TGP | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Selling Gross % Net Sales": {
    name: "BODY SHOP > ROS (Income Statement) > Selling Gross % Net Sales",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Selling Gross % Net Sales",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TNS | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Gross Profit": {
    name: "BODY SHOP > ROS (Income Statement) > Gross Profit",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Gross Profit",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Expenses",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Variable Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Variable Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Variable Expenses",
    tableNumber: 120,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Personnel Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Personnel Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Personnel Expenses",
    tableNumber: 121,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Other Semi Variable Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Other Semi Variable Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Other Semi Variable Expenses",
    tableNumber: 122,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Fixed Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Fixed Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Fixed Expenses",
    tableNumber: 123,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Interest Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Interest Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Interest Expenses",
    tableNumber: 124,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Departmental Profit": {
    name: "BODY SHOP > ROS (Income Statement) > Departmental Profit",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Departmental Profit",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest (EAIT) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > Allocation of Admin Expenses": {
    name: "BODY SHOP > ROS (Income Statement) > Allocation of Admin Expenses",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop Allocation of Admin Expenses",
    tableNumber: 119,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > ROS (Income Statement) > EBIT": {
    name: "BODY SHOP > ROS (Income Statement) > EBIT",
    department: "BODY SHOP",
    category: "ROS (Income Statement)",
    label: "Body Shop EBIT",
    tableNumber: 127,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT (Profit Before Interest & Tax) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Labour GP / Productive / Month": {
    name: "BODY SHOP > PRODUCTIVITY > Labour GP / Productive / Month",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Labour GP / Productive / Month",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Labour Gross Profit per productive | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Labour Sales / Productive / Month": {
    name: "BODY SHOP > PRODUCTIVITY > Labour Sales / Productive / Month",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Labour Sales / Productive / Month",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Labour Sales per productive | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Technician Cost / Productive / Month": {
    name: "BODY SHOP > PRODUCTIVITY > Technician Cost / Productive / Month",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Technician Cost / Productive / Month",
    tableNumber: 118,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Labour COS p Technician | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > GP / Productive / Month": {
    name: "BODY SHOP > PRODUCTIVITY > GP / Productive / Month",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop GP / Productive / Month",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > GP / Employee / Month": {
    name: "BODY SHOP > PRODUCTIVITY > GP / Employee / Month",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop GP / Employee / Month",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Employee | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > RO / Technician / Day": {
    name: "BODY SHOP > PRODUCTIVITY > RO / Technician / Day",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop RO / Technician / Day",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Repair Orders pd p Productive | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Labour Sales / RO": {
    name: "BODY SHOP > PRODUCTIVITY > Labour Sales / RO",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Labour Sales / RO",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Labour Sales (TLS) p RO | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Hours Sold / RO": {
    name: "BODY SHOP > PRODUCTIVITY > Hours Sold / RO",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Hours Sold / RO",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Hours Sold p RO | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Effective Labour Rate / Hour": {
    name: "BODY SHOP > PRODUCTIVITY > Effective Labour Rate / Hour",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Effective Labour Rate / Hour",
    tableNumber: 114,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Effective Labour Rate | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Productivity": {
    name: "BODY SHOP > PRODUCTIVITY > Productivity",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Productivity",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Productivity % (Non Factorised) | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Efficiency": {
    name: "BODY SHOP > PRODUCTIVITY > Efficiency",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Efficiency",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Efficiency % | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Effectiveness": {
    name: "BODY SHOP > PRODUCTIVITY > Effectiveness",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Effectiveness",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Effectiveness % | Body Shop | All Brand | All Model | | SUM",
  },
  "BODY SHOP > PRODUCTIVITY > Workbay Utilization": {
    name: "BODY SHOP > PRODUCTIVITY > Workbay Utilization",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Workbay Utilization",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Workbay utilization | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Workbay Occupancy": {
    name: "BODY SHOP > PRODUCTIVITY > Workbay Occupancy",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Workbay Occupancy",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Workbay Occupancy | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Number of Workbay": {
    name: "BODY SHOP > PRODUCTIVITY > Number of Workbay",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Number of Workbay",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Number of Workbays | Body Shop | All Brand | All Model | | CLOSE",
  },
  "BODY SHOP > PRODUCTIVITY > Total Staff": {
    name: "BODY SHOP > PRODUCTIVITY > Total Staff",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Total Staff",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Total Employees | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Productive Staff": {
    name: "BODY SHOP > PRODUCTIVITY > Productive Staff",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Productive Staff",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Workshop Productives | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Support Staff incl. SA": {
    name: "BODY SHOP > PRODUCTIVITY > Support Staff incl. SA",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Support Staff incl. SA",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Non-Productives | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Body Shop SA": {
    name: "BODY SHOP > PRODUCTIVITY > Body Shop SA",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop SA",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Service Advisor | Body Shop | All Brand | All Model | | AVG",
  },
  "BODY SHOP > PRODUCTIVITY > Body Shop Ratio Productive : Non Productive": {
    name: "BODY SHOP > PRODUCTIVITY > Body Shop Ratio Productive : Non Productive",
    department: "BODY SHOP",
    category: "PRODUCTIVITY",
    label: "Body Shop Ratio Productive : Non Productive",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Productive to Non-Productive staff | Body Shop | All Brand | All Model | | AVG",
  },
};

export const BodyShopOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "BODY SHOP > OEM Supports Analysis > Body Shop Support from OEM": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Support from OEM",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Support from OEM",
    measure: "G > OEM Incentives & Expenses Subsidy | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Incentives",
    measure: "G > OEM Incentives | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Expenses Subsidy": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Expenses Subsidy",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Expenses Subsidy",
    measure: "TE > OEM Expenses Subsidy | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Direct Advertising Subsidy": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Direct Advertising Subsidy",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Direct Advertising Subsidy",
    measure: "VE > Advertising Subsidy | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Indirect Advertising Subsidy": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Indirect Advertising Subsidy",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Indirect Advertising Subsidy",
    measure: "SVE > Indirect Advertising Subsidy | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives % TGP": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives % TGP",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Incentives % TGP",
    measure: "G > OEM Incentives % TGP UsedF&IAftercare | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives % Sales": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Incentives % Sales",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Incentives % Sales",
    measure: "G > OEM Incentives % Net Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM Expenses Subsidy % Sales": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM Expenses Subsidy % Sales",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM Expenses Subsidy % Sales",
    measure: "TE > OEM Expenses Subsidy % Net Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop OEM All Supports % Sales": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop OEM All Supports % Sales",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop OEM All Supports % Sales",
    measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop MIX": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop MIX",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop MIX",
    measure: "G > Gross Profit % of Sales (MIX) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop MIX (excl Incentive)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop MIX (excl Incentive)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop MIX (excl Incentive)",
    measure: "G > Gross Profit (excl Incentive) % of Net Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop RETAINED",
    measure: "PBT > PBT % of TGP: RETAINED | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl All Supports from OEM)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl All Supports from OEM)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop RETAINED (excl All Supports from OEM)",
    measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl Incentives)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl Incentives)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop RETAINED (excl Incentives)",
    measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl OEM Expenses Subsidy)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop RETAINED (excl OEM Expenses Subsidy)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop RETAINED (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses % TGP",
    measure: "TE > Total Expenses % of TGP | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl All Supports from OEM)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl All Supports from OEM)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses % TGP (excl All Supports from OEM)",
    measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl Incentives)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl Incentives)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses % TGP (excl Incentives)",
    measure: "TE > Total Expenses % of TGP (excl Incentive) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl OEM Expenses Subsidy)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses % TGP (excl OEM Expenses Subsidy)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses % TGP (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop ROS": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop ROS",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop ROS",
    measure: "PBT > PBT % of Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl All Supports from OEM)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl All Supports from OEM)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop ROS (excl All Supports from OEM)",
    measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl Incentives)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl Incentives)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop ROS (excl Incentives)",
    measure: "PBT > PBT (excl OEM Incentive) % of Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl OEM Expenses Subsidy)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop ROS (excl OEM Expenses Subsidy)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop ROS (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Sales": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Sales",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Sales",
    measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop GP": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop GP",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop GP",
    measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop GP (excl Incentives)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop GP (excl Incentives)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop GP (excl Incentives)",
    measure: "G > Gross Profit (excl Incentive) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses",
    measure: "TE > Total Expenses | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop Expenses (excl OEM Expenses Subsidy)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop Expenses (excl OEM Expenses Subsidy)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop Expenses (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses (excl Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop PBT": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop PBT",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop PBT",
    measure: "PBT > PBT (Profit Before Tax) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl All Supports from OEM)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl All Supports from OEM)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop PBT (excl All Supports from OEM)",
    measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl Incentives)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl Incentives)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop PBT (excl Incentives)",
    measure: "PBT > PBT (excl OEM Incentive) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
  "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl OEM Expenses Subsidy)": {
    name: "BODY SHOP > OEM Supports Analysis > Body Shop PBT (excl OEM Expenses Subsidy)",
    department: "BODY SHOP",
    category: "OEM Supports Analysis",
    label: "Body Shop PBT (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT (excl OEM Expenses Subsidy) | Body Shop | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 533,
  },
};
