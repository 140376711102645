import { request } from "./base";

export type MenuItem = {
  reportType: string;
  reportName: string;
  dataSetId: number;
  reportSubPath: string;
  exportPDF: boolean;
  exportXLS: boolean;
  tooltip: string;
  url: string;
  folder?: string;
  docType?: "xls" | "pdf";
  description: string;
};

const types = {
  COMPOSITE: "Composite Reports",
  ANALYTICAL: "Analytical Reports",
  REPORTTOOLS: "Submission Templates",
};

const reportDocType = (item: any) => {
  if (item.exportPDF) {
    return "pdf";
  }
  if (item.exportXLS) {
    return "xls";
  }
  return null;
};

export const getReportMenuItems = async (language: string = "English"): Promise<{ [index: string]: { [index: string]: MenuItem[] } }> => {
  const res = await request("reportmenu", {
    method: "GET",
  });
  const result = await res.json();
  const data = result.data;
  return Object.keys(data).reduce((reports, key) => {
    if (key.split(" | ")[1] === language) {
      const reportPath = data[key].reportSubPath.replace("/Dashboard/", "");

      const report = {
        ...data[key],
        reportSubPath: reportPath.includes("/") ? reportPath.split("/")[0] : reportPath,
        folder: reportPath.includes("/") ? reportPath.split("/")[1] : "",
        reportType: types[data[key].reportType] || "Dealer Reports",
        docType: reportDocType(data[key]),
      };

      reports[report.reportSubPath] = reports[report.reportSubPath] || {};
      reports[report.reportSubPath][report.reportType] = reports[report.reportSubPath][report.reportType] || [];
      reports[report.reportSubPath][report.reportType].push(report);
    }
    return reports;
  }, {});
};
