import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core/styles";
import { Dealer } from "./lib/api/charts";
import { Loading } from "./components/Loading";
import { DealerContext } from "./contexts/DealerContext";
import { CriteriaContext } from "./contexts/CriteriaContext";
import { Select } from "./components/Select";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { DashboardConfigContext } from "./contexts/DashboardConfigContext";
import { GroupDealer } from "./GroupDealerFilter";
import Checkbox from "@material-ui/core/Checkbox";
import { WithWidthOptions, WithWidth } from "@material-ui/core/withWidth";
import { DealerSelection } from "./pages/admin/DealerSelection";
import { SubmissionReloadContext } from "./pages/admin/SubmissionReloadContext";
import { FormattedMessage } from "react-intl";
import { CubeReloadContext } from "./pages/admin/cube-reload/CubeReloadContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    style: {
      padding: theme.spacing.unit * 2,
      minWidth: 400,
      backgroundColor: "whitesmoke",
      display: "flex",
      flexDirection: "column",
      maxHeight: "90vh",
      overflowY: "scroll",
      [theme.breakpoints.up("xs")]: {
        minWidth: "300px !important",
      },
    },
    nopad: {
      padding: 0,
    },
    fields: {},
    control: {
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    input: {
      marginTop: theme.spacing.unit * 2,
      backgroundColor: "white",
    },
    title: {
      fontWeight: "bold",
      fontSize: theme.typography.fontSize * 1.2,
    },
    dealerList: {
      overflow: "hidden",
      overflowY: "scroll",
    },
    button: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      backgroundColor: "#fff",
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formLabelText: {
      paddingBottom: theme.spacing.unit * 1,
      fontSize: "0.8rem",
    },
    tableGrid: {
      marginTop: "20px",
      height: "200px",
      overflowY: "scroll",
      border: "1px solid #ddd",
    },
    mainModal: {
      maxHeight: "100%",
      height: "100%",
      maxWidth: "600px",
    },
    actionButtons: {
      float: "right",
    },
    tableHeader: {
      backgroundColor: "white",
      fontSize: theme.typography.fontSize * 1,
    },
    tableBody: {
      backgroundColor: "white",
    },
    clickable: {
      cursor: "pointer",
    },
    inputText: {
      backgroundColor: "white",
      padding: 9,
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    innerInputLabel: {
      transform: "translate(12px, 11px) scale(1)",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    selectInputs: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: "0.8rem",
    },
    hr: {
      width: "95%",
      marginTop: 15,
    },
    code: {
      width: 100,
    },
    cellHead: {
      backgroundColor: "#f3f3f3",
    },
    selectInput: {
      padding: 0,
    },
    filters: {
      width: "100%",
    },
    moreFiltersBtn: {
      marginTop: "10px",
    },
  });

/**
 * TODO: Add types to props
 */
export type DealerFilterProps = {
  className: any;
  max?: any;
  selectedDealers?: any;
  selectDealer?: any;
  selectAll?: any;
  hideTitle?: any;
  setFilterSet?: any;
  hideButtons?: any;
  shrinkInputLabels?: any;
  classes?: any;
  filteredDealers?: any;
  liveFilteredDealers?: any;
  dealerCode?: any;
  dealerName?: any;
  setDealerCode?: any;
  setDealerName?: any;
  filters?: any;
  updateFilter?: any;
  toggleModal?: any;
  setFilters?: any;
  filterSet?: any;
  selection: "dashboard" | "group-dashboard" | "submission-reload" | "cube-reload";
  setDealer: any;
} & WithStyles<typeof styles> &
  React.HTMLAttributes<HTMLDivElement>;

export const DealerFilterUnstyled: React.FunctionComponent<DealerFilterProps> = ({
  classes,
  shrinkInputLabels,
  filteredDealers,
  liveFilteredDealers,
  hideTitle,
  filterSet,
  hideButtons,
  dealerCode,
  dealerName,
  setDealerCode,
  setDealerName,
  filters,
  className,
  updateFilter,
  max = 50,
  selectAll,
  toggleModal,
  selectDealer,
  selectedDealers,
  setFilters,
  setFilterSet,
  selection,
  setDealer,
}) => {
  const { criteria, loading } = useContext(CriteriaContext);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [dealerVisibilty, setDealerVisiblity] = React.useState(false);
  const { configs } = React.useContext(DashboardConfigContext);
  const settings = (configs && configs.settings) || { oems: [], country: [] };
  const [xselectedDealers, xsetSelectedDealers] = useState<Dealer[]>(selectedDealers || []);
  const { selectedDealers: submissionDealers, setSelectedDealers } = React.useContext(SubmissionReloadContext);
  const { selectedDealers: cubeReloadDealers, setSelectedDealers: setCubeReloadDealers } = React.useContext(CubeReloadContext);

  const [error, setError] = React.useState(true);
  const [touched, setTouched] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [tempDealers, setTempDealers] = React.useState([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  React.useEffect(() => {
    if (selection == "group-dashboard") {
      setError(false);
      return;
    }
    if (selection == "submission-reload" || selection == "cube-reload") {
      setError(false);
      return;
    }
    if (!selectedValue) {
      return setError(true);
    }
    setError(false);
  }, [selection, selectedValue]);

  const filter = () => {
    setFilterSet({
      dealerCode: dealerCode,
      dealerName: dealerName,
      filters: filters,
    });
  };

  const config = configs["settings"] || { oems: [], country: [] };

  const minimizeFilters = (e: React.MouseEvent) => {
    setShowFilters(!showFilters);
  };
  return (
    <div className={classnames(classes.style, className)}>
      {loading.loading ? (
        <Loading />
      ) : (
        <div className={classes.mainModal}>
          {!hideTitle && (
            <Typography className={classes.title} component="h3">
              <FormattedMessage id="dealer.search.header" />
            </Typography>
          )}
          <form
            onSubmit={e => {
              e.preventDefault();
              setDealerVisiblity(true);
              filter();
            }}
          >
            <Grid container item xs={12}>
              <Grid container spacing={8} item xs={12}>
                <Grid item xs={6}>
                  <FormControl className={classes.control}>
                    <FormLabel className={classes.formLabelText}>
                      <FormattedMessage id="dealer.search.dealer.code.label" />
                    </FormLabel>
                    <TextField
                      fullWidth
                      value={dealerCode.value}
                      onChange={e => setDealerCode({ value: e.target.value })}
                      name="dealerCode"
                      variant="outlined"
                      inputProps={{
                        className: classes.inputText,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.control}>
                    <FormLabel className={classes.formLabelText}>
                      <FormattedMessage id="dealer.search.dealer.name.label" />
                    </FormLabel>
                    <TextField
                      fullWidth
                      value={dealerName.value}
                      onChange={e => setDealerName({ value: e.target.value })}
                      name="dealerName"
                      variant="outlined"
                      inputProps={{
                        className: classes.inputText,
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={8} item xs={12}>
                <Grid item xs={12}>
                  <Button onClick={minimizeFilters} className={classes.moreFiltersBtn}>
                    <FormattedMessage id="dealer.search.show.more.filters.button" />
                    {showFilters ? " -" : " +"}
                  </Button>
                </Grid>
              </Grid>
              <span hidden={!showFilters} className={classes.filters}>
                <Grid container item xs={12}>
                  <Grid container spacing={8} item xs={12}>
                    <Grid item xs={6}>
                      <FormControl className={classes.control}>
                        <FormLabel className={classes.formLabelText}>
                          <FormattedMessage id="country.label" />
                        </FormLabel>
                        <Select
                          fullWidth
                          isMulti
                          value={criteria.countries.filter(country => filters.country.includes(country.value))}
                          options={[{ value: "", label: "" }, ...criteria.countries.filter(v => config.country.includes(v.value))]}
                          onChange={updateFilter("country")}
                          className={classes.selectInputs}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {criteria.oems.length > 0 ? (
                        <FormControl className={classes.control}>
                          <FormLabel className={classes.formLabelText}>
                            <FormattedMessage id="oem.label" />
                          </FormLabel>
                          <Select
                            fullWidth
                            isMulti
                            value={criteria.oems.filter(oem => filters.oem.includes(oem.value))}
                            options={[
                              { value: "", label: "" },
                              ...criteria.oems.filter((oem: any) => {
                                const inOems = settings.oems.length > 0 ? settings.oems.includes(oem.value) : true;
                                const inSettings = settings.country.length > 0 ? settings.country.includes(oem.country) : true;
                                const inCountries = filters.country.length > 0 ? filters.country.includes(oem.country) : true;
                                return inOems && inSettings && inCountries;
                              }),
                            ]}
                            onChange={updateFilter("oem")}
                            className={classes.selectInputs}
                          />
                        </FormControl>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={8} item xs={12}>
                    <Grid item xs={6}>
                      {criteria.regions.length > 0 ? (
                        <FormControl className={classes.control}>
                          <FormLabel className={classes.formLabelText}>
                            <FormattedMessage id="dealer.search.region.label" />
                          </FormLabel>
                          <Select
                            fullWidth
                            isMulti
                            value={criteria.regions.filter(region => filters.region.includes(region.value))}
                            options={[
                              { value: "", label: "" },
                              ...criteria.regions.filter(region => liveFilteredDealers.find(dealer => dealer.regions.includes(region.value))),
                            ]}
                            onChange={updateFilter("region")}
                            className={classes.selectInputs}
                          />
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      {criteria.dealerSets.length > 0 ? (
                        <FormControl className={classes.control}>
                          <FormLabel className={classes.formLabelText}>
                            <FormattedMessage id="dealer.search.dealer.set.label" />
                          </FormLabel>
                          <Select
                            fullWidth
                            isMulti
                            value={criteria.dealerSets.filter(dealerSet => filters.dealerSet.includes(dealerSet.value))}
                            options={[
                              { value: "", label: "" },
                              ...criteria.dealerSets.filter((s: any) => {
                                const matchesFilter = filters.oem.length > 0 ? filters.oem.includes(s.oem) : true;
                                const matchesSetting = settings.oems.length > 0 ? settings.oems.includes(s.oem) : true;
                                return matchesFilter && matchesSetting;
                              }),
                            ]}
                            onChange={updateFilter("dealerSet")}
                            className={classes.selectInputs}
                          />
                        </FormControl>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={8} item xs={12}>
                    <Grid item xs={6}>
                      {criteria.dealerGroups.length > 0 ? (
                        <FormControl className={classes.control}>
                          <FormLabel className={classes.formLabelText}>
                            <FormattedMessage id="dealer.search.dealer.group.label" />
                          </FormLabel>
                          <Select
                            fullWidth
                            isMulti
                            value={criteria.dealerGroups.filter(dealerGroup => filters.dealerGroup.includes(dealerGroup.value))}
                            options={[
                              { value: "", label: "" },
                              ...criteria.dealerGroups.filter(dealerGroup =>
                                liveFilteredDealers.find(dealer => dealer.dealerGroups.includes(dealerGroup.value)),
                              ),
                            ]}
                            onChange={updateFilter("dealerGroup")}
                            className={classes.selectInputs}
                          />
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.control}>
                        <FormLabel className={classes.formLabelText}>Exclude new dealers</FormLabel>
                        <Checkbox
                          checked={filters.excludeNewDealers}
                          onChange={e => {
                            setFilters({
                              ...filters,
                              excludeNewDealers: !filters.excludeNewDealers,
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </span>
              <Grid container item xs={12} className={classes.control}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <Button color="primary" type="submit">
                      <FormattedMessage id="search.button" />
                    </Button>
                    <Button
                      onClick={() => {
                        setFilters({
                          oem: [],
                          region: [],
                          dealerGroup: [],
                          dealerSet: [],
                          country: [],
                        });
                        setDealerCode({ value: "" });
                        setDealerName({ value: "" });
                        setDealerVisiblity(false);
                        setSelectedValue("");
                        filter();
                      }}
                    >
                      <FormattedMessage id="clear.button" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {(selection == "submission-reload" || selection == "cube-reload") && (
                <DealerSelection
                  tempDealers={tempDealers}
                  setTempDealers={setTempDealers}
                  filteredDealers={filteredDealers}
                  dealerVisibilty={dealerVisibilty}
                />
              )}
              {selection == "group-dashboard" && (
                // Group Dashboard Dealer filter tables
                <GroupDealer
                  multi={selection == "group-dashboard"}
                  max={max}
                  selectAll={selectAll}
                  setTouched={setTouched}
                  dealerVisibilty={dealerVisibilty}
                  filteredDealers={filteredDealers}
                  xselectedDealers={xselectedDealers}
                  xsetSelectedDealers={xsetSelectedDealers}
                  filters={filters}
                />
              )}
              {selection == "dashboard" && (
                // Dealer filter table
                <Grid item xs={12} className={classes.tableGrid}>
                  <Table>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell className={classnames(classes.cell, classes.cellHead)}>
                          <>
                            {max >= filteredDealers.length && (
                              <span className={classes.clickable} onClick={() => selectAll(filteredDealers.map(d => d.dealer))}>
                                <FormattedMessage id="all.label" /> | &nbsp;
                              </span>
                            )}
                            <span className={classes.clickable} onClick={() => selectAll([])}>
                              <FormattedMessage id="clear.button" />
                            </span>
                          </>
                        </TableCell>
                        <TableCell className={classnames(classes.cell, classes.cellHead, classes.code)}>
                          <FormattedMessage id="dealer.search.dealer.code.label" />
                        </TableCell>
                        <TableCell className={classnames(classes.cell, classes.cellHead)}>
                          <FormattedMessage id="dealer.search.dealer.name.label" />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredDealers.map((opt, indx: number) => {
                        return (
                          <TableRow key={indx} className={classes.tableBody}>
                            <TableCell className={classes.cell}>
                              <Radio
                                checked={selectedValue === opt.dealer}
                                className={classes.selectInput}
                                onChange={handleChange}
                                value={opt.dealer}
                                name="dealer"
                                color="default"
                                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                              />
                            </TableCell>
                            <TableCell className={classes.cell}>{opt.code}</TableCell>
                            <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              )}

              <Grid container item xs={12} className={classes.control}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    {!hideButtons && (
                      <Typography className={classes.title} component="h3">
                        {filteredDealers.filter(d => d.active).length} &nbsp;
                        <FormattedMessage id="dealers.active.label" />
                      </Typography>
                    )}
                  </Grid>
                  {!hideButtons && (
                    <Grid item xs={6} alignContent="flex-end">
                      <Button
                        disabled={error}
                        onClick={() => {
                          if (selection == "group-dashboard") {
                            if (touched) {
                              selectAll(xselectedDealers);
                            }
                          } else if (selection == "submission-reload" || selection == "cube-reload") {
                            if (tempDealers.length > 0) {
                              setSelectedDealers([...submissionDealers, ...tempDealers]);
                              setCubeReloadDealers([...cubeReloadDealers, ...tempDealers]);
                              setTempDealers([]);
                            }
                          } else {
                            setDealer(filteredDealers.find(d => d.dealer === selectedValue) || filteredDealers[0]);
                          }
                          toggleModal(false);
                        }}
                        color="primary"
                        className={classes.actionButtons}
                      >
                        <FormattedMessage id="ok.button" />
                      </Button>
                      <Button
                        onClick={() => {
                          if (selection == "submission-reload" || selection == "cube-reload") {
                            setTempDealers([]);
                          }
                          setFilters({
                            oem: [],
                            region: [],
                            dealerGroup: [],
                            dealerSet: [],
                            country: [],
                          });
                          setDealerCode({ value: "" });
                          setDealerName({ value: "" });
                          toggleModal(false);
                        }}
                        color="default"
                        className={classes.actionButtons}
                      >
                        <FormattedMessage id="cancel.button" />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
};

type FiltersType = {
  oem: any[];
  region: any[];
  dealerGroup: any[];
  dealerSet: any[];
  country: any[];
  excludeNewDealers: boolean;
};

type SettingsType = {
  oems: any[];
  country: any[];
};

export const filterDealers = (
  available: Dealer[],
  dealerCode,
  dealerName,
  filters: FiltersType,
  oem: boolean,
  dealerTypes: "consolidated" | "non-consolidated" | "all",
  submissionsWorkbenchEnabled: boolean,
  excludeSubmissionsWorkbenchEnabled: boolean,
  settings: SettingsType,
  availableOemsByCountry: any[],
) => {
  return available.filter(dealer => {
    return (
      (filters.excludeNewDealers ? dealer.minMonth && dealer.maxMonth : true) &&
      (dealerCode.value ? new RegExp(dealerCode.value, "gi").test(dealer.dealer) : true) &&
      (dealerName.value ? new RegExp(dealerName.value, "gi").test(dealer.dealer) : true) &&
      (dealer.active && filters.country.length > 0 ? dealer.oems.find(oem => availableOemsByCountry.find(countryOem => countryOem.code == oem)) : true) &&
      (filters.oem.length <= 0 && settings.oems.length > 0 ? dealer.oems.find(v => settings.oems.includes(v)) : true) &&
      (dealer.active && filters.oem.length > 0 ? dealer.oems.find(v => filters.oem.includes(v)) : true) &&
      (filters.region.length > 0 ? dealer.regions.find(v => filters.region.includes(v)) : true) &&
      (filters.dealerGroup.length > 0 ? dealer.dealerGroups.find(v => filters.dealerGroup.includes(v)) : true) &&
      (filters.dealerSet.length > 0 ? dealer.dealerSets.find(v => filters.dealerSet.includes(v)) : true) &&
      (oem ? dealer.isDashboardDealer : true) &&
      (submissionsWorkbenchEnabled ? dealer.active : true) &&
      (submissionsWorkbenchEnabled ? submissionsWorkbenchEnabled == dealer.submissionsWorkbenchEnabled : true) &&
      (dealerTypes.includes("all") ? true : dealerTypes.includes("non") ? !dealer.isConsolidated : dealer.isConsolidated)
    );
  });
};

export const DealerFilter = withStyles(styles)(DealerFilterUnstyled);
