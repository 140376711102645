import React, { createContext, useState, useMemo, useEffect } from "react";
import { Loading } from "../../contexts/base";
import differenceInMonths from "date-fns/differenceInMonths";
import addMonths from "date-fns/addMonths";
import BigNumber from "bignumber.js";
import {
  updateDataReaload,
  getDataReloadProgress,
  getChildDealers,
  dealerDataReset,
  getDataQuality,
  getDealerEndMonth,
  dealerCubeReloadForRange,
} from "../../lib/api/data-reload";
import format from "date-fns/format";
import { Dealer } from "../../lib/api/charts";
import { getMonth, getYear, addYears } from "date-fns";

const defaultSubmissionReload = [];

export type Dealers = {
  code: string;
  endMonth: Date;
  progress: number;
  quality: number;
  currentMonth: Date | null;
  finYear: string;
  failureReason: null | string;
};

export type SubmissionReloadContextState = {
  loading: Loading;
  dealersLoading: Loading;
  selectedDealers: Dealer[] & Dealers[];
  startMonth: Date;
  endMonth: Date;
  dealers: Dealer[] & Dealers[];
  removeDealers: any[];
  setSelectedDealers(dealers: Dealer[]): void;
  setStartMonth(date: Date): void;
  setEndMonth(date: Date): void;
  dataReload(startMonth: Date, endMonth: Date, mappingOption: string, keepThirdPartyData: boolean): void;
  dataReset(startMonth: Date, endMonth: Date, keepThirdPartyData: boolean): void;
  removeSelectedDealers(dealers: Dealers[]): void;
  setRemoveDealers(dealers: any[]): void;
  setDealers(dealers: Dealer[] & Dealers[]): void;
};

export const SubmissionReloadContext = createContext<SubmissionReloadContextState>({
  loading: { loading: false, loaded: false, error: null },
  dealersLoading: { loading: false, loaded: false, error: null },
  selectedDealers: [],
  startMonth: new Date(),
  endMonth: new Date(),
  dealers: [],
  removeDealers: [],
  setSelectedDealers: () => {},
  setStartMonth: () => {},
  setEndMonth: () => {},
  dataReload: () => {},
  dataReset: () => {},
  removeSelectedDealers: () => {},
  setRemoveDealers: () => {},
  setDealers: () => {},
});

export const SubmissionReloadProvider = ({ children }) => {
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [dealersLoading, setDealersLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [selectedDealers, setSelectedDealers] = useState<any[]>([]);
  const [startMonth, setStartMonth] = useState<Date>(new Date());
  const [endMonth, setEndMonth] = useState<Date>(new Date());
  const [dealers, setDealers] = useState<any[]>([]);
  const [removeDealers, setRemoveDealers] = useState<any[]>([]);

  const dataReload = async (startMonth: Date, endMonth: Date, mappingOption: "latest" | "original", keepThirdPartyData: boolean) => {
    try {
      setLoading({ loading: true, loaded: false, error: null });
      for (const dealer of dealers) {
        if (loading.error) throw loading.error;

        const monthsCount = differenceInMonths(endMonth, startMonth) + 1;

        if (monthsCount > 0) {
          const dealerMonths = new Array(monthsCount).fill(startMonth).map((month, indx) => addMonths(month, indx));

          for (let i = 0; i < dealerMonths.length; i++) {
            if (loading.error) throw loading.error;

            const dealerIndex = dealers.findIndex(d => d.code === dealer.code);

            const progress = new BigNumber(100)
              .dividedBy(new BigNumber(monthsCount))
              .multipliedBy(new BigNumber(i).plus(0.5))
              .decimalPlaces(0)
              .toNumber();

            dealers[dealerIndex] = { ...dealer, currentMonth: dealerMonths[i], progress };
            setDealers([...dealers]);

            try {
              await updateDataReaload({
                dealerCode: dealer.code,
                startMonth: format(startMonth, "yyyyMM"),
                endMonth: format(endMonth, "yyyyMM"),
                actmonth: format(dealerMonths[i], "yyyyMM"),
                progress,
                quality: progress,
                mappingOption,
                keepThirdPartyData,
              });
            } catch (e) {
              throw { e, dealer: dealers[dealerIndex] };
            }

            const { data } = await getDataQuality(dealer.code, format(dealerMonths[i], "yyyyMM"));

            const endProgress = new BigNumber(100)
              .dividedBy(new BigNumber(monthsCount))
              .multipliedBy(new BigNumber(i).plus(1))
              .decimalPlaces(0)
              .toNumber();

            dealers[dealerIndex] = { ...dealer, currentMonth: dealerMonths[i], progress: endProgress, quality: (data[0] || {})["quality_perc"] || 0 };
            setDealers([...dealers]);
            // const { data } = await getDataReloadProgress(dealer.code, format(startMonth, "yyyy-MM"), format(dealer.endMonth, "yyyy-MM"));

            // const index = dealers.findIndex(dealer => dealer.code === data[0].code);
            // dealers[index] = { ...dealer, ...data[0], endMonth: new Date(data[0].endMonth), currentMonth: dealerMonths[i] };
          }

          await dealerCubeReloadForRange({
            dealerCode: dealer.code,
            startMonth: format(dealerMonths[0], "yyyyMM"),
            endMonth: format(dealerMonths[dealerMonths.length - 1], "yyyyMM"),
          });
        }
      }
      setLoading({ loading: false, loaded: true, error: null });
    } catch ({ e, dealer }) {
      const error = e.error ? e.error : e.message;
      setLoading({ loading: false, loaded: true, error: error });
      throw { error, dealer };
    }
  };

  const dataReset = async (startMonth: Date, endMonth: Date, keepThirdPartyData: boolean) => {
    try {
      setLoading({ loading: true, loaded: false, error: null });
      for (const dealer of dealers) {
        if (loading.error) throw loading.error;

        const monthsCount = differenceInMonths(endMonth, startMonth) + 1;

        if (monthsCount > 0) {
          const dealerMonths = new Array(monthsCount).fill(startMonth).map((month, indx) => addMonths(month, indx));

          for (let i = 0; i < dealerMonths.length; i++) {
            if (loading.error) throw loading.error;

            const progress = new BigNumber(100)
              .dividedBy(new BigNumber(monthsCount))
              .multipliedBy(new BigNumber(i).plus(0.5))
              .decimalPlaces(0)
              .toNumber();

            const index = dealers.findIndex(d => dealer.code === d.code);
            dealers[index] = { ...dealer, currentMonth: dealerMonths[i], progress };
            setDealers([...dealers]);

            const childDealers = await getChildDealers(dealer.code);

            await Promise.all(
              childDealers.map(async dealerCode => {
                await dealerDataReset({
                  dealerCode: dealerCode,
                  startMonth: format(startMonth, "yyyyMM"),
                  endMonth: format(endMonth, "yyyyMM"),
                  actmonth: format(dealerMonths[i], "yyyyMM"),
                  progress,
                  quality: progress,
                  keepThirdPartyData,
                });
              }),
            );

            await dealerDataReset({
              dealerCode: dealer.code,
              startMonth: format(startMonth, "yyyyMM"),
              endMonth: format(endMonth, "yyyyMM"),
              actmonth: format(dealerMonths[i], "yyyyMM"),
              progress,
              quality: progress,
              keepThirdPartyData,
            });

            const endProgress = new BigNumber(100)
              .dividedBy(new BigNumber(monthsCount))
              .multipliedBy(new BigNumber(i).plus(1))
              .decimalPlaces(0)
              .toNumber();

            dealers[index] = { ...dealer, progress: endProgress };
            setDealers([...dealers]);
          }
        }
      }
      setLoading({ loading: false, loaded: true, error: null });
    } catch (e) {
      setLoading({ loading: false, loaded: true, error: e.error ? e.error : e.message });
      throw e.error ? e.error : e.message;
    }
  };

  const removeSelectedDealers = (dealers: Dealers[]) => {
    const filtered = selectedDealers.reduce((arr, dealer) => {
      if (!dealers.find(d => d.code === dealer.code)) {
        arr.push(dealer);
      }
      return arr;
    }, []);
    setSelectedDealers([...filtered]);
    setDealers([...filtered]);
    setRemoveDealers([]);
  };

  useEffect(() => {
    async function fetchDealerEndMonths() {
      setDealersLoading({ loading: true, loaded: false, error: null });
      const xDealers = [];
      for (let i = 0; i < selectedDealers.length; i++) {
        const endMonth = await computeEndMonth(selectedDealers[i].code, startMonth);
        xDealers.push({
          ...selectedDealers[i],
          progress: 0,
          quality: 0,
          currentMonth: null,
          endMonth: new Date(endMonth),
          failureReason: null,
        });
      }
      setDealers([...xDealers]);
      setDealersLoading({ loading: false, loaded: true, error: null });
    }
    fetchDealerEndMonths();
  }, [selectedDealers, startMonth]);

  const value = useMemo(
    () => ({
      loading,
      selectedDealers,
      startMonth,
      endMonth,
      setSelectedDealers,
      setStartMonth,
      setEndMonth,
      dataReload,
      dataReset,
      removeSelectedDealers,
      dealers,
      removeDealers,
      setRemoveDealers,
      setDealers,
      dealersLoading,
    }),
    [loading, selectedDealers, startMonth, endMonth, dealers, removeDealers, dealersLoading],
  );

  return <SubmissionReloadContext.Provider value={value}>{children}</SubmissionReloadContext.Provider>;
};

export const computeEndMonth = (dealerCode: string, startMonth: Date): Promise<string> =>
  getDealerEndMonth(dealerCode, format(startMonth, "yyyyMM")).then(res => res);
