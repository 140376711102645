import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Dealer, dealers as fetchDealers, ViewType } from "../lib/api/charts";
import { Loading } from "./base";
import { filterDealers } from "../DealerFilter";
import { userConfig } from "../lib/api/user";
import { BenchmarkContext } from "./BenchmarkContext";
import { DateContext } from "./FilterDateContext";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { OemDealerContext } from "./OemDealerContext";
import { CustomDealerContext } from "./CustomDealerContext";
import { DealerAnalysisDealerContext } from "./DealerAnalysisDealerContext";
import { NetworkAnalysisDealerContext } from "./NetworkAnalysisDealerContext";
import { GroupDateContext } from "./GroupFilterDateContext";
import { childDealers } from "../lib/api/dealers";
import { maxMonth } from "../GroupDealer";

export const defaultDealer = {
  dealer: "",
  code: "",
  oems: [],
  regions: [],
  brands: [],
  currency: "USD",
  dealerGroups: [],
  benchmarks: [],
  dealerSets: [],
  hasTargets: [false],
  isConsolidated: true,
  active: true,
  isDashboardDealer: false,
  minMonth: new Date().toJSON() as any,
  maxMonth: new Date().toJSON() as any,
  finYear: "",
  submissionsWorkbenchEnabled: false,
  OEMSpecificDash: false,
  disabledDealerUpload: false,
};

export type DealerCode = {
  value: string;
};
export type DealerName = {
  value: string;
};
export type Filters = {
  oem: any[];
  region: any[];
  dealerGroup: any[];
  dealerSet: any[];
  country: any[];
  excludeNewDealers: boolean;
};
export type FilterSet = {
  dealerCode: string;
  dealerName: string;
  filters: {
    oem: any[];
    region: any[];
    dealerGroup: any[];
    dealerSet: any[];
    country: any[];
    excludeNewDealers: boolean;
  };
};

export type ViewValue = {
  value: ViewType;
  label: string;
};

export type DealerContextState = {
  hydrate();

  available: Dealer[];
  loading: Loading;

  view: ViewType;
  setView(view: ViewType): void;

  selected: Dealer;
  filters: Filters;
  filterSet: FilterSet;
  dealerCode: DealerCode;
  dealerName: DealerName;
  submissionsDealer: Dealer;
  setDealerCode(dealerCode: DealerCode): void;
  setDealerName(dealerName: DealerName): void;
  setDealer(dealer: Dealer): void;
  setSubmissionsDealer(dealer: Dealer): void;
  setFilters(obj: Filters): void;
  setFilterSet(obj: FilterSet): void;

  selectedDealers: Dealer[];
  groupMinMonth: Date;
  groupMaxMonth: Date;
  setSelectedDealers(dealers: Dealer[]): void;
  setSelectedOemDealers(dealers: Dealer[]): void;
  setGroupMinMonth(date: Date): void;
  setGroupMaxMonth(date: Date): void;
  childrenDealers: string[];
};

export const DealerContext = createContext<DealerContextState>({
  hydrate: () => {},
  loading: { loading: false, loaded: false, error: null },

  available: [],

  view: "mra",
  setView: () => {},

  selected: defaultDealer,
  dealerCode: { value: "" },
  dealerName: { value: "" },
  submissionsDealer: defaultDealer,
  filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  filterSet: {
    dealerCode: "",
    dealerName: "",
    filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  },
  setDealerCode: () => {},
  setDealerName: () => {},
  setDealer: () => {},
  setSubmissionsDealer: () => {},
  setFilters: () => {},
  setFilterSet: () => {},

  selectedDealers: [],
  groupMinMonth: new Date(2015, 1, 1),
  groupMaxMonth: new Date(),
  setSelectedDealers: () => {},
  setSelectedOemDealers: () => {},
  setGroupMinMonth: () => {},
  setGroupMaxMonth: () => {},
  childrenDealers: [],
});

export const DealerProviderInner: FC = ({ children }) => {
  const [view, setView] = useState<ViewType>("mra");
  const [dealer, xsetDealer] = useState<Dealer>(defaultDealer);
  const [submissionsDealer, xsetSubmissionsDealer] = useState<Dealer>(defaultDealer);
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { date, updateDate } = useContext(DateContext);
  const setDealer = (dealer?: Dealer) => xsetDealer(dealer || defaultDealer);
  const setSubmissionsDealer = (dealer?: Dealer) => xsetSubmissionsDealer(dealer || defaultDealer);

  const { setBenchmark, selected: selectedBenchmarks, setOemBenchmark, setCustomBenchmark } = useContext(BenchmarkContext);

  const [dealerCode, setDealerCodeValue] = React.useState({ value: "" });
  const [dealerName, setDealerNameValue] = React.useState({ value: "" });
  const [filters, setFiltersValue] = React.useState({
    oem: [],
    region: [],
    dealerGroup: [],
    dealerSet: [],
    country: [],
    excludeNewDealers: false,
  });
  const [filterSet, setFilterSetValue] = React.useState({
    dealerCode: "",
    dealerName: "",
    filters: {
      oem: [],
      region: [],
      dealerGroup: [],
      dealerSet: [],
      country: [],
      excludeNewDealers: false,
    },
  });

  // Group Dashboard
  const [selectedDealers, setSelectedDealers] = useState<Dealer[]>([]);
  const [groupMinMonth, setGroupMinMonth] = useState<Date>();
  const [groupMaxMonth, setGroupMaxMonth] = useState<Date>();
  // const setSelectedOemDealers = (dealers?: Dealer[]) => setSelectedOemDealers(dealers || []);
  const [childrenDealers, setChildrenDealers] = useState<string[]>([]);

  const { setOemDealer } = useContext(OemDealerContext);
  const { setCustomDealer } = useContext(CustomDealerContext);
  const { setDealerAnalysisDealer } = useContext(DealerAnalysisDealerContext);
  const { setNetworkAnalysisDealer } = useContext(NetworkAnalysisDealerContext);

  const { date: groupDate, updateDate: updateGroupDate } = useContext(GroupDateContext);

  const value = useMemo(
    () => ({
      available: dealers,
      view,
      selected: {
        ...dealer,
        minMonth: new Date(dealer.minMonth as any),
        maxMonth: new Date(dealer.maxMonth as any),
      },
      dealerCode,
      dealerName,
      filters,
      filterSet,
      selectedDealers,
      groupMinMonth,
      groupMaxMonth,
      submissionsDealer,

      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const dealers = await fetchDealers();

          const oemDashboard: boolean = false;
          const submissionsWorkbenchEnabled: boolean = false;
          const exludeSubmissionsWorkbenchEnabled: boolean = false;

          await userConfig().then(async configs => {
            const dealer = filterDealers(
              dealers.dealers,
              "",
              "",
              {
                oem: [],
                region: [],
                dealerGroup: [],
                dealerSet: [],
                country: [],
                excludeNewDealers: false,
              },
              false,
              "all",
              submissionsWorkbenchEnabled,
              exludeSubmissionsWorkbenchEnabled,
              configs.settings || { oems: [], country: [] },
              [],
            ).filter(d => d.active)[0];

            const oemDealer = filterDealers(
              dealers.dealers,
              "",
              "",
              {
                oem: [],
                region: [],
                dealerGroup: [],
                dealerSet: [],
                country: [],
                excludeNewDealers: false,
              },
              true,
              "all",
              submissionsWorkbenchEnabled,
              exludeSubmissionsWorkbenchEnabled,
              configs.settings || { oems: [], country: [] },
              [],
            ).filter(d => d.active)[0];

            const submissionsDealer = filterDealers(
              dealers.dealers,
              "",
              "",
              {
                oem: [],
                region: [],
                dealerGroup: [],
                dealerSet: [],
                country: [],
                excludeNewDealers: false,
              },
              false,
              "non-consolidated",
              submissionsWorkbenchEnabled,
              exludeSubmissionsWorkbenchEnabled,
              configs.settings || { oems: [], country: [] },
              [],
            ).filter(d => d.active)[0];

            if (!dealer) {
              return setLoading({
                loading: false,
                loaded: true,
                error: new Error("There are no dealers for your selected filters"),
              });
            }

            setDealer(dealer);
            setOemDealer(oemDealer || defaultDealer);
            setCustomDealer(dealer);
            setDealerAnalysisDealer(dealer);
            setNetworkAnalysisDealer(oemDealer || defaultDealer);
            setSubmissionsDealer(submissionsDealer);

            setView(dealer.defaultView);

            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
              await setOemBenchmark(["", ""]);
              await setCustomBenchmark(["", ""]);
            } else if (dealer.benchmarks.length > 0 && dealer.defaultBMLimits) {
              await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              await setOemBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              await setCustomBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
            } else {
              await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              await setOemBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              await setCustomBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
            }

            if (dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
          });
          setDealers(dealers.dealers);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selectedBenchmarks.filter(bm => bm.length > 0).length > 0 && !dealer.defaultBMLimits) {
                await setBenchmark(selectedBenchmarks as [string, string]);
              } else if (selectedBenchmarks.filter(bm => bm.length > 0).length > 0 && dealer.defaultBMLimits) {
                await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            setDealer(dealer);
            setView(dealer.defaultView == "oemspecific" && !dealer.OEMSpecificDash ? "mra" : dealer.defaultView || "mra");
            if (dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            if (dealer.defaultBMLimits) {
              await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
            } else {
              await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
            }
          }
          setDealer(dealer);
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
        }
      },
      setSubmissionsDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selectedBenchmarks.filter(bm => bm.length > 0).length > 0 && !dealer.defaultBMLimits) {
                await setBenchmark(selectedBenchmarks as [string, string]);
              } else if (selectedBenchmarks.filter(bm => bm.length > 0).length > 0 && dealer.defaultBMLimits) {
                await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            setSubmissionsDealer(dealer);
            if (dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            if (dealer.defaultBMLimits) {
              await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
            } else {
              await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
            }
          }
          setSubmissionsDealer(dealer);
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
        }
      },
      setDealerCode: obj => {
        setDealerCodeValue(obj);
      },
      setDealerName: obj => {
        setDealerNameValue(obj);
      },
      setFilters: obj => {
        setFiltersValue(obj);
      },
      setFilterSet: obj => {
        setFilterSetValue(obj);
      },
      setView,
      setSelectedDealers,
      setGroupMinMonth,
      setGroupMaxMonth,
      setDealerCodeValue,
      setDealerNameValue,
      setFiltersValue,
      setFilterSetValue,
      setSelectedOemDealers: async (dealers: Dealer[]) => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            await setSelectedDealers(dealers);
            if (maxMonth(dealers.map(dealer => dealer.maxMonth))) {
              const d = new Date(Date.parse(maxMonth(dealers.map(dealer => dealer.maxMonth)).toString()));
              updateGroupDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
                maxMonth: maxMonth(dealers.map(dealer => dealer.maxMonth)).toString(),
              });
            }
            const consolidatedDealers = dealers.filter(dealer => dealer.isConsolidated);
            const nonConsolidatedDealers = dealers.filter(d => !d.isConsolidated).map(d => d.dealer);
            const cDealerNames = await childDealers(
              "",
              new Date(maxMonth(dealers.map(dealer => dealer.maxMonth)) ? maxMonth(dealers.map(dealer => dealer.maxMonth)).toString() : groupDate.value),
              consolidatedDealers.map(cDealer => cDealer.code),
            );
            const dealerWithKids = [...nonConsolidatedDealers, ...cDealerNames.dealers];
            await setChildrenDealers(dealerWithKids);
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        }
        await setSelectedDealers(dealers);
        if (maxMonth(dealers.map(dealer => dealer.maxMonth))) {
          const d = new Date(Date.parse(maxMonth(dealers.map(dealer => dealer.maxMonth)).toString()));
          updateGroupDate({
            value: formatFullDate(d),
            label: formatDisplayDate(d),
            maxMonth: maxMonth(dealers.map(dealer => dealer.maxMonth)).toString(),
          });
        }
        const consolidatedDealers = dealers.filter(dealer => dealer.isConsolidated);
        const nonConsolidatedDealers = dealers.filter(d => !d.isConsolidated).map(d => d.dealer);
        const cDealerNames = await childDealers(
          "",
          new Date(maxMonth(dealers.map(dealer => dealer.maxMonth)) ? maxMonth(dealers.map(dealer => dealer.maxMonth)).toString() : groupDate.value),
          consolidatedDealers.map(cDealer => cDealer.code),
        );
        const dealerWithKids = [...nonConsolidatedDealers, ...cDealerNames.dealers];
        await setChildrenDealers(dealerWithKids);
      },
      childrenDealers,
    }),
    [
      dealer,
      xsetDealer,
      selectedDealers,
      setSelectedDealers,
      groupMinMonth,
      setGroupMinMonth,
      groupMaxMonth,
      setGroupMaxMonth,
      dealers,
      setDealers,
      loading,
      setLoading,
      updateDate,
      setBenchmark,
      dealerCode,
      setDealerCodeValue,
      dealerName,
      setDealerNameValue,
      filters,
      filterSet,
      setFiltersValue,
      setFilterSetValue,
      setDealer,
      childrenDealers,
      view,
      setView,
    ],
  );

  return <DealerContext.Provider value={value}>{children}</DealerContext.Provider>;
};

export const DealerProvider = DealerProviderInner;
