import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import { translation } from "../translations/Translations";

export const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: "bold",
      color: "#2f2f2f",
      marginLeft: theme.spacing.unit * 2,
      cursor: "pointer",
    },
  });

export type ReportSelectorProps = {
  report: any;
  handleSelection?(report: any): void;
  title: String;
  chartOptions?: any;
} & WithStyles<typeof styles>;

const reportOptions = {
  mix: "MIX",
  retained: "Retained",
  activity: "Activity",
  rooa: "ROOA",
  ros: "ROS",
  loss: "LOSS",
  tgp: "TGP",
  breakeven: "Breakeven Days",
  pbt: "PBT",
  revenue: "REVENUE",
};

const ReportSelectorUnstyled: React.FunctionComponent<ReportSelectorProps> = ({ classes, report: reportKey, handleSelection, title, chartOptions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (handleSelection) {
    const onSelection = key => () => {
      handleSelection(key);
      handleClose();
    };

    const selectionItems =
      Object.keys(chartOptions).length > 0
        ? Object.keys(chartOptions).map((key: string) => (
            <MenuItem key={key} selected={reportKey == key} onClick={onSelection(key)}>
              {translation[chartOptions[key]] ? <FormattedMessage id={translation[chartOptions[key]]} /> : chartOptions[key]}
            </MenuItem>
          ))
        : Object.keys(reportOptions).map(key => (
            <MenuItem key={key} selected={reportKey == key} onClick={onSelection(key)}>
              {translation[reportOptions[key]] ? <FormattedMessage id={translation[reportOptions[key]]} /> : reportOptions[key]}
            </MenuItem>
          ));

    const chartTitle = title ? title : chartOptions ? chartOptions[reportKey] : reportOptions[reportKey];
    return (
      <div>
        <Typography className={classes.heading} aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={handleClick}>
          {translation[chartTitle] ? <FormattedMessage id={translation[chartTitle]} /> : chartTitle}
        </Typography>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {selectionItems}
        </Menu>
      </div>
    );
  }
  const chartTitle = title ? title : chartOptions ? chartOptions[reportKey] : reportOptions[reportKey];
  return <Typography className={classes.heading}>{translation[chartTitle] ? <FormattedMessage id={translation[chartTitle]} /> : chartTitle}</Typography>;
};

export const ReportSelector = withStyles(styles)(ReportSelectorUnstyled);
