import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import React, { useEffect, useState } from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { Layout } from "./layout";
import { history } from "./lib/history";
import { themeFor } from "./style/theme";
import { ChartProvider } from "./contexts/ChartContext";
import { MetricProvider } from "./contexts/MetricContext";
import { RiskMetricProvider } from "./contexts/RiskMetricContext";
import { RiskIndicatorMetricProvider } from "./contexts/RiskIndicatorMetricContext";
import { ChartProvider as OemChartProvider } from "./contexts/OemChartContext";
import { DealerProvider } from "./contexts/DealerContext";
import { MetricProvider as OemMetricProvider } from "./contexts/OemMetricContext";
import { BrandProvider } from "./contexts/BrandContext";
import { CriteriaProvider } from "./contexts/CriteriaContext";
import { DashboardConfigContext, DashboardConfigProvider } from "./contexts/DashboardConfigContext";
import { OemTabsProvider } from "./contexts/OemTabsContext";
import { UserAccessableTabsProvider } from "./contexts/UserAccessableTabsContext";
import { MediaFilesProvider } from "./contexts/MediaFilesContext";
import "./App.css";
import { MeasuresProvider } from "./contexts/MeasuresContext";
import { MetricTableProvider } from "./contexts/MetricTableContext";
import { RiskMetricTableProvider } from "./contexts/RiskMetricTableContext";
import { MetricTableProvider as OemMetricTableProvider } from "./contexts/OemMetricTableContext";
import { ForecastProvider } from "./contexts/ForecastContext";
import { CustomDashboardProvider } from "./contexts/Custom/DashboardContext";
import { PermissionsProvider } from "./contexts/PermissionsContext";
import Joyride from "react-joyride";
import { whoami } from "./lib/api/user";
import { UserProvider, UserContext } from "./contexts/UserContext";
import { OemProvider } from "./contexts/OemContext";
import Loadable from "react-loadable";
import { Loading, LoadableLoading } from "./components/Loading";
import { HydrateApp, HydrateUser } from "./Hydrate";
import { StackedChartProvider } from "./contexts/StackedChartContext";
import { StackedChartProvider as OemStackedChartProvider } from "./contexts/OemStackedChartContext";
import { BreakdownProvider } from "./contexts/BreakdownContext";
import { AttachmentsProvider } from "./contexts/AttachmentsContext";
import { PeopleProvider } from "./contexts/PeopleContext";
// import { TaskProvider } from "./contexts/TodoListContext";
import { TodoAttachmentsProvider } from "./contexts/TodoAttachmentsContext";
import { CountryProvider } from "./contexts/CountryContext";
import { PersonCountryProvider } from "./contexts/PersonCountryContext";
import { PersonOemProvider } from "./contexts/PersonOemContext";
import { DateContextProvider } from "./contexts/FilterDateContext";
import { FilterBrandContextProvider } from "./contexts/FilterBrandContext";
import { marketsFromSubdomain } from "./pages/Login";
import { BenchmarkProvider } from "./contexts/BenchmarkContext";
import { PeriodProvider } from "./contexts/PeriodContext";
import { TabAccessProvider } from "./contexts/TabAccessContext";
import { MetricInsightProvider } from "./contexts/MetricInsightContext";
import { GroupMetricProvider } from "./contexts/GroupMetricContext";
import { GroupChartProvider } from "./contexts/GroupChartContext";
import { GroupBarChartProvider } from "./contexts/GroupBarChartContext";
import { RiskBarChartProvider } from "./contexts/RiskBarChartContext";
import { RiskScatterChartProvider } from "./contexts/RiskScatterChartContext";
import { GroupBreakdownProvider } from "./contexts/GroupBreakdownContext";
import { CustomMetricProvider } from "./contexts/Custom/MetricContext";
import { CustomMetricTableProvider } from "./contexts/Custom/MetricTableContext";
import { CustomChartProvider } from "./contexts/Custom/ChartContext";
import { CustomGroupMetricProvider } from "./contexts/Custom/GroupMetricContext";
import { CustomGroupChartProvider } from "./contexts/Custom/GroupChartContext";
import { CustomGroupBarChartProvider } from "./contexts/Custom/GroupBarChartContext";
import { CustomGroupBreakdownProvider } from "./contexts/Custom/GroupBreakdownContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { FlashReportsConfigProvider } from "./contexts/FlashReportsConfigContext";
import { RiskDashboardConfigProvider } from "./contexts/RiskDashboardConfigContext";
import { AnalysisMetaProvider } from "./contexts/AnalysisMetaContext";
import { AnalysisFilterBrandProvider } from "./contexts/AnalysisFilterBrandContext";
import { GroupDateContextProvider } from "./contexts/GroupFilterDateContext";
import { SelectAdminPagesProvider } from "./contexts/SelectedAdminPagesContext";
import { NotificationsProvider } from "./contexts/NotificationsContext";
import GroupBalanceSheetDashboard from "./pages/group-dashboards/BalanceSheetDashboard";
import { ErrorBoundary } from "./ErrorBoundary";
import { OemDealerProvider } from "./contexts/OemDealerContext";
import { CustomDealerProvider } from "./contexts/CustomDealerContext";
import { DealerAnalysisDealerProvider } from "./contexts/DealerAnalysisDealerContext";
import { NetworkAnalysisDealerProvider } from "./contexts/NetworkAnalysisDealerContext";
import { AdditionalStatsProvider } from "./contexts/Custom/AdditionalStatsContext";
import { CustomMetricRowProvider } from "./contexts/Custom/MetricRowContext";
import { CustomGroupBreakdownRowProvider } from "./contexts/Custom/GroupBreakdownRowContext";
import { DealerModelsProvider } from "./contexts/Custom/DealerModelsContext";
import { CustomDashboardDepartmentsProvider } from "./contexts/Custom/CustomDashboardDepartmentsContext";
import { ReportFolderProvider } from "./contexts/ReportFolderContext";
import { CurrencyContextProvider } from "./contexts/CurrencyContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
    },
  });

export type AppProps = {} & WithStyles<typeof styles>;

const LoginGaurd: React.FunctionComponent<any> = ({ match }) => {
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    whoami()
      .then(user => {
        if (!user.token) {
          throw new Error("Not logged in");
        }

        if (user.token.split("|")[0].toLowerCase() != marketsFromSubdomain[window.location.host.split(".")[0].split("-")[0] as any]) {
          (window as any).location = "/api/logout";
        }
      })
      .catch(e => {
        setLoggedIn(false);
      });
  }, [match.url]);

  if (
    window.location.pathname.includes("/login-testing") ||
    (window.location.pathname.includes("/password-reset") && window.location.pathname.split("/").length == 5)
  ) {
    return <Redirect to={window.location.pathname} />;
  }

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return null;
};

const Ride = ({}) => {
  const configs = React.useContext(DashboardConfigContext);
  const config = configs.configs["joyride"] || { dashboard: "Joyride", items: { finished: false } };
  return (
    <Joyride
      run={configs.loading.loaded && !config.items.finished}
      showProgress
      callback={step => {
        if (step.type == "tour:end") {
          configs.updateConfig("joyride", {
            ...config,
            items: {
              ...config.items,
              finished: true,
            },
          });
        }
      }}
      continuous
      steps={[
        { target: ".joyride-start", content: "Welcome!, we'd like to give you a brief tour of the dashboard." },
        {
          target: ".joyride-filters",
          content: "These are the dashboard filters, they will allow you to filter data for almost every page on the dashboard",
        },
        { target: ".joyride-filters-dealer", content: "Select a dealer for which you want to see data" },
        { target: ".joyride-filters-brand", content: "Chose which brands you want to see represented" },
        { target: ".joyride-filters-forecast", content: "Set your forecast type here" },
        { target: ".joyride-filters-month", content: "Select your reporting month here" },
        { target: ".joyride-filters-benchmark", content: "Chose your benchmark" },
        { target: ".joyride-filters-period", content: "Chose your report period" },
      ]}
    />
  );
};

// const Analysis = Loadable({
//   loader: () => import("./pages/analysis/Analysis"),
//   loading: LoadableLoading,
// });

const CustomDashboards = Loadable({
  loader: () => import("./pages/custom/dealer"),
  loading: LoadableLoading,
});

const DashboardEmpty = Loadable({
  loader: () => import("./pages/dashboards/DashboardEmpty"),
  loading: LoadableLoading,
});

const ExecutiveDashboard = Loadable({
  loader: () => import("./pages/dashboards/ExecutiveDashboard"),
  loading: LoadableLoading,
});

const NewVehiclesDashboard = Loadable({
  loader: () => import("./pages/dashboards/NewVehiclesDashboard"),
  loading: LoadableLoading,
});

const UsedVehiclesDashboard = Loadable({
  loader: () => import("./pages/dashboards/UsedVehiclesDashboard"),
  loading: LoadableLoading,
});

const PartsDashboard = Loadable({
  loader: () => import("./pages/dashboards/PartsDashboard"),
  loading: LoadableLoading,
});

const ServiceDashboard = Loadable({
  loader: () => import("./pages/dashboards/ServiceDashboard"),
  loading: LoadableLoading,
});

const BodyShopDashboard = Loadable({
  loader: () => import("./pages/dashboards/BodyShopDashboard"),
  loading: LoadableLoading,
});

const HomeDashboard = Loadable({
  loader: () => import("./pages/dashboards/HomeDashboard"),
  loading: LoadableLoading,
});

const GroupExecutiveDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/ExecutiveDashboard"),
  loading: LoadableLoading,
});

const GroupNewVehiclesDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/NewVehiclesDashboard"),
  loading: LoadableLoading,
});

const GroupUsedVehiclesDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/UsedVehiclesDashboard"),
  loading: LoadableLoading,
});

const GroupPartsDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/PartsDashboard"),
  loading: LoadableLoading,
});

const GroupServiceDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/ServiceDashboard"),
  loading: LoadableLoading,
});

const GroupBodyShopDashboard = Loadable({
  loader: () => import("./pages/group-dashboards/BodyShopDashboard"),
  loading: LoadableLoading,
});

const OemDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/index"),
  loading: LoadableLoading,
});

const FixedOemFoaPerformanceDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/FixedOemFoaPerformanceDashboard"),
  loading: LoadableLoading,
});

const FixedFoaNvFDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/FixedFoaNvFDashboard"),
  loading: LoadableLoading,
});

const FixedFoaAftersalesDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/FixedFoaAftersalesDashboard"),
  loading: LoadableLoading,
});

const DynamicFoaNvFDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/DynamicFoaNvFDashboard"),
  loading: LoadableLoading,
});

const DynamicOemFoaPerformanceDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/DynamicOemFoaPerformanceDashboard"),
  loading: LoadableLoading,
});

const DynamicFoaAftersalesDashboard = Loadable({
  loader: () => import("./pages/oem-dashboards/DynamicFoaAftersalesDashboard"),
  loading: LoadableLoading,
});

const FixedFordAcademyDashboard = Loadable({
  loader: () => import("./pages/dashboards/FixedFordAcademyDashboard"),
  loading: LoadableLoading,
});

const DynamicFordAcademyDashboard = Loadable({
  loader: () => import("./pages/dashboards/DynamicFordAcademyDashboard"),
  loading: LoadableLoading,
});

const FixedIntegratedReportDashboard = Loadable({
  loader: () => import("./pages/dashboards/FixedIntegratedReportDashboard"),
  loading: LoadableLoading,
});

const FixedRiskDashboard = Loadable({
  loader: () => import("./pages/dashboards/FixedRiskDashboard"),
  loading: LoadableLoading,
});

const DynamicRiskDashboard = Loadable({
  loader: () => import("./pages/dashboards/DynamicRiskDashboard"),
  loading: LoadableLoading,
});

const DynamicIntegratedReportDashboard = Loadable({
  loader: () => import("./pages/dashboards/DynamicIntegratedAcademyDashboard"),
  loading: LoadableLoading,
});

const Login = Loadable({
  loader: () => import("./pages/Login"),
  loading: LoadableLoading,
});

const LoginTesting = Loadable({
  loader: () => import("./pages/LoginTesting"),
  loading: LoadableLoading,
});

const PasswordReset = Loadable({
  loader: () => import("./pages/PasswordReset"),
  loading: LoadableLoading,
});

const Notifications = Loadable({
  loader: () => import("./pages/notifications"),
  loading: LoadableLoading,
});

const Reports = Loadable({
  loader: () => import("./pages/Reports"),
  loading: LoadableLoading,
});

const SubmissionsOuter = Loadable({
  loader: () => import("./pages/submissions"),
  loading: LoadableLoading,
});

const Insights = Loadable({
  loader: () => import("./pages/tutorials/Index"),
  loading: LoadableLoading,
});

const InsightPage = Loadable({
  loader: () => import("./pages/Insight"),
  loading: LoadableLoading,
});

const DynamicOemInsightPage = Loadable({
  loader: () => import("./pages/DynamicOemInsight"),
  loading: LoadableLoading,
});

const FixedOemInsightPage = Loadable({
  loader: () => import("./pages/FixedOemInsight"),
  loading: LoadableLoading,
});

const FixedOemRiskInsightPage = Loadable({
  loader: () => import("./pages/FixedOemRiskInsight"),
  loading: LoadableLoading,
});

const DynamicOemRiskInsightPage = Loadable({
  loader: () => import("./pages/DynamicOemRiskInsight"),
  loading: LoadableLoading,
});

const GroupInsightPage = Loadable({
  loader: () => import("./pages/GroupInsight"),
  loading: LoadableLoading,
});

const Targets = Loadable({
  loader: () => import("./pages/targets"),
  loading: LoadableLoading,
});

// const TodoList = Loadable({
//   loader: () => import("./pages/todo-list/index"),
//   loading: LoadableLoading,
// });

const Filters = Loadable({
  loader: () => import("./pages/filters/index"),
  loading: LoadableLoading,
});

const Profile = Loadable({
  loader: () => import("./pages/profile/index"),
  loading: LoadableLoading,
});

const About = Loadable({
  loader: () => import("./pages/about/index"),
  loading: LoadableLoading,
});

const Admin = Loadable({
  loader: () => import("./pages/admin/index"),
  loading: LoadableLoading,
});

const CustomInsightPage = Loadable({
  loader: () => import("./pages/custom/dealer/CustomInsight"),
  loading: LoadableLoading,
});

const CustomGroupInsightPage = Loadable({
  loader: () => import("./pages/custom/group/CustomInsight"),
  loading: LoadableLoading,
});

const CustomDashboardHome = Loadable({
  loader: () => import("./pages/custom/index"),
  loading: LoadableLoading,
});

const BalanceSheetDashboard = Loadable({
  loader: () => import("./pages/dashboards/BalanceSheetDashboard"),
  loading: LoadableLoading,
});

const CustomTesting = Loadable({
  loader: () => import("./pages/custom/testing"),
  loading: LoadableLoading,
});

const RailsLoginGippo = () => {
  const user = React.useContext(UserContext);
  if (!user.loading.loaded) {
    return null;
  }

  /* Completely arbitrary URL, could be anything just here to pre-warm the login for the reports */
  return (
    <iframe
      title="analytics"
      data-test-id="analytics iframe"
      style={{ display: "none" }}
      src={`https://${window.location.host.split(".")[0].includes("qa") ? "analyticsqa" : "analytics"}.${
        window.location.host.split(".")[0].includes("qa") ? "msxinsightbm" : "sewellsgroupreporting"
      }.com/${window.location.host.split(".")[0].replace("qa", "")}/dashboard?esos_token=${user.user.token}`}
    />
  );
};

const AppUnstyled: React.FunctionComponent<AppProps> = ({ children, classes }) => (
  <LanguageProvider>
    <CssBaseline />
    <Router history={history}>
      <>
        <Route component={LoginGaurd} />
        <Route
          path="/:path?/:department?"
          render={({ match }) => (
            <MuiThemeProvider theme={themeFor(match.params.department)}>
              <UserProvider>
                <CriteriaProvider>
                  <Route component={HydrateUser} />
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/login-testing" component={LoginTesting} />
                    <Route path="/password-reset/:market/:passToken" component={PasswordReset} />
                    <Route
                      render={() => (
                        <BenchmarkProvider>
                          <PeriodProvider>
                            <MetricProvider>
                              <RiskMetricProvider>
                                <RiskIndicatorMetricProvider>
                                  <OemMetricProvider>
                                    <GroupMetricProvider>
                                      <ChartProvider>
                                        <OemChartProvider>
                                          <DateContextProvider>
                                            <OemDealerProvider>
                                              <CustomDealerProvider>
                                                <DealerAnalysisDealerProvider>
                                                  <NetworkAnalysisDealerProvider>
                                                    <DealerProvider>
                                                      <BrandProvider>
                                                        <DashboardConfigProvider>
                                                          <OemProvider>
                                                            <OemTabsProvider>
                                                              <UserAccessableTabsProvider>
                                                                <MediaFilesProvider>
                                                                  <MeasuresProvider>
                                                                    <MetricTableProvider>
                                                                      <RiskMetricTableProvider>
                                                                        <OemMetricTableProvider>
                                                                          <ForecastProvider>
                                                                            <CustomDashboardProvider>
                                                                              <StackedChartProvider>
                                                                                <OemStackedChartProvider>
                                                                                  <PermissionsProvider>
                                                                                    <BreakdownProvider>
                                                                                      <AttachmentsProvider>
                                                                                        <NotificationsProvider>
                                                                                          <CountryProvider>
                                                                                            <TodoAttachmentsProvider>
                                                                                              <PeopleProvider>
                                                                                                <PersonCountryProvider>
                                                                                                  <PersonOemProvider>
                                                                                                    <FilterBrandContextProvider>
                                                                                                      <TabAccessProvider>
                                                                                                        <GroupChartProvider>
                                                                                                          <GroupBarChartProvider>
                                                                                                            <GroupBreakdownProvider>
                                                                                                              <RiskBarChartProvider>
                                                                                                                <RiskScatterChartProvider>
                                                                                                                  <MetricInsightProvider>
                                                                                                                    <CustomMetricProvider>
                                                                                                                      <CustomMetricTableProvider>
                                                                                                                        <CustomChartProvider>
                                                                                                                          <CustomGroupMetricProvider>
                                                                                                                            <CustomGroupChartProvider>
                                                                                                                              <CustomGroupBarChartProvider>
                                                                                                                                <CustomGroupBreakdownProvider>
                                                                                                                                  <FlashReportsConfigProvider>
                                                                                                                                    <RiskDashboardConfigProvider>
                                                                                                                                      <AnalysisMetaProvider>
                                                                                                                                        <AnalysisFilterBrandProvider>
                                                                                                                                          <GroupDateContextProvider>
                                                                                                                                            <SelectAdminPagesProvider>
                                                                                                                                              <AdditionalStatsProvider>
                                                                                                                                                <CustomMetricRowProvider>
                                                                                                                                                  <CustomGroupBreakdownRowProvider>
                                                                                                                                                    <CustomDashboardDepartmentsProvider>
                                                                                                                                                      <DealerModelsProvider>
                                                                                                                                                        <ReportFolderProvider>
                                                                                                                                                          <CurrencyContextProvider>
                                                                                                                                                            <Route
                                                                                                                                                              component={
                                                                                                                                                                HydrateApp
                                                                                                                                                              }
                                                                                                                                                            />
                                                                                                                                                            {/* <Ride /> */}
                                                                                                                                                            <Layout>
                                                                                                                                                              <Switch>
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/:dashboard/insight/:metric/:department/"
                                                                                                                                                                  component={
                                                                                                                                                                    InsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/custom/dealer/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    CustomInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/custom/group/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    CustomGroupInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/:dashboard/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/reports"
                                                                                                                                                                  component={
                                                                                                                                                                    Reports
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/action-plan"
                                                                                                                                                                  component={
                                                                                                                                                                    Targets
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                {/* <Route path="/analysis/:date?" component={Analysis} /> */}
                                                                                                                                                                <Route
                                                                                                                                                                  path="/custom"
                                                                                                                                                                  component={
                                                                                                                                                                    CustomDashboardHome
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/exec"
                                                                                                                                                                  component={
                                                                                                                                                                    ExecutiveDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/balance-sheet"
                                                                                                                                                                  component={
                                                                                                                                                                    BalanceSheetDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/new"
                                                                                                                                                                  component={
                                                                                                                                                                    NewVehiclesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/used"
                                                                                                                                                                  component={
                                                                                                                                                                    UsedVehiclesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/parts"
                                                                                                                                                                  component={
                                                                                                                                                                    PartsDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/service"
                                                                                                                                                                  component={
                                                                                                                                                                    ServiceDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/bodyshop"
                                                                                                                                                                  component={
                                                                                                                                                                    BodyShopDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/exec"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupExecutiveDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/new"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupNewVehiclesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/used"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupUsedVehiclesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/parts"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupPartsDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/service"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupServiceDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/bodyshop"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupBodyShopDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/group-dashboard/balance-sheet"
                                                                                                                                                                  component={
                                                                                                                                                                    GroupBalanceSheetDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/home/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicOemInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/home/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedOemInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/risk/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedOemRiskInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/risk/insight/:metric/:department"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicOemRiskInsightPage
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/home"
                                                                                                                                                                  component={
                                                                                                                                                                    OemDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/home"
                                                                                                                                                                  component={
                                                                                                                                                                    OemDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/fordacademy"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedFordAcademyDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/fordacademy"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicFordAcademyDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/integrated-report"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedIntegratedReportDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/integrated-report"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicIntegratedReportDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/risk"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedRiskDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/risk"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicRiskDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/foa-performance-snapshot"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedOemFoaPerformanceDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/foa-performance-snapshot"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicOemFoaPerformanceDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/foa-nv-dept"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedFoaNvFDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/foa-nv-dept"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicFoaNvFDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/fixed/foa-aftersales-dept"
                                                                                                                                                                  component={
                                                                                                                                                                    FixedFoaAftersalesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/oem/dynamic/foa-aftersales-dept"
                                                                                                                                                                  component={
                                                                                                                                                                    DynamicFoaAftersalesDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard/:any"
                                                                                                                                                                  component={
                                                                                                                                                                    DashboardEmpty
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/dashboard"
                                                                                                                                                                  component={
                                                                                                                                                                    HomeDashboard
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/notifications"
                                                                                                                                                                  component={
                                                                                                                                                                    Notifications
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/submissions"
                                                                                                                                                                  component={
                                                                                                                                                                    SubmissionsOuter
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/insights"
                                                                                                                                                                  component={
                                                                                                                                                                    Insights
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                {/* <Route path="/todolist" component={TodoList} /> */}
                                                                                                                                                                <Route
                                                                                                                                                                  path="/filters"
                                                                                                                                                                  component={
                                                                                                                                                                    Filters
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/profile"
                                                                                                                                                                  component={
                                                                                                                                                                    Profile
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/about"
                                                                                                                                                                  component={
                                                                                                                                                                    About
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                <Route
                                                                                                                                                                  path="/admin"
                                                                                                                                                                  component={
                                                                                                                                                                    Admin
                                                                                                                                                                  }
                                                                                                                                                                />
                                                                                                                                                                {/* For testing the custom dashboard tables */}
                                                                                                                                                                {/* <Route
                                                                                                                                    path="/customtesting"
                                                                                                                                    component={CustomTesting}
                                                                                                                                  /> */}
                                                                                                                                                                <Redirect
                                                                                                                                                                  from="/"
                                                                                                                                                                  to="/dashboard"
                                                                                                                                                                />
                                                                                                                                                                ;
                                                                                                                                                              </Switch>
                                                                                                                                                              <RailsLoginGippo />
                                                                                                                                                            </Layout>

                                                                                                                                                          </CurrencyContextProvider>
                                                                                                                                                        </ReportFolderProvider>
                                                                                                                                                      </DealerModelsProvider>
                                                                                                                                                    </CustomDashboardDepartmentsProvider>
                                                                                                                                                  </CustomGroupBreakdownRowProvider>
                                                                                                                                                </CustomMetricRowProvider>
                                                                                                                                              </AdditionalStatsProvider>
                                                                                                                                            </SelectAdminPagesProvider>
                                                                                                                                          </GroupDateContextProvider>
                                                                                                                                        </AnalysisFilterBrandProvider>
                                                                                                                                      </AnalysisMetaProvider>
                                                                                                                                    </RiskDashboardConfigProvider>
                                                                                                                                  </FlashReportsConfigProvider>
                                                                                                                                </CustomGroupBreakdownProvider>
                                                                                                                              </CustomGroupBarChartProvider>
                                                                                                                            </CustomGroupChartProvider>
                                                                                                                          </CustomGroupMetricProvider>
                                                                                                                        </CustomChartProvider>
                                                                                                                      </CustomMetricTableProvider>
                                                                                                                    </CustomMetricProvider>
                                                                                                                  </MetricInsightProvider>
                                                                                                                </RiskScatterChartProvider>
                                                                                                              </RiskBarChartProvider>
                                                                                                            </GroupBreakdownProvider>
                                                                                                          </GroupBarChartProvider>
                                                                                                        </GroupChartProvider>
                                                                                                      </TabAccessProvider>
                                                                                                    </FilterBrandContextProvider>
                                                                                                  </PersonOemProvider>
                                                                                                </PersonCountryProvider>
                                                                                              </PeopleProvider>
                                                                                            </TodoAttachmentsProvider>
                                                                                          </CountryProvider>
                                                                                        </NotificationsProvider>
                                                                                      </AttachmentsProvider>
                                                                                    </BreakdownProvider>
                                                                                  </PermissionsProvider>
                                                                                </OemStackedChartProvider>
                                                                              </StackedChartProvider>
                                                                            </CustomDashboardProvider>
                                                                          </ForecastProvider>
                                                                        </OemMetricTableProvider>
                                                                      </RiskMetricTableProvider>
                                                                    </MetricTableProvider>
                                                                  </MeasuresProvider>
                                                                </MediaFilesProvider>
                                                              </UserAccessableTabsProvider>
                                                            </OemTabsProvider>
                                                          </OemProvider>
                                                        </DashboardConfigProvider>
                                                      </BrandProvider>
                                                    </DealerProvider>
                                                  </NetworkAnalysisDealerProvider>
                                                </DealerAnalysisDealerProvider>
                                              </CustomDealerProvider>
                                            </OemDealerProvider>
                                          </DateContextProvider>
                                        </OemChartProvider>
                                      </ChartProvider>
                                    </GroupMetricProvider>
                                  </OemMetricProvider>
                                </RiskIndicatorMetricProvider>
                              </RiskMetricProvider>
                            </MetricProvider>
                          </PeriodProvider>
                        </BenchmarkProvider>
                      )}
                    />
                  </Switch>
                </CriteriaProvider>
              </UserProvider>
            </MuiThemeProvider>
          )}
        />
      </>
    </Router>
  </LanguageProvider>
);

export const App = withStyles(styles)(AppUnstyled);

export default App;
