import React, { createContext, useState, useMemo } from "react";
import { Loading } from "./base";
import { getCurrencyList } from "../lib/api/currencies";
import { request } from "../lib/api/base";
import { Dealer, Query } from "../lib/api/charts";

const defaultCurrency = "";
const defaultCurrencyPeriod = "close";

export type CurrencyContextState = {
  targetCurrency: string,
  currencyPeriod: string,
  updateTargetCurrency(currency: string),
  updateCurrencyPeriod(currencyPeriod: string),
  hydrate(oems?: string[]): void,
  loading: Loading
  getCurrencies(oems?: string[]): void,
  currencies: string[],
  rates: {
    dealerRates: { dealer: string, base: string, target: string, rate: string }[],
    benchmarkRates: { benchmark: string, base: string, target: string, rate: string }[]
  },
  getRates(dealers?: Dealer[], query?: Query, benchmarks?: string[]): void,
}
export const CurrencyContext = createContext<CurrencyContextState>({
  currencies: [],
  rates: { dealerRates: [], benchmarkRates: [] },
  targetCurrency: defaultCurrency,
  currencyPeriod: defaultCurrencyPeriod,
  updateTargetCurrency: () => "",
  updateCurrencyPeriod: () => "",
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  getCurrencies: () => null,
  getRates: () => [],
});

export const CurrencyContextProvider = ({ children }) => {
  const [targetCurrency, setTargetCurrency] = useState(defaultCurrency);
  const [currencyPeriod, setCurrencyPeriod] = useState(defaultCurrencyPeriod);
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [rates, setRates] = useState({ dealerRates: [], benchmarkRates: [] });

  const hydrate = async (oems) => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const currencies = await getCurrencyList(oems);
      const mappedCurrencies = currencies.map(cur => cur.Abbreviation);
      setCurrencies(mappedCurrencies)
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }


  const value = useMemo(() => ({
    rates,
    currencies,
    loading,
    hydrate,
    targetCurrency,
    currencyPeriod,
    getCurrencies(oems: string[]) {
      hydrate(oems);
    },
    getRates(dealers, query, benchmarks) {
      try {
        request("/currency/rates", {
          method: "POST",
          body: JSON.stringify({ query: query, dealers: dealers, benchmarks }),
        }).then((res) => res.json()).then(({ data }) => {
          setRates(data)
        });

      } catch (e) {
        return e;
      }
    },
    updateTargetCurrency: (currency: string) => {
      try {
        setTargetCurrency(currency);
      } catch (e) {
        return e;
      }
    },
    updateCurrencyPeriod: (currencyPeriod: string) => {
      try {
        setCurrencyPeriod(currencyPeriod);
      } catch (e) {
        return e;
      }
    },
  }), [currencies, targetCurrency, currencyPeriod, rates]);

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};