import React, { createContext, useReducer, useMemo } from "react";
import { Metric, MetricReport, Query, stringifyQuery, customMetricFor } from "../../lib/api/charts";
import { Loading } from "../base";
import { CustomDashboardMeasures, CustomType } from "../../charts/CustomDashReports";

type CustomMetricSet = { metric: Metric; loading: Loading; custom?: any };

export type MetricContextState = {
  metric: {
    [index: string]: CustomMetricSet;
  };
  getCustomMetric(report: MetricReport, query: Query, custom?: any): Partial<CustomMetricSet>;
};

export const CustomMetricContext = createContext<MetricContextState>({
  metric: {},
  getCustomMetric: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<CustomMetricSet>;
};

const defaultValue = {
  value: 0,
  formattedValue: "",
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultMetric: CustomMetricSet = {
  metric: {
    title: "",
    actualTitle: "",
    classification: "income",
    benchmark: defaultValue,
    variance: defaultValue,
    actual: defaultValue,
    median: defaultValue,
    previous: defaultValue,
    target: defaultValue,
    targetVariance: defaultValue,
  },
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

export const CustomMetricProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query, custom?: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [metric, dispatchMetric] = useReducer((state: { [index: string]: CustomMetricSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      metric,
      getCustomMetric: (report: MetricReport, query: Query, custom?: CustomType) => {
        const id = memo(report, query, custom);
        const set = metric[id];

        if (set) {
          return set;
        }

        dispatchMetric({
          type: "PATCH_METRIC",
          id,
          data: defaultMetric,
        });

        customMetricFor(query, report, custom)
          .then(metric => {
            dispatchMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                metric: metric,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultMetric;
      },
    }),
    [metric],
  );
  return <CustomMetricContext.Provider value={value}>{children}</CustomMetricContext.Provider>;
};
