import { request } from "./base";
import { Forecast } from "./forecast";
import { Benchmark } from "../../contexts/BenchmarkContext";
import { CustomType } from "../../charts/CustomDashReports";

export type Row = {
  month: string;
  x: string | number;
  y: number | string;
  yFormatted: any;
};

export type Series = {
  name: string;
  rows: Row[];
  formats: {
    pos: string;
    neg: string;
  };
  classification: "income" | "expense";
  type: "currency" | "kpi" | "unit";
  yAxisMax?: number;
};

export type GroupSeries = {
  name: string;
  rows: Row[];
  dealers: string[];
  formats: {
    pos: string;
    neg: string;
  };
  classification: "income" | "expense";
  type: "currency" | "kpi" | "unit";
};

export type RiskSeries = {
  name: string;
  rows: Row[];
  scatterdata: ScatterDataCategories;
  dealers: string[];
  formats?: {
    pos: string;
    neg: string;
  };
  xformats?: {
    pos: string;
    neg: string;
  };
  yformats?: {
    pos: string;
    neg: string;
  };
  classification: "income" | "expense";
  type: "currency" | "kpi" | "unit";
};

export type ScatterSeries = {
  name: { x: string; y: string };
  scatterdata: { [dealer: string]: ScatterData[] };
  dealers: string[];
  xformats?: {
    pos: string;
    neg: string;
  };
  yformats?: {
    pos: string;
    neg: string;
  };
  classification: "income" | "expense";
  type: "currency" | "kpi" | "unit";
  xbenchmarks?: ScatterBenchmark[];
  ybenchmarks?: ScatterBenchmark[];
};

type ScatterBenchmark = {
  benchmark: string;
  median: string;
};

type ScatterData = {
  dealer: string;
  xactual: number;
  xactualFormatted: string;
  yactual: number;
  yactualFormatted: string;
};

type ScatterDataCategories = {
  "No Risk": RiskScatterData[];
  "Early Warning": RiskScatterData[];
  Warning: RiskScatterData[];
  Risk: RiskScatterData[];
};

type RiskScatterData = {
  dealer: string;
  actual: string | number;
};

export type StackedSeries = {
  name: string;
  stacked: boolean;
  type: "stacked" | "horizontal";
  yAxisMax?: number;
  formats: {
    pos: string;
    neg: string;
  }[];
  classifications: Array<"income" | "expense">;
  types: Array<"currency" | "kpi" | "unit">;
  bars: string[];
  lines: string[];
  barsb: string[];
  linesb: string[];
  measures: string[];
  rows: {
    month: string;

    formats: {
      pos: string;
      neg: string;
    };
    classification: "income" | "expense";

    new: string;
    newFormatted: string;

    used: string;
    usedFormatted: string;

    parts: string;
    partsFormatted: string;

    service: string;
    serviceFormatted: string;

    bodyshop: string;
    bodyshopFormatted: string;

    other: string;
    otherFormatted: string;
  }[];
};

export type MetricTable = {
  name: string;
  classification: Classification[];
  columns: string[];
  rows: string[];
  data: any[][];
  style: Style[];
  aggregationOverride: any[];
  measures?: string[];
  translationKeys?: string[];
  divideMeasure?: boolean[];
  fullMeasures?: string[];
  subcolumns?: string[];
};

export type MetricRow = {
  classification: Classification;
  columns: string[];
  label: string;
  data: any[];
  aggregationOverride: any;
  measure?: string;
  subHeaders: any;
};

export type Style = {
  bold: boolean;
  depth: number;
};
export type RiskReport =
  | "salesTarget"
  | "rooa"
  | "test1"
  | "test2"
  | "test3"
  | "test4"
  | "test5"
  | "test6"
  | "test7"
  | "test8"
  | "test9"
  | "test10"
  | "test11"
  | "achievment"
  | "ros"
  | "loss"
  | "loss2"
  | "rooa"
  | "wcr"
  | "debttoequity"
  | "capital"
  | "ccc"
  | "nvinventory"
  | "uvinventory"
  | "partsinventory"
  | "risk"
  | "warning"
  | "no-risk"
  | "early-warning"
  | "network-risk-level";

export type Report =
  | "mix"
  | "loss"
  | "loss2"
  | "tgp"
  | "retained"
  | "sales"
  | "pbt"
  | "pbtt"
  | "activity"
  | "rooa"
  | "absorption"
  | "breakeven"
  | "employeetgp"
  | "ros"
  | "foa"
  | "custom"
  | "workingcapitaldays"
  | "mixros"
  | "debtros"
  | "retainedpbt"
  | "financialHealthSummary1"
  | "financialHealthSummary2"
  | "financialHealthSummary3"
  | "financialHealthSummary4"
  | "financialHealthSummary5"
  | "financialHealthSummary6"
  | "financialHealthSummary7"
  | "financialHealthSummary8"
  | "financialHealthSummary9"
  | "financialHealthSummary10"
  | "financialHealthSummary11"
  | "financialHealthSummary12"
  | "financialHealthSummary13"
  | "financialHealthSummary14"
  | "salesHealthSuymmary1"
  | "salesHealthSuymmary2"
  | "salesHealthSuymmary3"
  | "salesHealthSuymmary4"
  | "salesHealthSuymmary5"
  | "salesHealthSuymmary6"
  | "salesHealthSuymmary7"
  | "salesHealthSuymmary8"
  | "salesHealthSuymmary9"
  | "salesHealthSuymmary10"
  | "salesHealthSuymmary11"
  | "salesHealthSuymmary12"
  | "salesHealthSuymmary13"
  | "salesHealthSuymmary14"
  | "salesHealthSuymmary15"
  | "salesHealthSuymmary16"
  | "salesHealthSuymmary17"
  | "serviceHealthSuymmary1"
  | "serviceHealthSuymmary2"
  | "serviceHealthSuymmary3"
  | "serviceHealthSuymmary4"
  | "serviceHealthSuymmary5"
  | "serviceHealthSuymmary6"
  | "serviceHealthSuymmary7"
  | "serviceHealthSuymmary8"
  | "serviceHealthSuymmary9"
  | "serviceHealthSuymmary10"
  | "serviceHealthSuymmary11"
  | "serviceHealthSuymmary12"
  | "serviceHealthSuymmary13"
  | "serviceHealthSuymmary14"
  | "serviceHealthSuymmary15"
  | "serviceHealthSuymmary16"
  | "serviceHealthSuymmary17";

export type MetricReport =
  | "rosi"
  | "mix"
  | "blank"
  | "loss"
  | "loss2"
  | "dealercount"
  | "risk"
  | "tgp"
  | "employeetgp"
  | "retained"
  | "sales"
  | "pbt"
  | "pbtt"
  | "activity"
  | "rooa"
  | "absorption"
  | "breakeven"
  | "mix"
  | "retained"
  | "activity"
  | "rooa"
  | "ros"
  | "totalunits"
  | "revenue"
  | "productivity"
  | "foa"
  | "custom"
  | "retailfleet"
  | "workingcapitaldays"
  | "financialhealth"
  | "saleshealth"
  | "servicehealth"
  | "mixros"
  | "debtros"
  | "retainedpbt"
  | "workingcapitaldays"
  | "debttoequity"
  | "roi"
  | "rooa"
  | "activity"
  | "cashconversioncycle"
  | "balancesheet"
  | "currentratio"
  | "workingcapitalamount"
  | "totaldebtordays"
  | "totalinventorydays"
  | "workshop"
  | "labour"
  | "financialHealthSummary1"
  | "financialHealthSummary2"
  | "financialHealthSummary3"
  | "financialHealthSummary4"
  | "financialHealthSummary5"
  | "financialHealthSummary6"
  | "financialHealthSummary7"
  | "financialHealthSummary8"
  | "financialHealthSummary9"
  | "financialHealthSummary10"
  | "financialHealthSummary11"
  | "financialHealthSummary12"
  | "financialHealthSummary13"
  | "financialHealthSummary14"
  | "salesHealthSuymmary1"
  | "salesHealthSuymmary2"
  | "salesHealthSuymmary3"
  | "salesHealthSuymmary4"
  | "salesHealthSuymmary5"
  | "salesHealthSuymmary6"
  | "salesHealthSuymmary7"
  | "salesHealthSuymmary8"
  | "salesHealthSuymmary9"
  | "salesHealthSuymmary10"
  | "salesHealthSuymmary11"
  | "salesHealthSuymmary12"
  | "salesHealthSuymmary13"
  | "salesHealthSuymmary14"
  | "salesHealthSuymmary15"
  | "salesHealthSuymmary16"
  | "salesHealthSuymmary17"
  | "serviceHealthSuymmary1"
  | "serviceHealthSuymmary2"
  | "serviceHealthSuymmary3"
  | "serviceHealthSuymmary4"
  | "serviceHealthSuymmary5"
  | "serviceHealthSuymmary6"
  | "serviceHealthSuymmary7"
  | "serviceHealthSuymmary8"
  | "serviceHealthSuymmary9"
  | "serviceHealthSuymmary10"
  | "serviceHealthSuymmary11"
  | "serviceHealthSuymmary12"
  | "serviceHealthSuymmary13"
  | "serviceHealthSuymmary14"
  | "serviceHealthSuymmary15"
  | "serviceHealthSuymmary16"
  | "serviceHealthSuymmary17"
  | "mixdriver"
  | "retaineddriver"
  | "activitydriver"
  | "toyotatoa"
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "g"
  | "h"
  | "homepageinsight";

export type ScatterReport =
  | "ros"
  | "rooa"
  | "mix"
  | "retained"
  | "activity"
  | "volume"
  | "achievement"
  | "foa"
  | "nv"
  | "parts"
  | "servive"
  | "bodyshop"
  | "expenses"
  | "variableexpenses"
  | "personnelexpenses"
  | "semivarexpenses"
  | "fixedexpenses"
  | "interestexp"
  | "rent"
  | "rentsales"
  | "rentdepresales"
  | "gpemployee"
  | "gpprod"
  | "pbtemployee";

export type Chart = "bar" | "line";

export type GroupMetric = {
  tableTitle?: string;
  title: string;
  actualTitle: string;
  dealers: string[];
  dealerNames: string[];
  classification: "income" | "expense" | string;
  benchmark: {
    value?: any;
    formatted?: any;
  };
  target: {
    value?: any;
    formatted?: any;
  };
  median: {
    value?: any;
    formatted?: any;
  };
  values: Array<{ dealer: string; dealerNames: string } & MetricData>;
  style?: Style;
  formats?: { pos: string; neg: string };
  columns?: string[];
};

export type MetricData = {
  actual: {
    value?: any;
    formatted?: any;
  };
  variance: {
    value?: any;
    formatted?: any;
  };
  previous: {
    value?: any;
    formatted?: any;
  };
  targetVariance: {
    value?: any;
    formatted?: any;
  };
  dealerCount?: {
    value?: any;
  };
};

export type Metric = {
  title: string;
  actualTitle: string;
  classification: "income" | "expense" | string;
  style?: Style;
  tableTitle?: string;
  actual: {
    value?: any;
    formatted?: any;
  };
  benchmark: {
    value?: any;
    formatted?: any;
  };
  variance: {
    value?: any;
    formatted?: any;
  };
  median: {
    value?: any;
    formatted?: any;
  };
  previous: {
    value?: any;
    formatted?: any;
  };
  target: {
    value?: any;
    formatted?: any;
  };
  targetVariance: {
    value?: any;
    formatted?: any;
  };
} & MetricData;

export type RiskIndicatorMetric = {
  title: string;
  actualTitle: string;
  classification: "income" | "expense" | string;
  style?: Style;
  tableTitle?: string;
  actual: {
    risk: {
      noRisk?: any;
      warning?: any;
      earlyWarning?: any;
      risk?: any;
    };
    previous: {
      noRisk?: any;
      warning?: any;
      earlyWarning?: any;
      risk?: any;
    };
    previousVar: {
      noRisk?: any;
      warning?: any;
      earlyWarning?: any;
      risk?: any;
    };
    value?: any;
    formatted?: any;
  };
};

export type ViewType = "mra" | "standard" | "oemspecific" | "mradrivers";

export type Query = {
  dealer: Dealer;
  dealers: Dealer[];
  brand?: string;
  forecast: Forecast;
  department?: string;
  benchmarks?: Benchmark[];
  period?: string;
  financialMonth?: number;
  date?: any;
  meta: any;
  childDealers?: string[];
  benchmarkDates?: { [index: string]: { maxdate: Date } };
  comparativeDealer?: string | undefined;
  riskMetric?: "early-warning" | "no-risk" | "risk" | "warning";
  dashboard?: DashTypes;
  view?: ViewType;
  targetCurrency?: string;
  currencyPeriod?: string;
};

export type DashTypes = "home" | "exec" | "balance-sheet" | "new" | "used" | "parts" | "service" | "bodyshop";

export const stringifyQuery = (query: Query) => JSON.stringify(query);

export const stackedFor = (query: Query, report: Report, variation: string): Promise<StackedSeries> =>
  request(`stacked/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, variation }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const oemStackedFor = (query: Query, report: Report, variation: string): Promise<StackedSeries> =>
  request(`oem-stacked/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, variation }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const periodFor = (query: Query, report: Report): Promise<StackedSeries> =>
  request(`period/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const aggregateFor = (query: Query, report: Report): Promise<StackedSeries> =>
  request(`aggregate/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const seriesFor = (query: Query, report: Report): Promise<Series> =>
  request(`series/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const oemSeriesFor = (query: Query, report: Report): Promise<Series> =>
  request(`oem-series/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const customSeriesFor = (query: Query, report: Report, custom: any): Promise<Series> =>
  request(`custom/series/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const groupSeriesFor = (query: Query, report: Report, measure?: any): Promise<GroupSeries> =>
  request(`group-series/${measure ? "measure" : report}`, {
    method: "POST",
    body: JSON.stringify({ query, measure }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const groupBarSeriesFor = (query: Query, report: Report, measure?: any): Promise<GroupSeries> =>
  request(`group-bar-series/${measure ? "measure" : report}`, {
    method: "POST",
    body: JSON.stringify({ query, measure }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const riskBarSeriesFor = (query: Query, report: RiskReport, measure?: any): Promise<RiskSeries> =>
  request(`risk-bar-series/${measure ? "measure" : report}`, {
    method: "POST",
    body: JSON.stringify({ query, measure }),
  })
    .then(res => res.json())
    .then(result => {
      return result.data;
    });

export const riskScatterSeriesFor = (query: Query, report: RiskReport, chart?: ScatterReport, measure?: any, brand?: string): Promise<RiskSeries> =>
  request(`risk-scatter-series/${measure ? "measure" : report}/${chart}`, {
    method: "POST",
    body: JSON.stringify({ query, measure: measure, brand: brand }),
  })
    .then(res => res.json())
    .then(result => {
      return result.data;
    });

export const customGroupSeriesFor = (query: Query, report: Report, custom: any): Promise<GroupSeries> =>
  request(`group-series/custom/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const customScatterSeriesFor = (query: Query, custom: any): Promise<ScatterSeries> =>
  request(`scatter-series/custom`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const customGroupBarFor = (query: Query, report: Report, custom: any): Promise<GroupSeries> =>
  request(`group-bar-series/custom/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const metricFor = (query: Query, report: MetricReport): Promise<Metric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      actualTitle: "N/A",
      classification: "income",
      benchmark: blankValue,
      variance: blankValue,
      actual: blankValue,
      median: blankValue,
      previous: blankValue,
      target: blankValue,
      targetVariance: blankValue,
    });
  }

  return request(`metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const riskMetricFor = (query: Query, report: MetricReport): Promise<Metric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      actualTitle: "N/A",
      classification: "income",
      benchmark: blankValue,
      variance: blankValue,
      actual: blankValue,
      median: blankValue,
      previous: blankValue,
      target: blankValue,
      targetVariance: blankValue,
    });
  }

  return request(`risk-metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const oemMetricFor = (query: Query, report: MetricReport): Promise<Metric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      actualTitle: "N/A",
      classification: "income",
      benchmark: blankValue,
      variance: blankValue,
      actual: blankValue,
      median: blankValue,
      previous: blankValue,
      target: blankValue,
      targetVariance: blankValue,
    });
  }

  return request(`oem-metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const riskIndicatormetricFor = (query: Query, report: MetricReport): Promise<RiskIndicatorMetric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      actualTitle: "N/A",
      classification: "income",
      actual: {
        ...blankValue,
        risk: {
          noRisk: { value: 0, formatted: "N/A" },
          risk: { value: 0, formatted: "N/A" },
          earlyWarning: { value: 0, formatted: "N/A" },
          warning: { value: 0, formatted: "N/A" },
        },
        previous: {
          noRisk: { value: 0, formatted: "N/A" },
          risk: { value: 0, formatted: "N/A" },
          earlyWarning: { value: 0, formatted: "N/A" },
          warning: { value: 0, formatted: "N/A" },
        },
        previousVar: {
          noRisk: { value: 0, formatted: "N/A" },
          risk: { value: 0, formatted: "N/A" },
          earlyWarning: { value: 0, formatted: "N/A" },
          warning: { value: 0, formatted: "N/A" },
        },
      },
    });
  }

  return request(`risk-indicator-metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const groupMetricFor = (query: Query, report: MetricReport): Promise<GroupMetric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      dealers: [],
      dealerNames: [],
      actualTitle: "N/A",
      classification: "income",
      values: [],
      benchmark: blankValue,
      target: blankValue,
      median: blankValue,
    });
  }

  return request(`group-metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const customGroupMetricFor = (query: Query, report: MetricReport, custom?: any): Promise<GroupMetric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      dealers: [],
      dealerNames: [],
      actualTitle: "N/A",
      classification: "income",
      values: [],
      benchmark: blankValue,
      target: blankValue,
      median: blankValue,
    });
  }

  return request(`group-metric/custom/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export type BreakdownReport = "mra" | "rooa" | "custom";
export type Breakdown = { [index: string]: Metric };

export const breakdownFor = (query: Query, report: BreakdownReport, custom?: any): Promise<Breakdown> => {
  return request(`breakdown/${report}`, {
    method: "POST",
    body: JSON.stringify({ query: query, custom: custom }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export type GroupBreakdown = { [index: string]: GroupMetric };

export const groupBreakdownFor = (query: Query, report: MetricReport): Promise<GroupBreakdown> => {
  return request(`group-breakdown/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const customGroupBreakdownFor = (query: Query, report: MetricReport, custom: any): Promise<GroupBreakdown> => {
  return request(`group-breakdown/custom/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const customGroupBreakdownRowFor = (query: Query, report: MetricReport, custom: any): Promise<GroupBreakdown> => {
  return request(`group-breakdown/row/custom/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const metricTableFor = (query: Query, report: MetricReport): Promise<MetricTable> =>
  request(`metric-table/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const riskMetricTableFor = (query: Query, report: MetricReport): Promise<MetricTable> =>
  request(`risk-metric-table/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const oemMetricTableFor = (query: Query, report: MetricReport): Promise<MetricTable> =>
  request(`oem-metric-table/${report}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const customMetricFor = (query: Query, report: MetricReport, custom?: CustomType): Promise<Metric> => {
  if (report == "blank") {
    const blankValue = { value: 0, formatted: "N/A" };
    return Promise.resolve({
      title: "N/A",
      actualTitle: "N/A",
      classification: "income",
      benchmark: blankValue,
      variance: blankValue,
      actual: blankValue,
      median: blankValue,
      previous: blankValue,
      target: blankValue,
      targetVariance: blankValue,
    });
  }

  return request(`custom/metric/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);
};

export const customMetricRowFor = (query: Query, report: MetricReport, custom?: CustomType): Promise<MetricRow> =>
  request(`custom/metric-row/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export const customMetricTableFor = (query: Query, report: MetricReport, custom?: CustomType): Promise<MetricTable> =>
  request(`custom/metric-table/${report}`, {
    method: "POST",
    body: JSON.stringify({ query, custom }),
  })
    .then(res => res.json())
    .then(result => result.data);

export type Value = {
  actual: string | number;
  formatted: string | number;
};

type Classification = "income" | "expense";

export type TableRow = {
  name: string;
  values: Value[];
  rows?: TableRow[];
  classification: Classification[];
  meta: {
    aggregationType: "UNIT" | "KPI" | string;
    classification: "INCOME" | "EXPENSE" | string;
  };
};

export type DealerTable = TableRow[];

export type Tables = "dealer" | "executive-summary";

export const tableFor = (query: Query, type: Tables): Promise<DealerTable> =>
  request(`table/${type}`, {
    method: "POST",
    body: JSON.stringify({ query }),
  })
    .then(res => res.json())
    .then(result => result.data);

export type Dealer = {
  dealer: string;
  code: string;
  currency: string;
  oems: string[];
  minMonth: Date;
  maxMonth: Date;
  regions: string[];
  isConsolidated: boolean;
  active: boolean;
  brands: string[];
  dealerGroups: string[];
  dealerSets: string[];
  benchmarks: string[];
  hasTargets: boolean[];
  isDashboardDealer: boolean;
  childDealers?: string[];
  submissionsWorkbenchEnabled?: boolean;
  OEMSpecificDash?: boolean;
  defaultBMLimits?: boolean;
  defaultView?: ViewType;
  disabledDealerUpload?: boolean;
  newDealer?: boolean;
  isChildDealer?: boolean;
};

export type NetworkComparableDealer = {
  oemCode: string;
  dealerCode: string;
  sortOrder: number;
};

export const dealers = (): Promise<{ dealers: Dealer[] }> =>
  request(`dealers`)
    .then(res => res.json())
    .then(result => {
      return result.data;
    });

export const benchmarks = (): Promise<{ benchmarks: string[] }> =>
  request(`benchmarks`)
    .then(res => res.json())
    .then(result => result.data);

export const apiBenchmarkDates = (): Promise<{ benchmark_name: string; maxdate: Date }[]> =>
  request(`benchmark-dates`)
    .then(res => res.json())
    .then(result => result.data);

export const getNetworkComparableDealers = (oems: string[]): Promise<{ dealers: NetworkComparableDealer[] }> =>
  request(`dealers/network-comparable`, {
    method: "POST",
    body: JSON.stringify({ oems }),
  })
    .then(res => res.json())
    .then(result => result.data);
