import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const Driveway: { [index: string]: CustomDashboardType } = {
  "DRIVEWAY > SALES > Breakeven Days": {
    name: "DRIVEWAY > SALES > Breakeven Days",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Breakeven Days",
    tableNumber: 130,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Breakeven Days | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Breakeven Sales": {
    name: "DRIVEWAY > SALES > Breakeven Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Breakeven Sales",
    tableNumber: 130,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Driveway Breakeven Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Total Sales": {
    name: "DRIVEWAY > SALES > Driveway Total Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Total Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Unleaded Fuel Sales": {
    name: "DRIVEWAY > SALES > Driveway Unleaded Fuel Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Unleaded Fuel Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Unleaded Fuel net sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Leaded Fuel Sales": {
    name: "DRIVEWAY > SALES > Driveway Leaded Fuel Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Leaded Fuel Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Leaded Fuel Net Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Diesel Sales": {
    name: "DRIVEWAY > SALES > Driveway Diesel Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Diesel Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Diesel Net sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Parrafin Sales": {
    name: "DRIVEWAY > SALES > Driveway Parrafin Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Parrafin Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Parrafin net sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Oil driveway Sales": {
    name: "DRIVEWAY > SALES > Driveway Oil driveway Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Oil driveway Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Oil driveway net sale | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Gas Sales": {
    name: "DRIVEWAY > SALES > Driveway Gas Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Gas Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS >  Gas net sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Shop > General Sales": {
    name: "DRIVEWAY > SALES > Driveway Shop > General Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Shop - General Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Shop - General net sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > SALES > Driveway Other Sales": {
    name: "DRIVEWAY > SALES > Driveway Other Sales",
    department: "DRIVEWAY",
    category: "SALES",
    label: "Driveway Other Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Driveway Other Net Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > MIX > DRIVEWAY MIX": {
    name: "DRIVEWAY > MIX > DRIVEWAY MIX",
    department: "DRIVEWAY",
    category: "GROSS PROFIT",
    label: "DRIVEWAY MIX",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > MIX > DRIVEWAY Total Sales": {
    name: "DRIVEWAY > MIX > DRIVEWAY Total Sales",
    department: "DRIVEWAY",
    category: "GROSS PROFIT",
    label: "DRIVEWAY Total Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > MIX > DRIVEWAY Total GP": {
    name: "DRIVEWAY > MIX > DRIVEWAY Total GP",
    department: "DRIVEWAY",
    category: "GROSS PROFIT",
    label: "DRIVEWAY Total GP",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > DRIVEWAY RETAINED": {
    name: "DRIVEWAY > RETAINED > DRIVEWAY RETAINED",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "DRIVEWAY RETAINED",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > DRIVEWAY Total GP": {
    name: "DRIVEWAY > RETAINED > DRIVEWAY Total GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "DRIVEWAY Total GP",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > DRIVEWAY Total Expenses": {
    name: "DRIVEWAY > RETAINED > DRIVEWAY Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "DRIVEWAY Total Expenses",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > DRIVEWAY Total PBT": {
    name: "DRIVEWAY > RETAINED > DRIVEWAY Total PBT",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "DRIVEWAY Total PBT",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Total Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Total Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Total Expenses % GP",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Variable Expenses  % GP": {
    name: "DRIVEWAY > RETAINED > Variable Expenses  % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Variable Expenses  % GP",
    tableNumber: 133,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Personnel  Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Personnel  Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Personnel  Expenses % GP",
    tableNumber: 534,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Other Semi Variable Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Other Semi Variable Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Other Semi Variable Expenses % GP",
    tableNumber: 135,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Fixed Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Fixed Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Fixed Expenses % GP",
    tableNumber: 136,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Interest Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Interest Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Interest Expenses % GP",
    tableNumber: 137,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Allocation of Admin  Expenses % GP": {
    name: "DRIVEWAY > RETAINED > Allocation of Admin  Expenses % GP",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Allocation of Admin  Expenses % GP",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Variable Expenses% Total Expenses": {
    name: "DRIVEWAY > RETAINED > Variable Expenses% Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Variable Expenses% Total Expenses",
    tableNumber: 133,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Personnel Expenses% Total Expenses": {
    name: "DRIVEWAY > RETAINED > Personnel Expenses% Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Personnel Expenses% Total Expenses",
    tableNumber: 534,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Other Semi Var.Exp.% Total Expenses": {
    name: "DRIVEWAY > RETAINED > Other Semi Var.Exp.% Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Other Semi Var.Exp.% Total Expenses",
    tableNumber: 135,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Fixed Expenses% Total Expenses": {
    name: "DRIVEWAY > RETAINED > Fixed Expenses% Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Fixed Expenses% Total Expenses",
    tableNumber: 136,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Interest Expenses % Total Expenses": {
    name: "DRIVEWAY > RETAINED > Interest Expenses % Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Interest Expenses % Total Expenses",
    tableNumber: 137,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > RETAINED > Allocation of Admin Expenses % Total Expenses": {
    name: "DRIVEWAY > RETAINED > Allocation of Admin Expenses % Total Expenses",
    department: "DRIVEWAY",
    category: "EXPENSES",
    label: "Driveway Allocation of Admin Expenses % Total Expenses",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ACTIVITY > DRIVEWAY ACTIVITY": {
    name: "DRIVEWAY > ACTIVITY > DRIVEWAY ACTIVITY",
    department: "DRIVEWAY",
    category: "OPERATIONAL ASSETS",
    label: "DRIVEWAY ACTIVITY",
    tableNumber: 138,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | DRIVEWAY | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > ACTIVITY > DRIVEWAY Annualised Sales": {
    name: "DRIVEWAY > ACTIVITY > DRIVEWAY Annualised Sales",
    department: "DRIVEWAY",
    category: "OPERATIONAL ASSETS",
    label: "DRIVEWAY Annualised Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Projected Annualised Sales | DRIVEWAY | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ACTIVITY > DRIVEWAY Average Operational Assets": {
    name: "DRIVEWAY > ACTIVITY > DRIVEWAY Average Operational Assets",
    department: "DRIVEWAY",
    category: "OPERATIONAL ASSETS",
    label: "DRIVEWAY Average Operational Assets",
    tableNumber: 138,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Operational Assets | DRIVEWAY | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > ROOA > DRIVEWAY ROOA": {
    name: "DRIVEWAY > ROOA > DRIVEWAY ROOA",
    department: "DRIVEWAY",
    category: "MRA MODEL",
    label: "DRIVEWAY ROOA",
    tableNumber: 139,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Operational Assets (ROOA) | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > ROOA > MIX": {
    name: "DRIVEWAY > ROOA > MIX",
    department: "DRIVEWAY",
    category: "MRA MODEL",
    label: "DRIVEWAY MIX",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROOA > RETAINED": {
    name: "DRIVEWAY > ROOA > RETAINED",
    department: "DRIVEWAY",
    category: "MRA MODEL",
    label: "DRIVEWAY RETAINED",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROOA > ACTIVITY": {
    name: "DRIVEWAY > ROOA > ACTIVITY",
    department: "DRIVEWAY",
    category: "MRA MODEL",
    label: "DRIVEWAY ACTIVITY",
    tableNumber: 138,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > ROS (Income Statement) > ROS": {
    name: "DRIVEWAY > ROS (Income Statement) > ROS",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway ROS",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Sales": {
    name: "DRIVEWAY > ROS (Income Statement) > Sales",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Sales",
    tableNumber: 129,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > PBT": {
    name: "DRIVEWAY > ROS (Income Statement) > PBT",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway PBT",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Dept. Profit % Net Sales": {
    name: "DRIVEWAY > ROS (Income Statement) > Dept. Profit % Net Sales",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Dept. Profit % Net Sales",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest % of TNS | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > EBIT% (PBIT%)": {
    name: "DRIVEWAY > ROS (Income Statement) > EBIT% (PBIT%)",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway EBIT% (PBIT%)",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT % of Sales | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
    name: "DRIVEWAY > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Variable Selling Gross % Gorss Profit",
    tableNumber: 133,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Variable Selling Gross % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Selling Gross % Gross Profit": {
    name: "DRIVEWAY > ROS (Income Statement) > Selling Gross % Gross Profit",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Selling Gross % Gross Profit",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TGP | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Selling Gross % Net Sales": {
    name: "DRIVEWAY > ROS (Income Statement) > Selling Gross % Net Sales",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Selling Gross % Net Sales",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TNS | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Gross Profit": {
    name: "DRIVEWAY > ROS (Income Statement) > Gross Profit",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Gross Profit",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Expenses",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Variable Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Variable Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Variable Expenses",
    tableNumber: 133,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Personnel Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Personnel Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Personnel Expenses",
    tableNumber: 534,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Other Semi Variable Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Other Semi Variable Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Other Semi Variable Expenses",
    tableNumber: 135,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Fixed Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Fixed Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Fixed Expenses",
    tableNumber: 136,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Interest Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Interest Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Interest Expenses",
    tableNumber: 137,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Departmental Profit": {
    name: "DRIVEWAY > ROS (Income Statement) > Departmental Profit",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Departmental Profit",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest (EAIT) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > Allocation of Admin Expenses": {
    name: "DRIVEWAY > ROS (Income Statement) > Allocation of Admin Expenses",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway Allocation of Admin Expenses",
    tableNumber: 132,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > ROS (Income Statement) > EBIT": {
    name: "DRIVEWAY > ROS (Income Statement) > EBIT",
    department: "DRIVEWAY",
    category: "ROS (Income Statement)",
    label: "Driveway EBIT",
    tableNumber: 140,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT (Profit Before Interest & Tax) | Driveway | All Brand | All Model | | SUM",
  },
  "DRIVEWAY > PRODUCTIVITY > Driveway GP / Productive / Month": {
    name: "DRIVEWAY > PRODUCTIVITY > Driveway GP / Productive / Month",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway GP / Productive / Month",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Driveway Sales / Productive / Month": {
    name: "DRIVEWAY > PRODUCTIVITY > Driveway Sales / Productive / Month",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway Sales / Productive / Month",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Driveway net sales per Productive | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Driveway GP / Employee / Month": {
    name: "DRIVEWAY > PRODUCTIVITY > Driveway GP / Employee / Month",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway GP / Employee / Month",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Employee | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Total Staff": {
    name: "DRIVEWAY > PRODUCTIVITY > Total Staff",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway Total Staff",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Total Employees | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Productive Staff": {
    name: "DRIVEWAY > PRODUCTIVITY > Productive Staff",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway Productive Staff",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Productives | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Non-Productive Staff": {
    name: "DRIVEWAY > PRODUCTIVITY > Non-Productive Staff",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway Non-Productive Staff",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Non-Productives | Driveway | All Brand | All Model | | AVG",
  },
  "DRIVEWAY > PRODUCTIVITY > Ratio Productive : Non Productive": {
    name: "DRIVEWAY > PRODUCTIVITY > Ratio Productive : Non Productive",
    department: "DRIVEWAY",
    category: "PRODUCTIVITY",
    label: "Driveway Ratio Productive : Non Productive",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Productive to Non-Productive staff | Driveway | All Brand | All Model | | AVG",
  },
};

export const DrivewayOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "DRIVEWAY > OEM Supports Analysis > Driveway OEM Expenses Subsidy": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway OEM Expenses Subsidy",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway OEM Expenses Subsidy",
    measure: "TE > OEM Expenses Subsidy | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway OEM Direct Advertising Subsidy": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway OEM Direct Advertising Subsidy",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway OEM Direct Advertising Subsidy",
    measure: "VE > Advertising Subsidy | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway OEM Indirect Advertising Subsidy": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway OEM Indirect Advertising Subsidy",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway OEM Indirect Advertising Subsidy",
    measure: "SVE > Indirect Advertising Subsidy | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway OEM Expenses Subsidy % Sales": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway OEM Expenses Subsidy % Sales",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway OEM Expenses Subsidy % Sales",
    measure: "TE > OEM Expenses Subsidy % Net Sales | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway MIX": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway MIX",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway MIX",
    measure: "G > Gross Profit % of Sales (MIX) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway RETAINED": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway RETAINED",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway RETAINED",
    measure: "PBT > PBT % of TGP: RETAINED |  Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway RETAINED (excl OEM Expenses Subsidy)": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway RETAINED (excl OEM Expenses Subsidy)",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway RETAINED (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway Expenses % TGP": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway Expenses % TGP",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway Expenses % TGP",
    measure: "TE > Total Expenses % of TGP | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway Expenses % TGP (excl OEM Expenses Subsidy)": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway Expenses % TGP (excl OEM Expenses Subsidy)",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway Expenses % TGP (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway ROS": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway ROS",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway ROS",
    measure: "PBT > PBT % of Sales | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway ROS (excl OEM Expenses Subsidy)": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway ROS (excl OEM Expenses Subsidy)",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway ROS (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway Sales": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway Sales",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway Sales",
    measure: "TNS > Net Sales | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway GP": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway GP",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway GP",
    measure: "G > Gross Profit | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway Expenses": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway Expenses",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway Expenses",
    measure: "TE > Total Expenses | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway Expenses (excl OEM Expenses Subsidy)": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway Expenses (excl OEM Expenses Subsidy)",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway Expenses (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses (excl Expenses Subsidy) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway PBT": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway PBT",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway PBT",
    measure: "PBT > PBT (Profit Before Tax) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
  "DRIVEWAY > OEM Supports Analysis > Driveway PBT (excl All Supports from OEM)": {
    name: "DRIVEWAY > OEM Supports Analysis > Driveway PBT (excl All Supports from OEM)",
    department: "DRIVEWAY",
    category: "OEM Supports Analysis",
    label: "Driveway PBT (excl All Supports from OEM)",
    measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | Driveway | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 534,
  },
};
