import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { NativeSelectProps } from "@material-ui/core/NativeSelect";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { DashboardConfigContext } from "./contexts/DashboardConfigContext";
import { DealerContext } from "./contexts/DealerContext";
import { DealerFilter, filterDealers } from "./DealerFilter";
import { GroupDateContext } from "./contexts/GroupFilterDateContext";
import { formatFullDate, formatDisplayDate } from "./charts/GroupDashboardOuter";
import { OemContext } from "./contexts/OemContext";
import { BenchmarkContext } from "./contexts/BenchmarkContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    style: {
      padding: theme.spacing.unit * 2,
      minWidth: 400,
      backgroundColor: "whitesmoke",
      display: "flex",
      flexDirection: "column",
    },
    fields: {},
    control: {
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    input: {
      marginTop: theme.spacing.unit * 2,
      backgroundColor: "white",
    },
    title: {
      fontWeight: "bold",
      fontSize: theme.typography.fontSize * 1.2,
    },
    dealerList: {
      overflow: "hidden",
      overflowY: "scroll",
    },
    button: {
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
      height: "35px",
      backgroundColor: "#fff",
      margin: "0px",
      "@media(max-width: 900px)": {
        marginBottom: "20px",
      },
      gridColumn: "1/6",
      "@media(max-width: 1100px)": {
        gridColumn: "1/4",
      },
    },
    buttons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    formLabelText: {
      paddingBottom: theme.spacing.unit * 1,
      fontSize: "0.8rem",
    },
    tableGrid: {
      marginTop: "20px",
      height: "200px",
      overflowY: "scroll",
      border: "1px solid #ddd",
    },
    mainModal: {
      maxHeight: "100%",
      height: "100%",
      maxWidth: "600px",
    },
    actionButtons: {
      float: "right",
    },
    tableHeader: {
      backgroundColor: "white",
      fontSize: theme.typography.fontSize * 1,
    },
    tableBody: {
      backgroundColor: "white",
    },
    clickable: {
      cursor: "pointer",
    },
    inputText: {
      backgroundColor: "white",
      padding: 9,
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    innerInputLabel: {
      transform: "translate(12px, 11px) scale(1)",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    selectInputs: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: "0.8rem",
    },
    hr: {
      width: "95%",
      marginTop: 15,
    },
    code: {
      width: 100,
    },
    cellHead: {
      backgroundColor: "#f3f3f3",
    },
    selectInput: {
      padding: 0,
    },
  });

export type GroupDealerSelectionProps = {
  dashboard?: string;
  dealerTypes?: "consolidated" | "non-consolidated" | "all";
} & WithStyles<typeof styles>;

export const maxMonth = (months: Date[]) => {
  let returnMonth = months[0];
  months.map(month => {
    if (month > returnMonth) {
      returnMonth = month;
    }
  });
  return returnMonth;
};

const minMonth = (months: Date[]) => {
  let returnMonth = months[0];
  months.map(month => {
    if (month < returnMonth) {
      returnMonth = month;
    }
  });
  return returnMonth;
};

const GroupDealerSelectionUnstlyed: React.SFC<GroupDealerSelectionProps & NativeSelectProps> = ({
  classes,
  children,
  dealerTypes = "all",
  dashboard,
  onChange,
  ...props
}) => {
  const [modalOpen, toggleModal] = React.useState(false);
  const { configs } = React.useContext(DashboardConfigContext);
  const {
    setDealer,
    available,
    selected,
    setDealerCode,
    setDealerName,
    setFilters,
    setFilterSet,
    dealerCode,
    dealerName,
    filterSet,
    setSelectedDealers,
    setSelectedOemDealers,
    setGroupMinMonth,
    setGroupMaxMonth,
    selectedDealers,
    filters,
  } = React.useContext(DealerContext);
  const settings = configs.settings || { oems: [], country: "" };
  const { date, updateDate } = React.useContext(GroupDateContext);
  const { oems, loading: oemLoading } = React.useContext(OemContext);
  const { setBenchmark } = React.useContext(BenchmarkContext);

  const availableOemsByCountry = oems.filter(oem => filters.country.includes(oem.country));

  const updateFilter = (which: string) => e => {
    setFilters({
      ...filters,
      [which]: e.map(v => v.value),
    });
  };

  const filteredDealers = React.useMemo(() => {
    return filterDealers(
      available,
      filterSet.dealerCode,
      filterSet.dealerName,
      filterSet.filters,
      dashboard == "oem",
      dealerTypes,
      false,
      false,
      settings,
      availableOemsByCountry,
    );
  }, [filterSet, dashboard, availableOemsByCountry]);

  const liveFilteredDealers = React.useMemo(() => {
    return filterDealers(available, dealerCode, dealerName, filters, dashboard == "oem", dealerTypes, false, false, settings, availableOemsByCountry);
  }, [dealerCode, dealerName, filters, dashboard, availableOemsByCountry]);

  useEffect(() => {
    if (selectedDealers.length == 0) {
      toggleModal(true);
    }
  }, [selectedDealers]);

  return (
    <>
      <Button variant="contained" className={classes.button} onClick={() => toggleModal(true)}>
        Advanced Search Access
      </Button>
      <Modal open={modalOpen} onClose={() => toggleModal(false)}>
        <DealerFilter
          selectedDealers={selectedDealers}
          selectDealer={e => {
            const dealer = e.target.value;
            if (selectedDealers.includes(dealer)) {
              setSelectedDealers(selectedDealers.filter(d => d != dealer));
              setGroupMinMonth(minMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.minMonth)));
              setGroupMaxMonth(maxMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.maxMonth)));
              if (maxMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.maxMonth))) {
                const d = new Date(Date.parse(maxMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.maxMonth)).toString()));
                updateDate({
                  value: formatFullDate(d),
                  label: formatDisplayDate(d),
                });
              }
            } else {
              setSelectedDealers([...selectedDealers, dealer]);
              setGroupMinMonth(minMonth([...selectedDealers, dealer].map(dealer => dealer.minMonth)));
              setGroupMaxMonth(maxMonth([...selectedDealers, dealer].map(dealer => dealer.maxMonth)));
              if (maxMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.maxMonth))) {
                const d = new Date(Date.parse(maxMonth(selectedDealers.filter(d => d != dealer).map(dealer => dealer.maxMonth)).toString()));
                updateDate({
                  value: formatFullDate(d),
                  label: formatDisplayDate(d),
                });
              }
            }
          }}
          selectAll={dealers => {
            if (dashboard == "dynamic") {
              setSelectedOemDealers(dealers);
              if (dealers.some(dealer => dealer.defaultBMLimits)) {
                setBenchmark([`${dealers[0].benchmarks[0]} - UL`, `${dealers[0].benchmarks[0]} - LL`] as [string, string]);
              } else {
                setBenchmark([dealers[0].benchmarks[0], `${dealers[0].benchmarks[0]} - Median`]);
              }
              setGroupMinMonth(minMonth(dealers.map(dealer => dealer.minMonth)));
              setGroupMaxMonth(maxMonth(dealers.map(dealer => dealer.maxMonth)));
            } else {
              setSelectedDealers(dealers);
              if (dealers.some(dealer => dealer.defaultBMLimits && dealers.filter(d => d.benchmarks.length > 1).length > 1)) {
                setBenchmark([
                  `${dealers.filter(d => d.benchmarks.length > 1)[0].benchmarks[0]} - UL`,
                  `${dealers.filter(d => d.benchmarks.length > 1)[0].benchmarks[0]} - LL`,
                ] as [string, string]);
              } else if (dealers.filter(d => d.benchmarks.length > 1).length > 1) {
                setBenchmark([
                  dealers.filter(d => d.benchmarks.length > 1)[0].benchmarks[0],
                  `${dealers.filter(d => d.benchmarks.length > 1)[0].benchmarks[0]} - Median`,
                ]);
              } else {
                setBenchmark(["", ""]);
              }
              setGroupMinMonth(minMonth(dealers.map(dealer => dealer.minMonth)));
              setGroupMaxMonth(maxMonth(dealers.map(dealer => dealer.maxMonth)));
              if (maxMonth(dealers.map(dealer => dealer.maxMonth))) {
                const d = new Date(Date.parse(maxMonth(dealers.map(dealer => dealer.maxMonth)).toString()));
                updateDate({
                  value: formatFullDate(d),
                  label: formatDisplayDate(d),
                });
              }
            }
          }}
          hideTitle={false}
          setFilterSet={setFilterSet}
          hideButtons={false}
          shrinkInputLabels={false}
          className=""
          classes={classes}
          filteredDealers={filteredDealers.map((opt, i) => ({ ...opt, index: i }))}
          liveFilteredDealers={liveFilteredDealers}
          dealerCode={dealerCode}
          dealerName={dealerName}
          setDealerCode={setDealerCode}
          setDealerName={setDealerName}
          filters={filters}
          updateFilter={updateFilter}
          toggleModal={toggleModal}
          setFilters={setFilters}
          filterSet={filterSet}
          selection={"group-dashboard"}
          setDealer={setDealer}
        />
      </Modal>
    </>
  );
};

export const GroupDealerSelection = withStyles(styles)(GroupDealerSelectionUnstlyed);
