import { OverallDealership, OverallDealershipOEMSupportsAnalysis } from "./custom-dashboard-reports/OverallDealership";
import { NewVehicle, NewVehicleOEMSupportsAnalysis } from "./custom-dashboard-reports/NewVehicle";
import { Parts, PartsOEMSupportsAnalysis } from "./custom-dashboard-reports/Parts";
import { Service, ServiceOEMSupportsAnalysis } from "./custom-dashboard-reports/Service";
import { FI, FI_OEMSupportsAnalysis } from "./custom-dashboard-reports/F&I";
import { AfterSales, AfterSalesOEMSupportsAnalysis } from "./custom-dashboard-reports/AfterSales";
import { Driveway, DrivewayOEMSupportsAnalysis } from "./custom-dashboard-reports/Driveway";
import { UsedVehicle, UsedVehicleOEMSupportsAnalysis } from "./custom-dashboard-reports/UsedVehicle";
import { BodyShop, BodyShopOEMSupportsAnalysis } from "./custom-dashboard-reports/BodyShop";
import { Aftercare } from "./custom-dashboard-reports/Aftercare";
import { OtherDept } from "./custom-dashboard-reports/OtherDept";
import { NetworkStats } from "./custom-dashboard-reports/NetworkStats";
import { BalanceSheet } from "./custom-dashboard-reports/BalanceSheet";
import { FordAcademyKPIs } from "./custom-dashboard-reports/FordAcademy";
import { VCJProcessData } from "./custom-dashboard-reports/VCJProcessData";
import { fordSAScorecard } from "./custom-dashboard-reports/FordSAScrorecard";
export type CustomDashboardType = {
  name: string;
  department: string;
  category: string;
  label: string;
  measure: string;
  tableNumber: number;
  getMeasure(label: string, measures: any): void;
  model?: string;
  brand?: string;
};

export type CustomType = {
  aggType: string;
  department: string;
  label: string;
  measure: string;
  name: string;
  tableNumber: number;
  model?: string;
  brand?: string;
};

export const getMeasure = (label: string, measures: CustomDashboardType) => {
  if (measures[label].measure == "Placeholder Measure") {
    return {
      name: measures[label].name,
      measure: "Placeholder Measure",
      department: "All Department",
      aggType: "sum",
      label: measures[label].label,
      tableNumber: measures[label].tableNumber,
    };
  }

  return {
    name: measures[label].name,
    measure: measures[label].measure.split(" | ")[0].trim(),
    department: measures[label].measure.split(" | ")[1].trim(),
    brand: measures[label].measure.split(" | ")[2].trim(),
    aggType: measures[label].measure
      .split("| |")[1]
      .trim()
      .toLowerCase(),
    label: measures[label].label,
    tableNumber: measures[label].tableNumber,
    model: measures[label].model,
  };
};

export const CustomDashboardMeasures: { [index: string]: CustomDashboardType } = {
  ...OverallDealership,
  ...BalanceSheet,
  ...NewVehicle,
  ...Parts,
  ...Service,
  ...FI,
  ...AfterSales,
  ...Driveway,
  ...UsedVehicle,
  ...BodyShop,
  ...Aftercare,
  ...OtherDept,
  ...FordAcademyKPIs,
  ...VCJProcessData,
  ...NetworkStats,
  ...fordSAScorecard,
};

export const AdditionalStatsMeasures: { [index: string]: CustomDashboardType } = {
  "OEM > Additional Stats > FRFT%": {
    name: "OEM > Additional Stats > FRFT%",
    department: "OEM",
    category: "Additional Stats",
    label: "FRFT%",
    measure: "MS > FORD CVP Service - FRFT% | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > TEST DRIVE Achievement MTD %": {
    name: "OEM > Additional Stats > TEST DRIVE Achievement MTD %",
    department: "OEM",
    category: "Additional Stats",
    label: "TEST DRIVE Achievement MTD %",
    measure: "MS > FORD INV & QC - Test Drive Achievement MTD % | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Prospect Achievement MTD%": {
    name: "OEM > Additional Stats > Prospect Achievement MTD%",
    department: "OEM",
    category: "Additional Stats",
    label: "Prospect Achievement MTD%",
    measure: "MS > FORD INV & QC - Prospect Achievement MTD% | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > PIP performance (FOA) New": {
    name: "OEM > Additional Stats > PIP performance (FOA) New",
    department: "OEM",
    category: "Additional Stats",
    label: "PIP performance (FOA) New",
    measure: "MS > FORD PFP - PIP performance (FOA) *new | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > No. of FGE implementations > Sales": {
    name: "OEM > Additional Stats > No. of FGE implementations > Sales",
    department: "OEM",
    category: "Additional Stats",
    label: "No. of FGE implementations - Sales",
    measure: "MS > FORD PFP - No. of FGE implementations - Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > No. of FGE implementations > Service": {
    name: "OEM > Additional Stats > No. of FGE implementations > Service",
    department: "OEM",
    category: "Additional Stats",
    label: "No. of FGE implementations - Service",
    measure: "MS > FORD PFP - No. of FGE implementations - Service | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Avg Sales per Sales Consultant per Mth": {
    name: "OEM > Additional Stats > Avg Sales per Sales Consultant per Mth",
    department: "OEM",
    category: "Additional Stats",
    label: "Avg Sales per Sales Consultant per Mth",
    measure: "MS > FORD PFP - Avg Sales per Sales Consultant per Mth | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Closing Ratio": {
    name: "OEM > Additional Stats > Closing Ratio",
    department: "OEM",
    category: "Additional Stats",
    label: "Closing Ratio",
    measure: "MS > FORD PFP - Closing Ratio | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Same Day Delivery %": {
    name: "OEM > Additional Stats > Same Day Delivery %",
    department: "OEM",
    category: "Additional Stats",
    label: "Same Day Delivery %",
    measure: "MS > FORD PFP - Same Day Delivery % | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Express Service Promise %": {
    name: "OEM > Additional Stats > Express Service Promise %",
    department: "OEM",
    category: "Additional Stats",
    label: "Express Service Promise %",
    measure: "MS > FORD PFP - Express Service Promise % (60mins gaurantee) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > CVP > Sales": {
    name: "OEM > Additional Stats > CVP > Sales",
    department: "OEM",
    category: "Additional Stats",
    label: "CVP - Sales",
    measure: "MS > FORD PFP - CVP - Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > CVP > Service": {
    name: "OEM > Additional Stats > CVP > Service",
    department: "OEM",
    category: "Additional Stats",
    label: "CVP - Service",
    measure: "MS > FORD PFP - CVP - Service | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > CVP > Delivery Process": {
    name: "OEM > Additional Stats > CVP > Delivery Process",
    department: "OEM",
    category: "Additional Stats",
    label: "CVP - Delivery Process",
    measure: "MS > FORD PFP - CVP - Delivery Process | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > JD Power Score > CSI": {
    name: "OEM > Additional Stats > JD Power Score > CSI",
    department: "OEM",
    category: "Additional Stats",
    label: "JD Power Score - CSI",
    measure: "MS > FORD PFP - JD Power Score - CSI | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > JD Power Score > SSI": {
    name: "OEM > Additional Stats > JD Power Score > SSI",
    department: "OEM",
    category: "Additional Stats",
    label: "JD Power Score - SSI",
    measure: "MS > FORD PFP - JD Power Score - SSI | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Sales Consultant Competence Mix": {
    name: "OEM > Additional Stats > Sales Consultant Competence Mix",
    department: "OEM",
    category: "Additional Stats",
    label: "Sales Consultant Competence Mix",
    measure: "MS > FORD PFP - Sales Consultant Competence Mix | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Sales Consultant Competence Mix L1%": {
    name: "OEM > Additional Stats > Sales Consultant Competence Mix L1%",
    department: "OEM",
    category: "Additional Stats",
    label: "Sales Consultant Competence Mix L1%",
    measure: "MS > FORD PFP - L1% | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Sales Consultant Competence Mix L2%": {
    name: "OEM > Additional Stats > Sales Consultant Competence Mix L2%",
    department: "OEM",
    category: "Additional Stats",
    label: "Sales Consultant Competence Mix L2%",
    measure: "MS > FORD PFP - L2% | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Sales Consultant Competence Mix L3%": {
    name: "OEM > Additional Stats > Sales Consultant Competence Mix L3%",
    department: "OEM",
    category: "Additional Stats",
    label: "Sales Consultant Competence Mix L3%",
    measure: "MS > FORD PFP - L3% | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Service Advisor Competence Mix L4%": {
    name: "OEM > Additional Stats > Service Advisor Competence Mix L4%",
    department: "OEM",
    category: "Additional Stats",
    label: "Service Advisor Competence Mix L4%",
    measure: "MS > FORD PFP - Service Advisor Competence Mix | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Sales Manager Competence Mix": {
    name: "OEM > Additional Stats > Sales Manager Competence Mix",
    department: "OEM",
    category: "Additional Stats",
    label: "Sales Manager Competence Mix",
    measure: "MS > FORD PFP - Sales Manager Competence Mix | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Average  % Of Dealer Staff Attrition": {
    name: "OEM > Additional Stats > Average  % Of Dealer Staff Attrition",
    department: "OEM",
    category: "Additional Stats",
    label: "Average  % Of Dealer Staff Attrition",
    measure: "MS > FORD Training - Average % of Dealer Staff Attrition | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Training Day %": {
    name: "OEM > Additional Stats > Training Day %",
    department: "OEM",
    category: "Additional Stats",
    label: "Training Day %",
    measure: "MS > FORD Training - Training Day % | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
  "OEM > Additional Stats > Training Day>YTD (Actual)": {
    name: "OEM > Additional Stats > Training Day>YTD (Actual)",
    department: "OEM",
    category: "Additional Stats",
    label: "Training Day-YTD (Actual)",
    measure: "MS > FORD Training - Training Day-YTD Actual | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 10000,
  },
};

export const OEMSupportAnalysis: { [index: string]: CustomDashboardType } = {
  ...OverallDealershipOEMSupportsAnalysis,
  ...NewVehicleOEMSupportsAnalysis,
  ...PartsOEMSupportsAnalysis,
  ...ServiceOEMSupportsAnalysis,
  ...FI_OEMSupportsAnalysis,
  ...AfterSalesOEMSupportsAnalysis,
  ...DrivewayOEMSupportsAnalysis,
  ...UsedVehicleOEMSupportsAnalysis,
  ...BodyShopOEMSupportsAnalysis,
};
