import React from "react";
import { Route } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { locations as layoutLocations } from "../layout";
import classnames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import { history } from "../lib/history";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    bar: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    link: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSize: "0.8rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
      fontWeight: "normal",
      color: "primary",
      cursor: "pointer",
      transform: "translateY(-2px)",
    },
  });

type BreadcrumbsProps = {} & WithStyles<typeof styles>;

const BreadcrumbsUnstyled: React.FunctionComponent<BreadcrumbsProps> = ({ classes }) => {
  const generateLinkSpecs = (childrenArr: any, pathsArr: string[], index: number) => {
    if (childrenArr.length === 0) {
      return [];
    }

    const link = childrenArr.find(
      el =>
        el.to === `/${pathsArr.slice(0, index).join("/")}` ||
        el.to === `/${pathsArr.slice(0, index + 1).join("/")}` ||
        (el.hasOwnProperty("url") && el.url === `/${pathsArr.slice(0, index).join("/")}`),
    );

    if (!link) return [];

    return [link, ...generateLinkSpecs(link.children || [], pathsArr, index + 1)];
  };

  return (
    <Route>
      {({ location }) => {
        const pathnames = location.pathname.slice(1).split("/");
        if (pathnames.length === 1 && pathnames[0] === "dashboard") {
          return (
            <nav aria-label="breadcrumbs" className={classes.bar}>
              <Link className={classes.link} component="button" style={{ textDecoration: "none" }}>
                Dashboard
              </Link>
            </nav>
          );
        }

        const paths = generateLinkSpecs(Object.values(layoutLocations), pathnames, 1);

        if (["Standard Reports", "OEM Reports", "Other BM Tools"].includes(pathnames[1]) && pathnames.length == 4) {
          paths.push({ to: `/reports/${pathnames[1]}/All Reports`, label: pathnames[1], id: `Reports / ${pathnames[1]}`, count: 0, children: [] });
          paths.push({ to: "#", label: pathnames[2] });
          paths.push({ to: `#`, label: pathnames[3] });
        }

        //  Special case for newly added report folders.
        if (["Standard Reports", "OEM Reports", "Other BM Tools"].includes(pathnames[1]) && pathnames.length == 3 && pathnames[2] != "All Reports") {
          paths.push({ to: `/reports/${pathnames[1]}/All Reports`, label: pathnames[1], id: `Reports / ${pathnames[1]}`, count: 0, children: [] });
          paths.push({
            to: `/reports/${pathnames[1]}/${pathnames[2]}`,
            label: `${pathnames[2]}`,
            id: `Reports / ${pathnames[1]} / ${pathnames[2]}`,
            count: 0,
            children: [],
          });
        }

        if (pathnames[0] == "oem" && pathnames[2] != "home" && pathnames.length == 3) {
          paths.push({ label: toTitleCase(pathnames[1].replace("-", " ")), to: "#" });
          paths.push({ label: pathnames[2] == "fordacademy" ? "Ford Academy" : toTitleCase(pathnames[2].replace("-", " ")), to: "#" });
        }

        return (
          <nav aria-label="breadcrumbs" className={classes.bar}>
            {paths.map((el, index) => {
              const last = index === pathnames.length - 1;

              return (
                <>
                  {index !== 0 && <span aria-hidden="true"> / </span>}
                  <Link
                    className={classes.link}
                    component="button"
                    onClick={() => {
                      history.push(el.to);
                    }}
                  >
                    {el.label}
                  </Link>
                </>
              );
            })}
          </nav>
        );
      }}
    </Route>
  );
};

export const toTitleCase = (text: string) => {
  return text.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const Breadcrumbs = React.memo(withStyles(styles)(BreadcrumbsUnstyled));
