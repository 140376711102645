import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const Aftercare: { [index: string]: CustomDashboardType } = {
  "AFTERCARE > SALES > Extended Warranty Contract NV": {
    name: "AFTERCARE > SALES > Extended Warranty Contract NV",
    department: "AFTERCARE",
    category: "SALES",
    label: "Extended Warranty Contract NV",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Warranty Contracts | New | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > Extended Warranty Contract UV": {
    name: "AFTERCARE > SALES > Extended Warranty Contract UV",
    department: "AFTERCARE",
    category: "SALES",
    label: "Extended Warranty Contract UV",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Warranty Contracts | Used | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > Aftercare Total Sales": {
    name: "AFTERCARE > SALES > Aftercare Total Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "Aftercare Total Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > Aftercare Extended Warranty Sales": {
    name: "AFTERCARE > SALES > Aftercare Extended Warranty Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "Aftercare Extended Warranty Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Extended Warranties | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > Aftercare Protection, Window Tint Sales": {
    name: "AFTERCARE > SALES > Aftercare Protection, Window Tint Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "Aftercare Protection, Window Tint Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftercare Protection / Window Tinting Sales Net Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > Aftercare Other Sales": {
    name: "AFTERCARE > SALES > Aftercare Other Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "Aftercare Other Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftercare Other Sales Net Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > NV Aftercare Sales": {
    name: "AFTERCARE > SALES > NV Aftercare Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "NV Aftercare Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftercare Net Sales New | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > SALES > UV Aftercare Sales": {
    name: "AFTERCARE > SALES > UV Aftercare Sales",
    department: "AFTERCARE",
    category: "SALES",
    label: "UV Aftercare Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftercare Net Sales Used | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > AFTERCARE MIX": {
    name: "AFTERCARE > MIX > AFTERCARE MIX",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "AFTERCARE MIX",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > AFTERCARE Total Sales": {
    name: "AFTERCARE > MIX > AFTERCARE Total Sales",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "AFTERCARE Total Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > AFTERCARE Total GP": {
    name: "AFTERCARE > MIX > AFTERCARE Total GP",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "AFTERCARE Total GP",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > NV Aftercare GP / NV Unit": {
    name: "AFTERCARE > MIX > NV Aftercare GP / NV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "NV Aftercare GP / NV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Gross New pu | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > NV Extended Warranty GP / NV Unit": {
    name: "AFTERCARE > MIX > NV Extended Warranty GP / NV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "NV Extended Warranty GP / NV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Extended Warranty New Gross pu | NewAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > NV Protection Window Tint GP / NV Unit": {
    name: "AFTERCARE > MIX > NV Protection Window Tint GP / NV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "NV Protection Window Tint GP / NV Unit ",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Protection / Window Tinting Gross New pu | NewAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > NV Aftercare Other GP / NV Unit": {
    name: "AFTERCARE > MIX > NV Aftercare Other GP / NV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "NV Aftercare Other GP / NV Unit ",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Other income Gross New pu | NewAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > UV Aftercare GP / UV Unit": {
    name: "AFTERCARE > MIX > UV Aftercare GP / UV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "UV Aftercare GP / UV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Gross Used pu | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > UV Extended Warranty GP / UV Unit": {
    name: "AFTERCARE > MIX > UV Extended Warranty GP / UV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "UV Extended Warranty GP / UV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Extended Warranty Used Gross pu | UsedAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > UV Protection, Window Tint GP / UV Unit": {
    name: "AFTERCARE > MIX > UV Protection, Window Tint GP / UV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "UV Protection, Window Tint GP / UV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Protection / Window Tinting Gross Used pu | UsedAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > MIX > UV Aftercare Other GP / UV Unit": {
    name: "AFTERCARE > MIX > UV Aftercare Other GP / UV Unit",
    department: "AFTERCARE",
    category: "GROSS PROFIT",
    label: "UV Aftercare Other GP / UV Unit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Other income Gross Used pu | UsedAftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > AFTERCARE RETAINED": {
    name: "AFTERCARE > RETAINED > AFTERCARE RETAINED",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "AFTERCARE RETAINED",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED |  Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > AFTERCARE Total GP": {
    name: "AFTERCARE > RETAINED > AFTERCARE Total GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "AFTERCARE Total GP",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > AFTERCARE Total Expenses": {
    name: "AFTERCARE > RETAINED > AFTERCARE Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "AFTERCARE Total Expenses",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > AFTERCARE Total PBT": {
    name: "AFTERCARE > RETAINED > AFTERCARE Total PBT",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "AFTERCARE Total PBT",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Total Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Total Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Total Expenses % GP",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Variable Expenses  % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Variable Expenses  % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Variable Expenses  % GP",
    tableNumber: 145,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Personnel  Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Personnel  Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Personnel  Expenses % GP",
    tableNumber: 146,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Other Semi Variable Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Other Semi Variable Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Other Semi Variable Expenses % GP",
    tableNumber: 147,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Fixed Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Fixed Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Fixed Expenses % GP",
    tableNumber: 148,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Interest Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Interest Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Interest Expenses % GP",
    tableNumber: 149,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Allocation of Admin  Expenses % GP": {
    name: "AFTERCARE > RETAINED > Aftercare Allocation of Admin  Expenses % GP",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Allocation of Admin  Expenses % GP",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Variable Expenses% Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Variable Expenses% Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Variable Expenses% Total Expenses",
    tableNumber: 145,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Personnel Expenses% Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Personnel Expenses% Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Personnel Expenses% Total Expenses",
    tableNumber: 146,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Other Semi Var.Exp.% Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Other Semi Var.Exp.% Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Other Semi Var.Exp.% Total Expenses",
    tableNumber: 147,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Fixed Expenses% Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Fixed Expenses% Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Fixed Expenses% Total Expenses",
    tableNumber: 148,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Interest Expenses % Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Interest Expenses % Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Interest Expenses % Total Expenses",
    tableNumber: 149,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Allocation of Admin Expenses % Total Expenses": {
    name: "AFTERCARE > RETAINED > Aftercare Allocation of Admin Expenses % Total Expenses",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Allocation of Admin Expenses % Total Expenses",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation % of Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > RETAINED > Aftercare Cost Recovery": {
    name: "AFTERCARE > RETAINED > Aftercare Cost Recovery",
    department: "AFTERCARE",
    category: "EXPENSES",
    label: "Aftercare Cost Recovery",
    tableNumber: 174,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ACTIVITY > AFTERCARE ACTIVITY": {
    name: "AFTERCARE > ACTIVITY > AFTERCARE ACTIVITY",
    department: "AFTERCARE",
    category: "OPERATIONAL ASSETS",
    label: "AFTERCARE ACTIVITY",
    tableNumber: 150,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > ACTIVITY > AFTERCARE Annualised Sales": {
    name: "AFTERCARE > ACTIVITY > AFTERCARE Annualised Sales",
    department: "AFTERCARE",
    category: "OPERATIONAL ASSETS",
    label: "AFTERCARE Annualised Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Projected Annualised Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ACTIVITY > AFTERCARE Average Operational Assets": {
    name: "AFTERCARE > ACTIVITY > AFTERCARE Average Operational Assets",
    department: "AFTERCARE",
    category: "OPERATIONAL ASSETS",
    label: "AFTERCARE Average Operational Assets",
    tableNumber: 150,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Operational Assets | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > ROOA > AFTERCARE ROOA": {
    name: "AFTERCARE > ROOA > AFTERCARE ROOA",
    department: "AFTERCARE",
    category: "MRA MODEL",
    label: "AFTERCARE ROOA",
    tableNumber: 151,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Operational Assets (ROOA) | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > ROOA > AFTERCARE MIX": {
    name: "AFTERCARE > ROOA > AFTERCARE MIX",
    department: "AFTERCARE",
    category: "MRA MODEL",
    label: "AFTERCARE MIX",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROOA > AFTERCARE RETAINED": {
    name: "AFTERCARE > ROOA > AFTERCARE RETAINED",
    department: "AFTERCARE",
    category: "MRA MODEL",
    label: "AFTERCARE RETAINED",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROOA > AFTERCARE ACTIVITY": {
    name: "AFTERCARE > ROOA > AFTERCARE ACTIVITY",
    department: "AFTERCARE",
    category: "MRA MODEL",
    label: "AFTERCARE ACTIVITY",
    tableNumber: 150,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare ROS": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare ROS",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare ROS",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Sales": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Sales",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Sales",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare PBT": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare PBT",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare PBT",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Dept. Profit % Net Sales": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Dept. Profit % Net Sales",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Dept. Profit % Net Sales",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest % of TNS | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare EBIT% (PBIT%)": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare EBIT% (PBIT%)",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare EBIT% (PBIT%)",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT % of Sales | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Variable Selling Gross % Gorss Profit": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Variable Selling Gross % Gorss Profit",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Variable Selling Gross % Gorss Profit",
    tableNumber: 145,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Variable Selling Gross % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Selling Gross % Gross Profit": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Selling Gross % Gross Profit",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Selling Gross % Gross Profit",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TGP | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Selling Gross % Net Sales": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Selling Gross % Net Sales",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Selling Gross % Net Sales",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TNS | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Gross Profit": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Gross Profit",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Gross Profit",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Expenses",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Variable Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Variable Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Variable Expenses",
    tableNumber: 145,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Personnel Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Personnel Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Personnel Expenses",
    tableNumber: 146,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Other Semi Variable Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Other Semi Variable Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Other Semi Variable Expenses",
    tableNumber: 147,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Fixed Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Fixed Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Fixed Expenses",
    tableNumber: 148,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Interest Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Interest Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Interest Expenses",
    tableNumber: 149,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Departmental Profit": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Departmental Profit",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Departmental Profit",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings After Interest (EAIT) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare Allocation of Admin Expenses": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare Allocation of Admin Expenses",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare Allocation of Admin Expenses",
    tableNumber: 144,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Exp. Allocation | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > ROS (Income Statement) > Aftercare EBIT": {
    name: "AFTERCARE > ROS (Income Statement) > Aftercare EBIT",
    department: "AFTERCARE",
    category: "ROS (Income Statement)",
    label: "Aftercare EBIT",
    tableNumber: 152,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT (Profit Before Interest & Tax) | Aftercare | All Brand | All Model | | SUM",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare GP / Productive / Month": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare GP / Productive / Month",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare GP / Productive / Month",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Sales / Productive / Month": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Sales / Productive / Month",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Sales / Productive / Month",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Sales per Productive | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare GP / Employee / Month": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare GP / Employee / Month",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare GP / Employee / Month",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Employee | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Total Staff": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Total Staff",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Total Staff",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Total Employees | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Productive Staff": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Productive Staff",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Productive Staff",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Productives | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Non-Productive Staff": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Non-Productive Staff",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Non-Productive Staff",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Non-Productives | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Ratio Productive : Non Productive": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Ratio Productive : Non Productive",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Ratio Productive : Non Productive",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Productive to Non-Productive staff | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Staff Allocation NV": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Staff Allocation NV",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Staff Allocation NV",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Aftercare Allocation New | Aftercare | All Brand | All Model | | AVG",
  },
  "AFTERCARE > PRODUCTIVITY > Aftercare Staff Allocation UV": {
    name: "AFTERCARE > PRODUCTIVITY > Aftercare Staff Allocation UV",
    department: "AFTERCARE",
    category: "PRODUCTIVITY",
    label: "Aftercare Staff Allocation UV",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Aftercare Allocation Used | Aftercare | All Brand | All Model | | AVG",
  },
};
