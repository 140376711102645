import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const VCJProcessData1: { [index: string]: CustomDashboardType } = {
  "VCJ Process Data 2022 > VCJ Sales > New Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Sales Target > New Retail Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Units Sold (Incl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Target Vs Units Sold (Incl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Target Vs Units Sold (Incl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Target Vs Units Sold (Incl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Lost Opportunity (Incl.Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Target Achievement % (Incl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Target Achievement % (Incl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Target Achievement % (Incl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Sales Target Achievement % (Incl.Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Units Sold (Excl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Target Vs Units Sold (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Target Vs Units Sold (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Target Vs Units Sold (Excl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Lost Opportunity | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Target Achievement % (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Target Achievement % (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Target Achievement % (Excl. Demo)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Sales Target Achievement % | New Retail | Volvo Japan | All Model | | SUM",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo) YOY %": {name: "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo) YOY %",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Units Sold (Incl. Demo) YOY %",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "",},
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo) Last Year": {name: "VCJ Process Data 2022 > VCJ Sales > New Units Sold (Incl. Demo) Last Year",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Units Sold (Incl. Demo) Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New Demo Units Sold": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Demo Units Sold",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Demo Units Sold",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Demo Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Showroom Visit Appointment",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Showroom Visit Appointment | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Rate": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Showroom Visit Appointment Rate",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Showroom Visit Appointment Rate | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Actual": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Showroom Visit Appointment Actual",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Showroom Visit Appointment Actual | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Actual Vs Appointment %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Actual Vs Appointment %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Showroom Visit Appointment Actual Vs Appointment %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Showroom Visit Actual vs Appointment % | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Existing Owner Visitors Actual": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Existing Owner Visitors Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Existing Owner Visitors Actual",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Existing Owner Visitors Rate (excl. Service)": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Existing Owner Visitors Rate (excl. Service)",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Showroom Existing Owner Visitors Rate (excl. Service)",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Existing Owner (excl. service) rate | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process New & Stock Visitors % Import Car Registration unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process New & Stock Visitors % Import Car Registration unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process New & Stock Visitors % Import Car Registration unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New & Stock Visitors % Import Car Reg Unit | New Retail | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Sales > New Sales Process New Visitors Actual": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process New Visitors Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process New Visitors Actual",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Stock Visitors Actual": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Stock Visitors Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Stock Visitors Actual",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Total Visitors Actual": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Total Visitors Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Total Visitors Actual",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Visitors Total | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry - Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Total Visiors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive - Total Visiors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive - Total Visiors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order - Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual - Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Strategy": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual - Strategy",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual - Strategy",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Strategy Actual | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry % Exiting Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry % Exiting Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry % Exiting Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry % Exiting Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry New & Stock % New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry New & Stock % New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry % Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry % Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry % Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry % Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry Total Visitors % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Customer Inquiry Total Visitors % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Customer Inquiry Total Visitors % Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Inquiry Total Visitors % Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Customer Inquiry Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Customer Inquiry Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % Customer Inquiry New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % Customer Inquiry New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order New & Stock % Customer Inquiry New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New & Stock % Customer Inquiry New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Customer Inquiry Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Customer Inquiry Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Total": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Customer Inquiry Total",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Customer Inquiry Total",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Customer Inquiry Total | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order New & Stock % New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New & Stock % New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive New & Stock % New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive New & Stock % New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive % Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive % Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Test Drive % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Test Drive % Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive % Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Test Drive Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % Test Drive New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order New & Stock % Test Drive New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order New & Stock % Test Drive New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New & Stock % Test Drive New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Test Drive Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Test Drive Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Retail Order % Test Drive Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Retail Order % Test Drive Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual New & Stock % New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual New & Stock % New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Strategy Actual % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Strategy Actual % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Strategy Actual % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Strategy Actual % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual & Strategy % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual & Strategy % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual & Strategy % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual & Strategy % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Test Drive Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual New & Stock % Test Drive New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual New & Stock % Test Drive New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual New & Stock % Test Drive New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual New & Stock % Test Drive New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Test Drive Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Test Drive Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Actual % Test Drive Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Actual % Test Drive Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Actual % Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Strategy": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target - Strategy",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target - Strategy",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Strategy Target | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target New & Stock % New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target New & Stock % New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target % Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target % Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target % Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target % Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Strategy Target % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Strategy Target % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Strategy Target % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Strategy Target % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target & Strategy % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Assessment Target & Strategy % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process Assessment Target & Strategy % Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Assessment Target & Strategy % Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Target Units": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Car Retail Order Target Units",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Car Retail Order Target unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Actual Units": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Actual Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Car Retail Order Actual Units",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Achievement %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Car Retail Order Achievement %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Car Retail Order Achievement %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Total Retail Order % Target Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Test Drive - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Test Drive - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Test Drive New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Test Drive - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Test Drive Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Test Drive Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Test Drive Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order % Test Drive Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order % EV Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order New & Stock % Test Drive New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order New & Stock % Test Drive New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order New & Stock % Test Drive New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order New & Stock % EV Test Drive New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order % Test Drive Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order % EV Test Drive Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process EV Retail Order % Test Drive Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Process EV Retail Order % Test Drive Total Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Retail Order % EV Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Alternative Owner": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Alternative Owner",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Alternative Owner",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Alternative Owner | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Annualised Alternative Owner Rate": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Annualised Alternative Owner Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Annualised Alternative Owner Rate",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annualised Alternative Owner Rate | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Owner Current": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Owner Current",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Owner Current",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Owner Current | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sale Consultant": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sale Consultant",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Sale Consultant",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sale Consultant | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Registration": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Registration",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Car Registration",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Sale Registration | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Registration per SC per month": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Registration per SC per month",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Car Registration per SC per month",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Registration per SC per month | New Retail | All Brand | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling No. of Prospect Central Lead": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling No. of Prospect Central Lead",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling No. of Prospect Central Lead",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > No of Prospect Central Lead | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Sales Unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Sales Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Prospect Lead Sales Unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Retail Sales Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sales Unit % Prospect Lead": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sales Unit % Prospect Lead",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Sales Unit % Prospect Lead",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Sales Unit % Prospect Lead | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Closing Unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Closing Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Prospect Lead Closing Unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Closing Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Closing Rate": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Prospect Lead Closing Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Prospect Lead Closing Rate",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Closing Rate | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Spec Number of Uses": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Car Spec Number of Uses",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Car Spec Number of Uses",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Spec Number of Uses | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling In-house Sales Unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > In-house Sales Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Focus %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Focus %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling In-house Sales Focus %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > In-house Sales Focus % | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Unit % Total Sales Status": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling In-house Sales Unit % Total Sales Status",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling In-house Sales Unit % Total Sales Status",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > In-house Sales Unit % Total Sales Status | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sales Pump in Unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Sales Pump in Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Sales Pump in Unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Pump in Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Total Sales Status": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Total Sales Status",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Total Sales Status",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Sales Status | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling DMA Sales Status Pump Out": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling DMA Sales Status Pump Out",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling DMA Sales Status Pump Out",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DMA Sales Status Pump Out | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Import Car Registration Unit": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Import Car Registration Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Import Car Registration Unit",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Import Car Registration Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Market Share excl Pump In": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Market Share excl Pump In",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Market Share excl Pump In",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Market Share excl Pump In | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Market Share incl Pump In": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Sales Profiling Market Share incl Pump In",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Sales Profiling Market Share incl Pump In",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Market Share incl Pump In | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Volvo": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Volvo",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Volvo",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Volvo %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Volvo %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Volvo %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > YTD Market Share % (Brand) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Audi": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Audi",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Audi",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New Retail | Audi | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Audi %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Audi %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Audi %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > YTD Market Share % (Brand) | New Retail | Audi | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Mercedes-Benz": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Mercedes-Benz",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Mercedes-Benz",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New Retail | Mercedes-Benz | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Mercedes-Benz %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Mercedes-Benz %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Mercedes-Benz %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > YTD Market Share % (Brand) | New Retail | Mercedes-Benz | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share BMW": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share BMW",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share BMW",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New Retail | BMW | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share BMW %": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share BMW %",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share BMW %",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > YTD Market Share % (Brand) | New Retail | BMW | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Total Brand": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Market Share Total Brand",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Market Share Total Brand",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New Retail | All Brand | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Q1 Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Q1 Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Q1 Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q1 Units Projected | New | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Q1 Units Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New Q1 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Q1 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New Q2 Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Q2 Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Q2 Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q2 Units Projected | New | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Q2 Units Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New Q2 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Q2 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New Q3 Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Q3 Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Q3 Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q3 Units Projected | New | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Q3 Units Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New Q3 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Q3 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New Q4 Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Q4 Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Q4 Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q4 Units Projected | New | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New Q4 Units Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New Q4 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Q4 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New YTD Units Target": {
    name: "VCJ Process Data 2022 > VCJ Sales > New YTD Units Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New YTD Units Target",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annual Units Projected | New | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Sales > New YTD Units Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New YTD Units Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New YTD Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Sales > New Plug-in Hybrid EV Units Target ": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Plug-in Hybrid EV Units Target ",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Plug-in Hybrid EV Units Target ",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Plug-in Hybrid EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Plug-in Hybrid EV Units Actual ": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Plug-in Hybrid EV Units Actual ",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Plug-in Hybrid EV Units Actual ",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Plug-in Hybrid EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Battery EV Units Target ": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Battery EV Units Target ",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Battery EV Units Target ",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Battery EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New EV Units Actual ": {
    name: "VCJ Process Data 2022 > VCJ Sales > New EV Units Actual ",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New EV Units Actual ",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Battery EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Battery EV Retail Order - Existing Owner Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Battery EV Retail Order Existing Owner Visitors | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Battery EV Retail Order - New & Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Battery EV Retail Order New & Stock Visitors | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Battery EV Retail Order - New Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Battery EV Retail Order New Visitors | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Sales > New Battery EV Retail Order - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Sales",
    label: "New Battery EV Retail Order - Stock Visitors",
    tableNumber: 300,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Battery EV Retail Order Stock Visitors | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Target Units": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Target Units",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Sales Target > Used Units | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Actual Units": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Actual Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Actual Units",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Achievement": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Achievement",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Achievement",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > UV Retail Achievement | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Achievement %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Achievement %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Achievement %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > UV Retail Achievement % | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Certified Units Sold": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Certified Units Sold",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Certified Units Sold",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Units Sold | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Certified % Used Units": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Certified % Used Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Certified % Used Units",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified % Used Retail Units | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Units Sold (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Units Sold (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Units Sold (Excl. Demo)",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold Vs New Units Sold (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold Vs New Units Sold (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Units Sold Vs New Units Sold (Excl. Demo)",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Used vs New Units Sold (excl Demo) | Used | Volvo Japan | All Model | | SUM",
  },
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Units Sold Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold YOY %": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Units Sold YOY %",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Units Sold YOY %",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "",},
  "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Extended Warranty Sold-Used": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Extended Warranty Sold-Used",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Service Plan Extended Warranty Sold-Used",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Extended Warranty Sold | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Extended Warranty % Used Certified Units Sold": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Extended Warranty % Used Certified Units Sold",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Service Plan Extended Warranty % Used Certified Units Sold",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Extended Warranty % Certified Units Sold | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Certified Maintenance Package": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Certified Maintenance Package",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Service Plan Certified Maintenance Package",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Maintenance Package | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Certified Maintenance Package % Used Certified Units Sold": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Service Plan Certified Maintenance Package % Used Certified Units Sold",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Service Plan Certified Maintenance Package % Used Certified Units Sold",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Maintenance Package % Certified Units Sold | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Selekt SMAVO": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Selekt SMAVO",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Selekt SMAVO",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Selekt SMAVO - Used | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Selekt Penetration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Selekt Penetration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Selekt Penetration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Selekt SMAVO Penetration Used | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Loan Contracts": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Loan Contracts",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Loan Contracts",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loan Contracts - Used | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Loan Contracts Penetration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Loan Contracts Penetration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Loan Contracts Penetration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Car Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Active Loan Contracts": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Active Loan Contracts",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Active Loan Contracts",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Active Loan Contracts - Used | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Active Loan Contracts Penetration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Used Contracts Active Loan Contracts Penetration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Used Contracts Active Loan Contracts Penetration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Car Active Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Loan/Credit Contracts": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Loan/Credit Contracts",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Loan/Credit Contracts",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loan Contracts - New | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Loan/Credit Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Loan/Credit Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Loan/Credit Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Car Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Active Loan Contracts": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Active Loan Contracts",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Active Loan Contracts",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Active Loan Contracts - New | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Active Loan Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Active Loan Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Active Loan Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Car Active Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts SMAWARI 50 Loan": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts SMAWARI 50 Loan",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts SMAWARI 50 Loan",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > SMAWARI 50 Loan | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts SMAWARI 50 Loan Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts SMAWARI 50 Loan Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts SMAWARI 50 Loan Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Car SMAWARI 50 Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Selekt SMAVO": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Selekt SMAVO",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Selekt SMAVO",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Selekt SMAVO - New | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Selekt SMAVO Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Selekt SMAVO Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Selekt SMAVO Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Selekt SMAVO Penetration - New | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Composition New Car within 3 year Order": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Composition New Car within 3 year Order",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Composition New Car within 3 year Order",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Composition New car within 3 year Order | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Composition New Car within 3 year %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Composition New Car within 3 year %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Composition New Car within 3 year %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Composition New Car within 3 years % | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup - No of Reports": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup - No of Reports",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Process Expiry Followup - No of Reports",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Expiry Followup - No of Reports | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup - Orders": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup - Orders",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Process Expiry Followup - Orders",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Expiry Followup - Orders | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup Orders % No of Reports": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Process Expiry Followup Orders % No of Reports",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Process Expiry Followup Orders % No of Reports",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Expiry Followup Order % No of Reports | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order - Existing Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Existing Owner Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Existing Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Existing Owner Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order % Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order % Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order % Existing Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Existing Owner Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order - New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New & Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New & Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order New & Stock % New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order New & Stock % New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order New & Stock % New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New & Stock % New & Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order - Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Total Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Visitors Total | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order % Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order % Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order % Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Total Visitors | Used | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Otoriyose Buy": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Otoriyose Buy",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Otoriyose Buy",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Otoriyose Buy | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Otoriyose Sell": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Otoriyose Sell",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Otoriyose Sell",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Otoriyose Sell | Used | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Exisiting Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Exisiting Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in - Exisiting Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Owner Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Exisiting Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Exisiting Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in % Retail Order Exisiting Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Existing Owner Visitors (Used) | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order - New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order New Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in - New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold New Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in % Retail Order New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order New Visitors (Used) | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process Retail Order - Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in - Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in % Retail Order Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Stock Visitors (Used) | Used | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in - New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold New & Stock Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in % Retail Order New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Stock & New Visitors (Used) | Used | Volvo Japan | All Model | | SUM",
  },

  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in - Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Total Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Trade-in % Retail Order Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Trade-in % Retail Order Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Total Visitors (Used) | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Retail Order - Existing Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Retail Order Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Exisiting Owner Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Exisiting Owner Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in - Exisiting Owner Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Exisiting Owner": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Exisiting Owner",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in % Retail Order Exisiting Owner",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Existing Owner Visitors (New) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Retail Order - New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Retail Order New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in - New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold New Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in % Retail Order New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order New Visitors (New) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Retail Order - Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Retail Order Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in - Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in % Retail Order Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Stock Visitors (New) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Retail Order - New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Retail Order New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in - New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold New & Stock Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order New & Stock Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order New & Stock Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in % Retail Order New & Stock Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Stock & New Visitors (New) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Retail Order - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Retail Order - Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Retail Order Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in - Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in - Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in Units Sold Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Total Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > New Sales Profiling Trade-in % Retail Order Total Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "New Sales Profiling Trade-in % Retail Order Total Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Trade-in % Retail Order Total Visitors (New) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory UV Inventory Units 0-30 days": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory UV Inventory Units 0-30 days",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Inventory UV Inventory Units 0-30 days",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > UV Inventory Units 0-30 days | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory UV Stock 3 Months Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory UV Stock 3 Months Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Inventory UV Stock 3 Months Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > UV Stock 3 Months Target | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory Months Sales of Inventory": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Inventory Months Sales of Inventory",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Inventory Months Sales of Inventory",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Months Sales of Inventory | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts No. of Existing Owners Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts No. of Existing Owners Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts No. of Existing Owners Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > No of Existing Owners | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration Existing Owner Insurance Penetration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration Existing Owner Insurance Penetration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Penetration Existing Owner Insurance Penetration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Existing Owner Insurance penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration Existing Owner Insurance Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration Existing Owner Insurance Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Penetration Existing Owner Insurance Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Existing Owner Insurance penetration % | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used and New Units Sold (Excl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used and New Units Sold (Excl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used and New Units Sold (Excl. Demo)",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Used & New Units Sold (excl Demo) | VehicleSales | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration New Visitors Insurance Penetration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration New Visitors Insurance Penetration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Penetration New Visitors Insurance Penetration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > No of New Contracts | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration New Visitors Insurance Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I Penetration New Visitors Insurance Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I Penetration New Visitors Insurance Penetration %",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance penetration % | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Expiry Number": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Expiry Number",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Insurance Expiry Number",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Expiry Number | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Renewal Number": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Renewal Number",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Insurance Renewal Number",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Renewal Number | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Renewal Rate": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > F&I New Contracts Insurance Renewal Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "F&I New Contracts Insurance Renewal Rate",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Renewal Rate | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Sale Consultant": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling Sale Consultant",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling Sale Consultant",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sale Consultant | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling UV Car Registration": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling UV Car Registration",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling UV Car Registration",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Sale Registration | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling UV Car Registration per SC per month": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Profiling UV Car Registration per SC per month",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Profiling UV Car Registration per SC per month",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Registration per SC per month | Used | All Brand | All Model | | SUM",
  },
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Actual Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Actual Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q1 Unit Actual Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Actual Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Actual Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q2 Unit Actual Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Actual Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Actual Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q3 Unit Actual Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Actual Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Actual Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q4 Unit Actual Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Actual Last Year": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Actual Last Year",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used YTD Unit Actual Last Year",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Q1 Unit Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q1 Units Projected | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Q2 Unit Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q2 Units Projected | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Q3 Unit Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q3 Units Projected | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Q4 Unit Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q4 Units Projected | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Target": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used YTD Unit Target",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annual Units Projected | Used | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Actual": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q1 Unit Actual",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q1 Unit Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Actual": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q2 Unit Actual",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q2 Unit Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Actual": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q3 Unit Actual",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q3 Unit Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Actual": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Q4 Unit Actual",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Q4 Unit Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Actual": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used YTD Unit Actual",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used YTD Unit Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process New Visitors": {
    name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process New Visitors",
    department: "VCJ Process Data 2022",
    category: "VCJ Value Chain",
    label: "Used Sales Process New Visitors",
    tableNumber: 301,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Visitors | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Total Active Customers",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Total No of Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Active Customers": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Active Customers",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Active Customers",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Semi Active Customers": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Semi Active Customers",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Semi Active Customers",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of Semi Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Other Active Customers": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Other Active Customers",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Other Active Customers",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of Other Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Prior Year End": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Prior Year End",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Total Active Customers Prior Year End",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Total No of Active Customer Prior Year end | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Vs Prior Year End": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Vs Prior Year End",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Total Active Customers Vs Prior Year End",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Active Customer vs Prior Year End % | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Active Customers Prior Quarter": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Active Customers Prior Quarter",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Active Customers Prior Quarter",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of Active Customer Prior Quarter | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Vs Prior Quarter": {
    name: "VCJ Process Data 2022 > VCJ Service > Service 24MR Total Active Customers Vs Prior Quarter",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service 24MR Total Active Customers Vs Prior Quarter",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Active Customer vs Prior Qtr % | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR DMA": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR DMA",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR DMA",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration 24 MR DMA | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Total Active Customer Vs DMA": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Total Active Customer Vs DMA",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR Total Active Customer Vs DMA",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Total Active Customer vs Car Park Penetration | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Prior Year End": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Prior Year End",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR Prior Year End",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration 24 MR Prior Year | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Vs Prior Year End": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Vs Prior Year End",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR Vs Prior Year End",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration vs Prior Year End | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Prior Quarter": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Prior Quarter",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR Prior Quarter",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration 24 MR Previous Qtr | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Vs Prior Quarter": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Car Park Penetration 24 MR Vs Prior Quarter",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Car Park Penetration 24 MR Vs Prior Quarter",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration vs Prior Qtr | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Parts Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales Actual": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales Actual",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales Target Achievement %": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales Target Achievement %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales Target Achievement %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Active Customers": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Active Customers",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Active Customers",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Active Customers | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales per Active Customer": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales per Active Customer",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales per Active Customer",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Sale per Active Customer | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales per Unit YOY %": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales per Unit YOY %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales per Unit YOY %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Sales per unit YOY % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Sales Per Unit Prior Year": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Sales Per Unit Prior Year",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Sales Per Unit Prior Year",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Sale Per unit Prior Year | Parts | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sales Actual": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sales Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sales Actual",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sales Target Achievement %": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sales Target Achievement %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sales Target Achievement %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessories Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > New Units Sold (Incl. Demo)": {
    name: "VCJ Process Data 2022 > VCJ Service > New Units Sold (Incl. Demo)",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "New Units Sold (Incl. Demo)",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sale Per Unit",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit YOY %": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit YOY %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sale Per Unit YOY %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessories Sales per unit YOY % | Parts | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit Prior Year": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Sale Per Unit Prior Year",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Sale Per Unit Prior Year",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessories Sale Per unit Prior Year | Parts | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Tire Incentive Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentive Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentive Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Tire Incentive Actual": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentive Actual",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentive Actual",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Tire Incentive Target Achievement %": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentive Target Achievement %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentive Target Achievement %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Tire Incentive Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Actual Parts Sales Last Year": {
    name: "VCJ Process Data 2022 > VCJ Service > Actual Parts Sales Last Year",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Actual Parts Sales Last Year",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Parts Sales Last Year | Parts | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Actual Accessories Sales Last Year": {
    name: "VCJ Process Data 2022 > VCJ Service > Actual Accessories Sales Last Year",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Actual Accessories Sales Last Year",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Accessories Sales Last Year | Parts | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ Service > Actual Accessories Sales YOY %": {
    name: "VCJ Process Data 2022 > VCJ Service > Actual Accessories Sales YOY %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Actual Accessories Sales YOY %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessories Sales actual YOY % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Shaken Retention Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Shaken Retention Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Shaken Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Shaken Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Tenken Retention Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Tenken Retention Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Tenken Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Tenken Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 6Mth Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 6Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 6Mth Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 6Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 6Mth Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 6Mth Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 6Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 18Mth Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 18Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 18Mth Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 18Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 18Mth Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 18Mth Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 18Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 30Mth Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 30Mth Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 30Mth Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 30Mth Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 42Mth Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 42Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 42Mth Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 42Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 42Mth Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 42Mth Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 42Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 54Mth Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 54Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 54Mth Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 54Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Retention %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP Retention 54Mth Retention %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP Retention 54Mth Retention %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 54Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit Total": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit Total",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Service Visit Total",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Service Visit Owner Paid | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit Maintenance": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit Maintenance",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Service Visit Maintenance",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit Maintenance | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit General": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit General",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Service Visit General",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit General | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit BP": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Service Visit BP",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Service Visit BP",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit BP | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken 0-1": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken 0-1",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan 1 day and 2 nights VCP Shaken 0-1",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 2 day and 1 nights VCP Shaken | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken Q4 Total": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken Q4 Total",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan 1 day and 2 nights VCP Shaken Q4 Total",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 2 day and 1 nights VCP Shaken Q4 Total | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan 1 day and 2 nights VCP Shaken %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan 1 day and 2 nights VCP Shaken %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 2 day and 1 nights VCP Shaken % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Target Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Target Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Target Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 4-5year (Excl. SMAVO) Target  units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 4-5year (Excl. SMAVO) Penetration units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year (Excl.SMAVO) - New Car Penetration %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 4-5year (Excl. SMAVO) Penetration % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Target Unit": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Target Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year - New Car Target Unit",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit (Excl. BEV) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Penetration Unit": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Penetration Unit",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year - New Car Penetration Unit",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 4-5year (All) Penetration units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Penetration %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 4-5year - New Car Penetration %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 4-5year - New Car Penetration %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 4-5year (All) Penetration % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 6-7year - Penetration Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 6-7year - Penetration Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 6-7year - Penetration Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 6-7years - Penetration Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 8-9year - Penetration Units ": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 8-9year - Penetration Units ",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 8-9year - Penetration Units ",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 8-9years - Penetration Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 6-9year - Penetration Units": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan VSP PLUS 6-9year - Penetration Units",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan VSP PLUS 6-9year - Penetration Units",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 6-9years Total | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Vehicle Report - Technical Matters Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Vehicle Report - Technical Matters Target | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Report": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Report",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Vehicle Report - Technical Matters Report",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Vehicle Report - Technical Matters Report | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Report %": {
    name: "VCJ Process Data 2022 > VCJ Service > Service Plan Vehicle Report - Technical Matters Report %",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Service Plan Vehicle Report - Technical Matters Report %",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Vehicle Report - Technical Matters Reporting % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Units in Operation this year": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Units in Operation this year",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Units in Operation this year",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Sales 12MR | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Stock Amount": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Stock Amount",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Stock Amount",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Stocking Stocked | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Dead Stock Amount": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Dead Stock Amount",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Dead Stock Amount",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Stocking Lost | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Stock Turnover": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Stock Turnover",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Stock Turnover",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Turnover | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Dead Stock Rate": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Dead Stock Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Dead Stock Rate",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer stock VR Parts Dead stock rate | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Dealer Stock in previous month": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Dealer Stock in previous month",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Stock Parts - Dealer Stock in previous month",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Dealer Stock in previous month | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Car Maintained": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Car Maintained",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Stock Parts - Car Maintained",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Stock car maintained | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Maintenance Rate": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Stock Parts - Maintenance Rate",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Stock Parts - Maintenance Rate",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Stock maintenance Rate | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data 2022 > VCJ Service > Parts Q1 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Q1 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Q1 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q1 Parts Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Parts Q1 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Parts Q1 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Parts Q1 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Parts Q2 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Q2 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Q2 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q2 Parts Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Parts Q2 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Parts Q2 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Parts Q2 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Parts Q3 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Q3 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Q3 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q3 Parts Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Parts Q3 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Parts Q3 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Parts Q3 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Parts Q4 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts Q4 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts Q4 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q4 Parts Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Parts Q4 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Parts Q4 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Parts Q4 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Parts YTD Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Parts YTD Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Parts YTD Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annual Parts Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Parts YTD Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Parts YTD Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Parts YTD Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Q1 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q1 Accessories Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q1 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > New Q1 Units Actual": {name: "VCJ Process Data 2022 > VCJ Service > New Q1 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "New Q1 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales per Unit": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q1 Sales per Unit",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q1 Sales per Unit",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Q2 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q2 Accessories Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q2 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > New Q2 Units Actual": {name: "VCJ Process Data 2022 > VCJ Service > New Q2 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "New Q2 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales per Unit": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q2 Sales per Unit",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q2 Sales per Unit",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Q3 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q3 Accessories Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q3 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > New Q3 Units Actual": {name: "VCJ Process Data 2022 > VCJ Service > New Q3 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "New Q3 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales per Unit": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q3 Sales per Unit",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q3 Sales per Unit",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories Q4 Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q4 Accessories Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q4 Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > New Q4 Units Actual": {name: "VCJ Process Data 2022 > VCJ Service > New Q4 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "New Q4 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales per Unit": {name: "VCJ Process Data 2022 > VCJ Service > Accessories Q4 Sales per Unit",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories Q4 Sales per Unit",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Accessories YTD Sales Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annual Accessories Sales Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales Actual": {name: "VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories YTD Sales Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > New Q1 Units Actual": {name: "VCJ Process Data 2022 > VCJ Service > New Q1 Units Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "New Q1 Units Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales per Unit": {name: "VCJ Process Data 2022 > VCJ Service > Accessories YTD Sales per Unit",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Accessories YTD Sales per Unit",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Accessories Sales per unit | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q1 Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q1 Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentives Q1 Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q1 Tire Incentive Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives Q1 Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q1 Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives Q1 Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q2 Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q2 Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentives Q2 Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q2 Tire Incentive Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives Q2 Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q2 Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives Q2 Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q3 Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q3 Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentives Q3 Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q3 Tire Incentive Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives Q3 Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q3 Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives Q3 Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q4 Target": {
    name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q4 Target",
    department: "VCJ Process Data 2022",
    category: "VCJ Service",
    label: "Tire Incentives Q4 Target",
    tableNumber: 302,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Q4 Tire Incentive Projected | Parts | Volvo Japan | All Model | | CLOSE",
  },
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives Q4 Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives Q4 Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives Q4 Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives YTD Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives YTD Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives YTD Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Target > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  // "TBD VCJ Process Data 2022 > VCJ Service > Tire Incentives YTD Actual": {name: "VCJ Process Data 2022 > VCJ Service > Tire Incentives YTD Actual",department: "VCJ Process Data 2022",category: "VCJ Service",label: "Tire Incentives YTD Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",},
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Promoters": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Promoters",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 3RM NPS Sales Promoters",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM NPS Promoters | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Detractors": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Detractors",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 3RM NPS Sales Detractors",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM NPS Detractors | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Score": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 3RM NPS Sales Score",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 3RM NPS Sales Score",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM Points Score | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Promoters": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Promoters",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 3RM NPS Service Promoters",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM NPS Promoters | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Detractors": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Detractors",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 3RM NPS Service Detractors",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM NPS Detractors | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Score": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 3RM NPS Service Score",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 3RM NPS Service Score",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 3RM Points Score | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Promoters": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Promoters",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 12RM NPS Sales Promoters",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM NPS Promoters | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Detractors": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Detractors",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 12RM NPS Sales Detractors",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM NPS Detractors | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Score": {
    name: "VCJ Process Data 2022 > VCJ NPS > New Customer Experience CEM 12RM NPS Sales Score",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "New Customer Experience CEM 12RM NPS Sales Score",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM Points Score | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Promoters": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Promoters",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 12RM NPS Service Promoters",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM NPS Promoters | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Detractors": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Detractors",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 12RM NPS Service Detractors",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM NPS Detractors | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Score": {
    name: "VCJ Process Data 2022 > VCJ NPS > Service Customer Satisfaction CEM 12RM NPS Service Score",
    department: "VCJ Process Data 2022",
    category: "VCJ NPS",
    label: "Service Customer Satisfaction CEM 12RM NPS Service Score",
    tableNumber: 303,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CEM 12RM Points Score | Service | Volvo Japan | All Model | | CLOSE",
  },
};

/*

"VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Rate": {name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Showroom Visit Appointment Rate",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Sales Process Showroom Visit Appointment Rate",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Existing Owner (excl. service) rate | New Retail | Volvo Japan | All Model | | CLOSE",},
"VCJ Process Data 2022 > VCJ Sales > New Sales Process Existing Owner Visitors Actual": {name: "VCJ Process Data 2022 > VCJ Sales > New Sales Process Existing Owner Visitors Actual",department: "VCJ Process Data 2022",category: "VCJ Sales",label: "New Sales Process Existing Owner Visitors Actual",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",},
"VCJ Process Data 2022 > VCJ Value Chain > New Units Sold (Excl. Demo)": {name: "VCJ Process Data 2022 > VCJ Value Chain > New Units Sold (Excl. Demo)",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "New Units Sold (Excl. Demo)",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Actual > Pass Unit | New Retail | Volvo Japan | All Model | | SUM",},
"VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Sales Process Retail Order - Total Visitors",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Retail Order Total Visitors | Used | Volvo Japan | All Model | | SUM",},
"VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Existing Owner Visitors": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Existing Owner Visitors",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Sales Process Retail Order - Existing Owner Visitors",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Retail Order Existing Owner Visitors | Used | Volvo Japan | All Model | | SUM",},
"VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New & Stock Visitors": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - New & Stock Visitors",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Sales Process Retail Order - New & Stock Visitors",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Retail Order New & Stock Visitors | Used | Volvo Japan | All Model | | SUM",},
"VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors": {name: "VCJ Process Data 2022 > VCJ Value Chain > Used Sales Process Retail Order - Total Visitors",department: "VCJ Process Data 2022",category: "VCJ Value Chain",label: "Used Sales Process Retail Order - Total Visitors",tableNumber: 300,getMeasure: (label, measures) => getMeasure(label, measures),    measure: "MS > Retail Order Total Visitors | Used | Volvo Japan | All Model | | SUM",},

*/

export const VCJProcessData2: { [index: string]: CustomDashboardType } = {
  "VCJ Process Data > Sales > New Units Target": {
    name: "VCJ Process Data > Sales > New Units Target",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Units Target",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Sales Target > New Retail Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Units Sold (incl. Demo)": {
    name: "VCJ Process Data > Sales > New Units Sold (incl. Demo)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Units Sold (incl. Demo)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit (incl. Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Units Sold (incl. Demo) Achievement %": {
    name: "VCJ Process Data > Sales > New Units Sold (incl. Demo) Achievement %",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Units Sold (incl. Demo) Achievement %",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Sales Target Achievement % (Incl.Demo) | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Demo Units Sold": {
    name: "VCJ Process Data > Sales > Demo Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Demo Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Demo Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Units Sold (excl. Demo)": {
    name: "VCJ Process Data > Sales > New Units Sold (excl. Demo)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Units Sold (excl. Demo)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Units Sold (excl. Demo) Achievement %": {
    name: "VCJ Process Data > Sales > New Units Sold (excl. Demo) Achievement %",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Units Sold (excl. Demo) Achievement %",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Pass Sales Target Achievement % | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > PHEV (Plug in Hybrid EV) New Units Sold": {
    name: "VCJ Process Data > Sales > PHEV (Plug in Hybrid EV) New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "PHEV (Plug in Hybrid EV) New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Plug-in Hybrid EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > PHEV New Units Sold % Total New Units Sold": {
    name: "VCJ Process Data > Sales > PHEV New Units Sold % Total New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "PHEV New Units Sold % Total New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Plug-in Hybrid EV Unit % Total New Units Sold | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > BEV (Battery EV) New Units Sold": {
    name: "VCJ Process Data > Sales > BEV (Battery EV) New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "BEV (Battery EV) New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Battery EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > BEV New Units Sold % Total New Units Sold": {
    name: "VCJ Process Data > Sales > BEV New Units Sold % Total New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "BEV New Units Sold % Total New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Battery EV Unit % Total New Units Sold | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Recharge (Hybrid EV) New Units Sold": {
    name: "VCJ Process Data > Sales > Recharge (Hybrid EV) New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Recharge (Hybrid EV) New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Hybrid EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Hybrid EV New Units Sold % Total New Units Sold": {
    name: "VCJ Process Data > Sales > Hybrid EV New Units Sold % Total New Units Sold",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Hybrid EV New Units Sold % Total New Units Sold",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Hybrid EV Unit % Total New Units Sold | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Prospect Unit": {
    name: "VCJ Process Data > Sales > New Vehicle Prospect Unit",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Prospect Unit",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Prospect Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Conversion Unit": {
    name: "VCJ Process Data > Sales > New Vehicle Conversion Unit",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Conversion Unit",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Conversion Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Closing Ratio": {
    name: "VCJ Process Data > Sales > New Vehicle Closing Ratio",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Closing Ratio",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Closing Ratio | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Test Drive Prospect Unit": {
    name: "VCJ Process Data > Sales > New Vehicle Test Drive Prospect Unit",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Test Drive Prospect Unit",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Test Drive Prospect Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Test Drive Conversion Unit": {
    name: "VCJ Process Data > Sales > New Vehicle Test Drive Conversion Unit",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Test Drive Conversion Unit",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Test Drive Conversion Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Vehicle Test Drive Closing Ratio": {
    name: "VCJ Process Data > Sales > New Vehicle Test Drive Closing Ratio",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Vehicle Test Drive Closing Ratio",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Test Drive Closing Ratio | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Test Drive Visitors  - Total": {
    name: "VCJ Process Data > Sales > Test Drive Visitors  - Total",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Test Drive Visitors  - Total",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Test Drive - Existing Owner Visitors": {
    name: "VCJ Process Data > Sales > Test Drive - Existing Owner Visitors",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Test Drive - Existing Owner Visitors",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Retail Order Visitors - Total": {
    name: "VCJ Process Data > Sales > Retail Order Visitors - Total",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Retail Order Visitors - Total",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Retail Order - Existing Owner Visitors": {
    name: "VCJ Process Data > Sales > Retail Order - Existing Owner Visitors",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Retail Order - Existing Owner Visitors",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order Existing Owner Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Total Retail Order Visitors % of Total Test Drive Visitors": {
    name: "VCJ Process Data > Sales > Total Retail Order Visitors % of Total Test Drive Visitors",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Total Retail Order Visitors % of Total Test Drive Visitors",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Order % Test Drive Total Visitors | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Owner Replacement - Current Owner (Active 24MR)": {
    name: "VCJ Process Data > Sales > Owner Replacement - Current Owner (Active 24MR)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Owner Replacement - Current Owner (Active 24MR)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Owner Current | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Sales > Owner Replacement - Alternative Owner": {
    name: "VCJ Process Data > Sales > Owner Replacement - Alternative Owner",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Owner Replacement - Alternative Owner",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Alternative Owner | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Owner Replacement - Annualised Alternative Owner Rate": {
    name: "VCJ Process Data > Sales > Owner Replacement - Annualised Alternative Owner Rate",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Owner Replacement - Annualised Alternative Owner Rate",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Annualised Alternative Owner Rate | New Retail | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Sales > Penetration Data - Import Car Registration Units": {
    name: "VCJ Process Data > Sales > Penetration Data - Import Car Registration Units",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Penetration Data - Import Car Registration Units",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Import Car Registration Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Penetration Data - In-house Sales Units": {
    name: "VCJ Process Data > Sales > Penetration Data - In-house Sales Units",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Penetration Data - In-house Sales Units",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > In-house Sales Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Penetration Data - Sales Pump-In Units": {
    name: "VCJ Process Data > Sales > Penetration Data - Sales Pump-In Units",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Penetration Data - Sales Pump-In Units",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Pump in Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Penetration Data - Self-Selling only (Market Share Excl. Pump in %)": {
    name: "VCJ Process Data > Sales > Penetration Data - Self-Selling only (Market Share Excl. Pump in %)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Penetration Data - Self-Selling only (Market Share Excl. Pump in %)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Market Share excl Pump In | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Penetration Data - Including Pump-In (Market Share Incl. Pump in %)": {
    name: "VCJ Process Data > Sales > Penetration Data - Including Pump-In (Market Share Incl. Pump in %)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Penetration Data - Including Pump-In (Market Share Incl. Pump in %)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Market Share incl Pump In | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Central Lead - Prospect Lead Retail Sales Unit (Deals Closed)": {
    name: "VCJ Process Data > Sales > Central Lead - Prospect Lead Retail Sales Unit (Deals Closed)",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Central Lead - Prospect Lead Retail Sales Unit (Deals Closed)",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Retail Sales Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Central Lead - No. of Prospect Central Lead": {
    name: "VCJ Process Data > Sales > Central Lead - No. of Prospect Central Lead",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Central Lead - No. of Prospect Central Lead",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > No of Prospect Central Lead | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Central Lead - Prospect Lead Closing Unit": {
    name: "VCJ Process Data > Sales > Central Lead - Prospect Lead Closing Unit",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Central Lead - Prospect Lead Closing Unit",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Closing Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Central Lead - Prospect Lead Retail Sales Unit % Prospect Central Lead": {
    name: "VCJ Process Data > Sales > Central Lead - Prospect Lead Retail Sales Unit % Prospect Central Lead",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Central Lead - Prospect Lead Retail Sales Unit % Prospect Central Lead",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Retail Sales Unit % Prospect Lead | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > Central Lead - Prospect Lead Closing Unit % Prospect Central Lead": {
    name: "VCJ Process Data > Sales > Central Lead - Prospect Lead Closing Unit % Prospect Central Lead",
    department: "VCJ Process Data",
    category: "Sales",
    label: "Central Lead - Prospect Lead Closing Unit % Prospect Central Lead",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Prospect Lead Closing Rate | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Web Traffic no. of Visitors": {
    name: "VCJ Process Data > Sales > New Web Traffic no. of Visitors",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Web Traffic no. of Visitors",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Web Traffic no. of Visitors | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Test Drive Reservations from Web Traffic": {
    name: "VCJ Process Data > Sales > New Test Drive Reservations from Web Traffic",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Test Drive Reservations from Web Traffic",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Test Drive Reservations from Web Traffic | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Sales > New Web Traffic Test Drive Conversion %": {
    name: "VCJ Process Data > Sales > New Web Traffic Test Drive Conversion %",
    department: "VCJ Process Data",
    category: "Sales",
    label: "New Web Traffic Test Drive Conversion %",
    tableNumber: 304,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Web Traffic Test Drive Conversion % | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Units Target": {
    name: "VCJ Process Data > Selekt > Used Units Target",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Units Target",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Sales Target > Used Units | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Units Sold": {
    name: "VCJ Process Data > Selekt > Used Units Sold",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Units Sold",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Units Sold Retail | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Units Sold Achievement %": {
    name: "VCJ Process Data > Selekt > Used Units Sold Achievement %",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Units Sold Achievement %",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > UV Retail Achievement % | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Vehicle Prospect Unit": {
    name: "VCJ Process Data > Selekt > Used Vehicle Prospect Unit",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Vehicle Prospect Unit",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Vehicle Prospect Unit | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Vehicle Conversion Unit": {
    name: "VCJ Process Data > Selekt > Used Vehicle Conversion Unit",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Vehicle Conversion Unit",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Vehicle Conversion Unit | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Use Vehicle Closing Ratio": {
    name: "VCJ Process Data > Selekt > Use Vehicle Closing Ratio",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Use Vehicle Closing Ratio",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Vehicle Closing Ratio | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > PSV Penetration - SELKT Units Sold": {
    name: "VCJ Process Data > Selekt > PSV Penetration - SELKT Units Sold",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "PSV Penetration - SELKT Units Sold",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Units Sold | Used | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > PSV Penetration - No. of  VSP SELEKT / VSP PLUS": {
    name: "VCJ Process Data > Selekt > PSV Penetration - No. of  VSP SELEKT / VSP PLUS",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "PSV Penetration - No. of  VSP SELEKT / VSP PLUS",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Maintenance Package | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > PSV Penetration - VSP SELEKT / VSP PLUS Penetration": {
    name: "VCJ Process Data > Selekt > PSV Penetration - VSP SELEKT / VSP PLUS Penetration",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "PSV Penetration - VSP SELEKT / VSP PLUS Penetration",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Certified Maintenance Package % Certified Units Sold | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Car Loan Contracts": {
    name: "VCJ Process Data > Selekt > Used Car Loan Contracts",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Car Loan Contracts",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loan Contracts - Used | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Used Car Loan Penetration": {
    name: "VCJ Process Data > Selekt > Used Car Loan Penetration",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Used Car Loan Penetration",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Used Car Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > New Units Sold (excl Demo)": {
    name: "VCJ Process Data > Selekt > New Units Sold (excl Demo)",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "New Units Sold (excl Demo)",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Pass Unit | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > New Car Loan Contracts": {
    name: "VCJ Process Data > Selekt > New Car Loan Contracts",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "New Car Loan Contracts",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loan Contracts - New | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > New Car Loan Penetration": {
    name: "VCJ Process Data > Selekt > New Car Loan Penetration",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "New Car Loan Penetration",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Car Loan Penetration | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Composition New car within 3 year Order": {
    name: "VCJ Process Data > Selekt > Composition New car within 3 year Order",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Composition New car within 3 year Order",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Composition New car within 3 year Order | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Composition New car within 3 year Order % New Loan Contracts": {
    name: "VCJ Process Data > Selekt > Composition New car within 3 year Order % New Loan Contracts",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Composition New car within 3 year Order % New Loan Contracts",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Composition New Car within 3 years % | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Total Registration Units": {
    name: "VCJ Process Data > Selekt > Total Registration Units",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Total Registration Units",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Registration Units | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > New Insurance Contracts": {
    name: "VCJ Process Data > Selekt > New Insurance Contracts",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "New Insurance Contracts",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > No of New Contracts | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > New Insurance Penetration (based on Car Registration)": {
    name: "VCJ Process Data > Selekt > New Insurance Penetration (based on Car Registration)",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "New Insurance Penetration (based on Car Registration)",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance penetration % (based on registration units) | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Insurance Contracts Expiry": {
    name: "VCJ Process Data > Selekt > Insurance Contracts Expiry",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Insurance Contracts Expiry",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Expiry Number | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Insurance Contracts Renewal": {
    name: "VCJ Process Data > Selekt > Insurance Contracts Renewal",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Insurance Contracts Renewal",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Renewal Number | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Selekt > Insurance Contracts Renewal Rate": {
    name: "VCJ Process Data > Selekt > Insurance Contracts Renewal Rate",
    department: "VCJ Process Data",
    category: "Selekt",
    label: "Insurance Contracts Renewal Rate",
    tableNumber: 305,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Insurance Renewal Rate | F&I Dept | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > 24 MR Active Customers": {
    name: "VCJ Process Data > Service & Accessories > 24 MR Active Customers",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "24 MR Active Customers",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Service & Accessories > Car Park Penetration (24MR) DMA": {
    name: "VCJ Process Data > Service & Accessories > Car Park Penetration (24MR) DMA",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Car Park Penetration (24MR) DMA",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Car Park Penetration 24 MR DMA | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Service & Accessories > 24MR Total Active Customer vs Car Park Penetration": {
    name: "VCJ Process Data > Service & Accessories > 24MR Total Active Customer vs Car Park Penetration",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "24MR Total Active Customer vs Car Park Penetration",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR Total Active Customer vs Car Park Penetration | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Service & Accessories > 24MR No of 10 Years Active Customer": {
    name: "VCJ Process Data > Service & Accessories > 24MR No of 10 Years Active Customer",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "24MR No of 10 Years Active Customer",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > 24MR No of 10 Years Active Customer | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Service & Accessories > Parts Sales Target": {
    name: "VCJ Process Data > Service & Accessories > Parts Sales Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Parts Sales Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Parts Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Parts Sales Actual": {
    name: "VCJ Process Data > Service & Accessories > Parts Sales Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Parts Sales Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Parts Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Parts Sales Achievement %": {
    name: "VCJ Process Data > Service & Accessories > Parts Sales Achievement %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Parts Sales Achievement %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Parts Sale per Active Customer": {
    name: "VCJ Process Data > Service & Accessories > Parts Sale per Active Customer",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Parts Sale per Active Customer",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Sale per Active Customer | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Parts  Active customers": {
    name: "VCJ Process Data > Service & Accessories > Parts  Active customers",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Parts  Active customers",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Parts Active Customers | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Accsessory Sales Target": {
    name: "VCJ Process Data > Service & Accessories > Accsessory Sales Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Accsessory Sales Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Accsessory Sales Actual": {
    name: "VCJ Process Data > Service & Accessories > Accsessory Sales Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Accsessory Sales Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Accessories Sales | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Accsessory Sales Achievement % ": {
    name: "VCJ Process Data > Service & Accessories > Accsessory Sales Achievement % ",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Accsessory Sales Achievement % ",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessories Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Tire Incentive Target": {
    name: "VCJ Process Data > Service & Accessories > Tire Incentive Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Tire Incentive Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Tire Incentive Actual": {
    name: "VCJ Process Data > Service & Accessories > Tire Incentive Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Tire Incentive Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Tire Incentive | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Tire Incentive Achievement %": {
    name: "VCJ Process Data > Service & Accessories > Tire Incentive Achievement %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Tire Incentive Achievement %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Tire Incentive Target Achievement % | Parts | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Inspection Target": {
    name: "VCJ Process Data > Service & Accessories > VSP Inspection Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Inspection Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Inspection Actual": {
    name: "VCJ Process Data > Service & Accessories > VSP Inspection Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Inspection Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Inspection Retention %": {
    name: "VCJ Process Data > Service & Accessories > VSP Inspection Retention %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Inspection Retention %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Shaken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non-VSP Shaken Target Unit": {
    name: "VCJ Process Data > Service & Accessories > Non-VSP Shaken Target Unit",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non-VSP Shaken Target Unit",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Shaken Target Unit | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non-VSP Shaken Retention Unit": {
    name: "VCJ Process Data > Service & Accessories > Non-VSP Shaken Retention Unit",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non-VSP Shaken Retention Unit",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Shaken Retention Unit | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non VSP Inspection Retention %": {
    name: "VCJ Process Data > Service & Accessories > Non VSP Inspection Retention %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non VSP Inspection Retention %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Shaken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Target": {
    name: "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Periodic Inspection (Excl. 6Mth Inspection) Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Actual": {
    name: "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Periodic Inspection (Excl. 6Mth Inspection) Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Retention %": {
    name: "VCJ Process Data > Service & Accessories > VSP Periodic Inspection (Excl. 6Mth Inspection) Retention %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Periodic Inspection (Excl. 6Mth Inspection) Retention %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Tenken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non-VSP Tenken Target Unit": {
    name: "VCJ Process Data > Service & Accessories > Non-VSP Tenken Target Unit",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non-VSP Tenken Target Unit",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Tenken Target Unit | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non-VSP Tenken Retention Unit": {
    name: "VCJ Process Data > Service & Accessories > Non-VSP Tenken Retention Unit",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non-VSP Tenken Retention Unit",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Tenken Retention Unit | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Non-VSP Tenken Retention %": {
    name: "VCJ Process Data > Service & Accessories > Non-VSP Tenken Retention %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Non-VSP Tenken Retention %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Non-VSP Tenken Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention (30Mth) Target": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention (30Mth) Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention (30Mth) Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention (30Mth) Actual": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention (30Mth) Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention (30Mth) Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention (30Mth)  %": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention (30Mth)  %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention (30Mth)  %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 30Mth Retention % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention 5-Year Target Units": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention 5-Year Target Units",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention 5-Year Target Units",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 5-Year Target Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention 5-Year Actual Units": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention 5-Year Actual Units",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention 5-Year Actual Units",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 5-Year Actual Units | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP Retention 5-Year %": {
    name: "VCJ Process Data > Service & Accessories > VSP Retention 5-Year %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP Retention 5-Year %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP Retention 5-Year % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Target": {
    name: "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP PLUS 10-Year Car Park Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 10-Year Car Park Target | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Actual": {
    name: "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP PLUS 10-Year Car Park Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 10-Year Car Park Actual | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Coverage": {
    name: "VCJ Process Data > Service & Accessories > VSP PLUS 10-Year Car Park Coverage",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "VSP PLUS 10-Year Car Park Coverage",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > VSP PLUS 10-Year Car Park Coverage | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit General Target": {
    name: "VCJ Process Data > Service & Accessories > Service Visit General Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit General Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Service Visit General Target | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit General Actual": {
    name: "VCJ Process Data > Service & Accessories > Service Visit General Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit General Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit General | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit General & Maintenance Achievement %": {
    name: "VCJ Process Data > Service & Accessories > Service Visit General & Maintenance Achievement %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit General & Maintenance Achievement %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit General & Maintenance Achievement % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint Target": {
    name: "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint Target",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit BP Body Paint Target",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Target > Service Visit Body Paint Target | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint Actual": {
    name: "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint Actual",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit BP Body Paint Actual",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit BP | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint  Achievement %": {
    name: "VCJ Process Data > Service & Accessories > Service Visit BP Body Paint  Achievement %",
    department: "VCJ Process Data",
    category: "Service & Accessories",
    label: "Service Visit BP Body Paint  Achievement %",
    tableNumber: 306,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Visit Body Paint Achievement % | Service | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > CS & Efficiency > OSAT Sales Point (Sales CSI Score)": {
    name: "VCJ Process Data > CS & Efficiency > OSAT Sales Point (Sales CSI Score)",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "OSAT Sales Point (Sales CSI Score)",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CSI Score New | New | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > CS & Efficiency > OSAT Service Points (Service CSI Score)": {
    name: "VCJ Process Data > CS & Efficiency > OSAT Service Points (Service CSI Score)",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "OSAT Service Points (Service CSI Score)",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > CSI Score Service | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > CS & Efficiency > VCJ Data - Service Efficiency": {
    name: "VCJ Process Data > CS & Efficiency > VCJ Data - Service Efficiency",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "VCJ Data - Service Efficiency",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data Service Efficiency | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > CS & Efficiency > VCJ Data - Service Productivity": {
    name: "VCJ Process Data > CS & Efficiency > VCJ Data - Service Productivity",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "VCJ Data - Service Productivity",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data Service Productivity | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > CS & Efficiency > VCJ Data - Service Optimization": {
    name: "VCJ Process Data > CS & Efficiency > VCJ Data - Service Optimization",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "VCJ Data - Service Optimization",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data Service Optimization | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > CS & Efficiency > Value Chain Coverage": {
    name: "VCJ Process Data > CS & Efficiency > Value Chain Coverage",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "Value Chain Coverage",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CUST > VCJ Value Chain Coverage | All Department | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > CS & Efficiency > Value Chain Coverage After Sales": {
    name: "VCJ Process Data > CS & Efficiency > Value Chain Coverage After Sales",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "Value Chain Coverage After Sales",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CUST > VCJ Value Chain Coverage After Sales | All Department | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > CS & Efficiency > Value Chain Coverage Used Car": {
    name: "VCJ Process Data > CS & Efficiency > Value Chain Coverage Used Car",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "Value Chain Coverage Used Car",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CUST > VCJ Value Chain Coverage UV | All Department | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > CS & Efficiency > ROS": {
    name: "VCJ Process Data > CS & Efficiency > ROS",
    department: "VCJ Process Data",
    category: "CS & Efficiency",
    label: "ROS",
    tableNumber: 307,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > Battery EV Units Sold": {
    name: "VCJ Process Data > Additional KPIs > Battery EV Units Sold",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Battery EV Units Sold",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Actual > Battery EV Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > New Units Target": {
    name: "VCJ Process Data > Additional KPIs > New Units Target",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "New Units Target",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Sales Target > New Retail Units | New Retail | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > Battery EV Units Achievement %": {
    name: "VCJ Process Data > Additional KPIs > Battery EV Units Achievement %",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Battery EV Units Achievement %",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Battery EV Unit Achievement % | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > EV In-house Sales Unit VOLVO": {
    name: "VCJ Process Data > Additional KPIs > EV In-house Sales Unit VOLVO",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "EV In-house Sales Unit VOLVO",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV In-house Sales Unit | New | Volvo Japan | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > EV In-house Sales Unit ALL Brand": {
    name: "VCJ Process Data > Additional KPIs > EV In-house Sales Unit ALL Brand",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "EV In-house Sales Unit ALL Brand",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV In-house Sales Unit | New | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > EV Sales Pump in Unit ALL Brand": {
    name: "VCJ Process Data > Additional KPIs > EV Sales Pump in Unit ALL Brand",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "EV Sales Pump in Unit ALL Brand",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Sales Pump in Unit | New | All Brand | All Model | | SUM",
  },
  "VCJ Process Data > Additional KPIs > New Salesperson": {
    name: "VCJ Process Data > Additional KPIs > New Salesperson",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "New Salesperson",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Staff | New | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Used Salesperson": {
    name: "VCJ Process Data > Additional KPIs > Used Salesperson",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Used Salesperson",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Staff | Used | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Total Technician": {
    name: "VCJ Process Data > Additional KPIs > Total Technician",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Total Technician",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Technicians (incl Master Technicians) | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > EV Online Adviser Score": {
    name: "VCJ Process Data > Additional KPIs > EV Online Adviser Score",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "EV Online Adviser Score",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Online Adviser Score | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Master Technician Lv3+Lv4": {
    name: "VCJ Process Data > Additional KPIs > Master Technician Lv3+Lv4",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Master Technician Lv3+Lv4",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Master Technician | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > EVT AEVT (EV Certified Tech.) Score": {
    name: "VCJ Process Data > Additional KPIs > EVT AEVT (EV Certified Tech.) Score",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "EVT AEVT (EV Certified Tech.) Score",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > EV Certified Technician Score | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Labour Sales / Tech / Month": {
    name: "VCJ Process Data > Additional KPIs > Labour Sales / Tech / Month",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Labour Sales / Tech / Month",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data Labour Sales / Technician / Month | Service | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Utilization %": {
    name: "VCJ Process Data > Additional KPIs > Utilization %",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Utilization %",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Utilization % | Admin | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Mystery Shopper Sales": {
    name: "VCJ Process Data > Additional KPIs > Mystery Shopper Sales",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Mystery Shopper Sales",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Mystery Shopper | New | Volvo Japan | All Model | | CLOSE",
  },
  "VCJ Process Data > Additional KPIs > Mystery Shopper Service": {
    name: "VCJ Process Data > Additional KPIs > Mystery Shopper Service",
    department: "VCJ Process Data",
    category: "Additional KPIs",
    label: "Mystery Shopper Service",
    tableNumber: 308,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Mystery Shopper | Service | Volvo Japan | All Model | | CLOSE",
  },
};

export const VCJProcessData: { [index: string]: CustomDashboardType } = {
  ...VCJProcessData1,
  ...VCJProcessData2,
};
