import React, { createContext, useReducer, useMemo } from "react";
import { GroupSeries, Report, Query, stringifyQuery, customGroupBarFor } from "../../lib/api/charts";
import { Loading } from "../base";
import { CustomDashboardMeasures } from "../../charts/CustomDashReports";

type GroupSeriesSet = { series: GroupSeries; loading: Loading; custom: any };

export type CustomGroupBarChartContextState = {
  series: {
    [index: string]: GroupSeriesSet;
  };
  getGroupSeries(report: Report, query: Query, custom: any): Partial<GroupSeriesSet>;
};

export const CustomGroupBarChartContext = createContext<CustomGroupBarChartContextState>({
  series: {},
  getGroupSeries: () => ({}),
});

type QueryAction = {
  type: "PATCH_SERIES";
  id: string;
  data: Partial<GroupSeriesSet>;
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultGroupSeries: GroupSeriesSet = {
  series: { name: "", rows: [], classification: "income", type: "currency", dealers: [], formats: { pos: "", neg: "" } },
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

export const CustomGroupBarChartProvider = ({ children }) => {
  const memo = (report: Report, query: Query, custom: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [series, dispatchGroupSeries] = useReducer((state: { [index: string]: GroupSeriesSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      series,
      getGroupSeries: (report: Report, query: Query, custom: any) => {
        const id = memo(report, query, custom);
        const set = series[id];

        if (set) {
          return set;
        }

        dispatchGroupSeries({
          type: "PATCH_SERIES",
          id,
          data: defaultGroupSeries,
        });

        customGroupBarFor(query, report, custom)
          .then(series => {
            dispatchGroupSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                series: series,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchGroupSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultGroupSeries;
      },
    }),
    [series],
  );

  return <CustomGroupBarChartContext.Provider value={value}>{children}</CustomGroupBarChartContext.Provider>;
};
