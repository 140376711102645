import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React, { useContext, useEffect, useMemo } from "react";
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, Text, XAxis, YAxis, Legend } from "recharts";
import { Loading } from "../components/Loading";
import { Query, Report, Series, GroupSeries } from "../lib/api/charts";
import { ChartMenu } from "./ChartMenu";
import { OemContext } from "../contexts/OemContext";
import numeral from "numeral";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { GroupChartContext } from "../contexts/GroupChartContext";
import * as d3Scale from "d3-scale-chromatic";
import { ReportSelector } from "./GroupReportSelector";
import { useIntl } from "react-intl";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Grid, Tooltip as MaterialUITooltip } from "@material-ui/core";
import History from "@material-ui/icons/History";
import { useMediaQuery } from "react-responsive";
import { ChartDialog } from "../lib/shared/ChartDialog";
import { DealerContext } from "../contexts/DealerContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    chartPaperRoot: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing.unit * 2}px`,
      flex: "1",
      backgroundColor: "#FFF",
      borderRadius: "5px",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      border: "1px #DADADA solid",
      ["@media (max-width:450px)"]: {
        padding: "0px",
      },
    },
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chart: {
      flex: "1",
    },
    menu: {
      // margin: "-0.85rem"
      display: "flex",
    },
    generalNews: {
      background: "#800080",
    },
    dataQualityNews: {
      background: "#ADD8E6",
    },
    dealerPerformanceNews: {
      background: "#003366",
    },
  });

const stylesAlt = (theme: Theme) =>
  createStyles({
    root: {
      flex: "1",
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    chartPaperRoot: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing.unit * 2}px`,
      flex: "1",
      border: "solid 3px #f3f3f3",
      borderRadius: "5px",
      backgroundColor: "#f8f8f8",
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      ["@media (max-width:450px)"]: {
        padding: "0px",
      },
    },
    headingContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    chart: {
      flex: "1",
    },
    menu: {
      // margin: "-0.85rem"
      display: "flex",
    },
    generalNews: {
      background: "#800080",
    },
    dataQualityNews: {
      background: "#ADD8E6",
    },
    dealerPerformanceNews: {
      background: "#003366",
    },
  });

export type ChartProps = {
  barColor?: string;
  lineColor?: string;
  query: Query;
  title?: string;
  report: Report;
  theme: Theme;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  measureAgainst?: string;
  forcedHeight?: string;
  renderMenu?: () => React.ReactElement<any>;
  chartOptions?: any;
  measure?: any;
  period?: string;
  openDialog?: boolean;
  setOpenDialog?: (value: boolean) => void;
  isDesktopOrLaptop?: boolean;
} & WithStyles<typeof styles>;

const BarTooltip = ({ active, payload, query, dealers }: any) => {
  if (active && payload && payload[0]) {
    return (
      <Paper style={{ padding: "0.5rem" }}>
        <Typography variant="caption" component="span">
          {payload[0].payload.month}
        </Typography>
        {(dealers || []).map(dealer => (
          <Typography variant="caption" key={dealer} component="span">
            <b>{dealer}:</b> {payload[0].payload[`${dealer.match(/\w+(?=\)$)/)[0]} actualFormatted`]}
          </Typography>
        ))}
        {payload[0].payload.benchmarkFormatted && (
          <Typography variant="caption" component="span">
            <b>Benchmark:</b> {payload[0].payload.benchmarkFormatted}
          </Typography>
        )}
        {payload[0].payload.medianFormatted && (
          <Typography variant="caption" component="span">
            <b>Median:</b> {payload[0].payload.medianFormatted}
          </Typography>
        )}
      </Paper>
    );
  }
  return null;
};

const renderLegend = (props: any) => {
  const { payload } = props;
  // These styles have been obtained from inspecting the default rechart rendering.
  return (
    <ul className="recharts-default-legend" style={{ padding: "0px", margin: "0px", textAlign: "center" }}>
      {(payload || []).map((entry: any, index: any) => {
        if (entry.value == "Actual") return null; // We never want to display the legend for Actual, this was requested by the Customer.

        if (entry.dataKey == "benchmark" || entry.dataKey == "median") {
          return (
            <li
              key={`recharts-legend-item legend-item-${index}`}
              className={`recharts-legend-item legend-item-${index}`}
              style={{ display: "inline-block", marginRight: "10px" }}
            >
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
              >
                {entry.type == "line" ? (
                  <path strokeWidth="6" fill="none" stroke={entry.color} d="M 10 16 L 30 16" className="recharts-legend-icon" strokeDasharray="3 3" />
                ) : (
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
                )}
              </svg>
              <span className={"recharts-legend-item-text"}>{entry.value}</span>
            </li>
          );
        }

        return (
          <MaterialUITooltip title={entry.value} key={`recharts-legend-item legend-item-${index}`}>
            <li className={`recharts-legend-item legend-item-${index}`} style={{ display: "inline-block", marginRight: "10px" }}>
              <svg
                className="recharts-surface"
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: "4px" }}
              >
                {entry.type == "line" ? (
                  <path
                    strokeWidth="4"
                    fill="none"
                    stroke={entry.color}
                    d="M0,16h10.666666666666666
    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
    H32M21.333333333333332,16
    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                    className="recharts-legend-icon"
                  />
                ) : (
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
                )}
              </svg>
              <span className={"recharts-legend-item-text"}>{entry.value.match(/\w+(?=\)$)/)[0]}</span>
            </li>
          </MaterialUITooltip>
        );
      })}
    </ul>
  );
};

export type ChartInnerProps = {
  theme: Theme;
  series: GroupSeries;
  report: Report;
  title: string;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  measureAgainst?: string;
  menu: React.ReactElement<any>;
  forcedHeight: string;
  query: Query;
  selectedDealers: any[];
  chartOptions?: any;
} & WithStyles<typeof styles>;

const CustomizedXAxisTick = ({ x, y, payload }) => {
  // YEAR
  if (/FYTD|CYTD/.test(payload.value)) {
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // YEAR
  if (payload.value.length == 4) {
    return <Text fill="#636463" x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // Percentage
  if (typeof payload.value == "number") {
    const pc = (payload.value * 100).toFixed(0) + "%";
    return <Text fill="#636463" x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">{`${pc}`}</Text>;
  }

  // Quarters
  if (payload.value.includes("Q")) {
    const [year, quarter] = payload.value.split(" ");
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${year} ${quarter}`}</Text>;
  }

  // Month Year
  return (
    <Text fill="#636463" x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

const actualColour = (series: Series, value) => {
  if (series.classification == "income") {
    if (value.actual < value.median) {
      return red[500];
    }

    if (value.actual <= value.benchmark && value.actual >= value.median) {
      return orange[500];
    }

    return grey[500];
  }

  if (value.actual > value.median) {
    return red[500];
  }

  if (value.actual >= value.benchmark && value.actual <= value.median) {
    return orange[500];
  }

  return grey[500];
};

// Setting the width to the responsive container to 99% as per this issue that is found with recharts
// https://github.com/recharts/recharts/issues/172
const ChartInner: React.FunctionComponent<ChartInnerProps> = ({
  theme,
  series,
  classes,
  report,
  handleSelection,
  measureAgainst,
  showBenchmark,
  showMedian,
  showTarget,
  menu,
  forcedHeight,
  title,
  query,
  selectedDealers,
  chartOptions,
}) => {
  const intl = useIntl();
  const { oems } = useContext(OemContext);
  const { selected: benchmarks, loading } = useContext(BenchmarkContext);

  const seriesRows = series.rows.reduce((arr, row) => {
    if (["yearly", "quarter", "fullyear"].includes(query.meta && query.meta.variant)) {
      const [start, end] = row.month.split("-");
      const [endMonth, endYear] = [intl.formatMessage({ id: `month.${end.split(" ")[0].toLowerCase()}.sub` }), end.split(" ")[1]];
      const startDate = start.includes(" ") ? start.split(" ") : start;
      const starMonth = start.includes(" ")
        ? intl.formatMessage({ id: `month.${startDate[0].toLowerCase()}.sub` })
        : intl.formatMessage({ id: `month.${start.toLowerCase()}.sub` });
      const startYear = start.includes(" ") ? startDate[1] : endYear;

      arr.push({
        ...row,
        month: `${starMonth}${startYear == endYear ? "" : ` ${startYear}`}-${endMonth} ${endYear}`,
      });
      return arr;
    }
    const [month, year] = row.month.split(" ");
    const formattedMonth = ["yearly", "quarter", "fullyear"].includes(query.meta && query.meta.variant)
      ? month
      : intl.formatMessage({ id: `month.${month.toLowerCase()}.sub` });
    arr.push({
      ...row,
      month: !!year ? `${formattedMonth} ${year}` : `${formattedMonth}`,
    });
    return arr;
  }, []);

  const benchmarkLine = "#20639B";
  const medianLine = "#3cAEA3";
  const targetsLine = "#F6D55C";

  if (!loading.loaded) {
    return <Loading />;
  }

  const dealers = ((selectedDealers || []).filter(dealer => dealer.active) || []).map(dealer => dealer.name);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1280px)",
  });

  return (
    <Paper className={classnames(classes.chartPaperRoot)} style={{ height: forcedHeight }}>
      <div className={classnames(classes.headingContainer)}>
        <ReportSelector handleSelection={handleSelection} report={report} title={series.name} chartOptions={chartOptions} />
        <div className={classnames(classes.menu)}>{menu || null}</div>
      </div>
      <div className={classes.chart}>
        <ResponsiveContainer width={"99%"} minHeight={300} className={classes.root}>
          <ComposedChart data={series.rows} margin={{ bottom: 30, top: 10, left: 30 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="month" type="category" interval={0} axisLine={false} tickLine={false} tick={CustomizedXAxisTick} />

            <YAxis
              width={40}
              axisLine={false}
              tickLine={false}
              tick={({ x, y, payload }) => {
                const value = numeral(payload.value);
                return (
                  <Text fill="#636463" x={x} y={y} textAnchor="end" verticalAnchor="middle">
                    {value.value() > 0
                      ? value.format(value.value() < 1000 ? series.formats.pos : "0.0a")
                      : value.format(value.value() > -1000 ? series.formats.neg : "0.0a")}
                  </Text>
                );
              }}
            />

            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              content={<BarTooltip dealers={dealers} query={query} />}
              position={isDesktopOrLaptop ? { x: 500, y: 0 } : { x: 0, y: 0 }}
            />

            {(dealers || []).map((dealer, i) => (
              <Line
                name={dealer}
                stroke={d3Scale.schemeDark2[i]}
                fill={d3Scale.schemeDark2[i]}
                strokeWidth={3}
                dataKey={`${dealer.match(/\w+(?=\)$)/)[0]} actual`}
                dot={false}
                strokeLinejoin="round"
              />
            ))}
            {showBenchmark && (
              <Line
                name={benchmarks[0]}
                stroke={benchmarkLine}
                fill={benchmarkLine}
                strokeWidth={3}
                dataKey="benchmark"
                dot={false}
                strokeDasharray="3 3"
                strokeLinejoin="round"
              />
            )}
            {showMedian && (
              <Line
                name={benchmarks[1]}
                stroke={medianLine}
                fill={medianLine}
                strokeWidth={3}
                dataKey="median"
                dot={false}
                strokeDasharray="3 3"
                strokeLinejoin="round"
              />
            )}
            <Legend content={renderLegend} verticalAlign="top" height={36} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

type ReportMenuProps = {
  query: Query;
  handleShowToggle: (attr: string) => (e: React.SyntheticEvent<any>) => void;
  state: {
    showBenchmark: boolean;
    showMedian: boolean;
    showTarget: boolean;
    forcedHeight: string;
  };
  hide?: boolean;
  selectedDealers?: any[];
  setSelectedDealers?: any;
};

const ReportMenu: React.FunctionComponent<ReportMenuProps> = ({ query, handleShowToggle, state, hide, selectedDealers, setSelectedDealers }) => {
  const toggleBenchmark = handleShowToggle("showBenchmark");
  const toggleMedian = handleShowToggle("showMedian");
  const toggleTarget = handleShowToggle("showTarget");
  const { oems } = useContext(OemContext);
  const { selected: benchmarks } = useContext(BenchmarkContext);

  const handleSelectedDealers = (value: string) => {
    const filtered = (selectedDealers || []).filter(dealer => dealer.name != value);
    const dealer = (selectedDealers || []).find(d => d.name == value);
    setSelectedDealers([...filtered, { ...dealer, active: !dealer.active }]);
  };

  const compare = (a, b) => {
    if (a.pos > b.pos) {
      return 1;
    }
    if (a.pos < b.pos) {
      return -1;
    }
    return 0;
  };

  const sortedDealers = React.useMemo(() => {
    return selectedDealers.sort(compare);
  }, [selectedDealers]);

  return (
    <div hidden={hide}>
      <ChartMenu>
        <Grid container justify="flex-end" style={{ padding: "0.5em 1em" }}>
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                setSelectedDealers(
                  selectedDealers.map(d => {
                    return { ...d, active: true };
                  }),
                );
              }}
            >
              Select All
            </Button>
            &nbsp;
            <Button
              size="small"
              onClick={() => {
                setSelectedDealers(
                  selectedDealers.map(d => {
                    return { ...d, active: false };
                  }),
                );
              }}
            >
              Deselect All
            </Button>
          </Grid>
        </Grid>
        {benchmarks.filter(bm => bm.length).length > 0 && (
          <>
            <MenuItem onClick={toggleBenchmark}>
              <Checkbox onChange={toggleBenchmark} checked={state.showBenchmark} disableRipple />
              {benchmarks[0]}
            </MenuItem>
            {benchmarks.length > 1 && (
              <MenuItem onClick={toggleMedian}>
                <Checkbox onChange={toggleMedian} checked={state.showMedian} disableRipple />
                {benchmarks[1]}
              </MenuItem>
            )}
          </>
        )}
        {(sortedDealers || []).map(dealer => (
          <MenuItem onClick={() => handleSelectedDealers(dealer.name)} disabled={dealer.active && sortedDealers.filter(d => d.active).length <= 1}>
            <Checkbox checked={dealer.active} disableRipple />
            {dealer.name}
          </MenuItem>
        ))}
      </ChartMenu>
    </div>
  );
};
const ChartUnstyled: React.FunctionComponent<ChartProps> = ({
  classes,
  theme,
  report,
  title,
  query,
  showBenchmark,
  handleSelection,
  showMedian,
  showTarget,
  measureAgainst,
  renderMenu,
  forcedHeight,
  chartOptions,
  measure,
  openDialog,
  setOpenDialog,
  isDesktopOrLaptop,
}) => {
  const { getGroupSeries } = React.useContext(GroupChartContext);
  const series = getGroupSeries(report, query, measure);
  const { selectedDealers: dealerList } = useContext(DealerContext);
  const [selectedDealers, setSelectedDealers] = React.useState<any[]>(dealerList.map((dealer, indx) => ({ name: dealer.dealer, active: true, pos: indx })));

  useEffect(() => setSelectedDealers(dealerList.map((dealer, indx) => ({ name: dealer.dealer, active: true, pos: indx }))), [dealerList]);

  const [state, setState] = React.useState({
    showMedian: showMedian || false,
    showTarget: showTarget || false,
    showBenchmark: showBenchmark || false,
    forcedHeight: forcedHeight,
  });

  const handleShowToggle = attr => event => {
    setState({
      ...state,
      [attr]: !state[attr],
    });
  };

  if (!series.loading.loaded) {
    return <Loading />;
  }

  return (
    <ChartInner
      measureAgainst={measureAgainst}
      showBenchmark={state.showBenchmark}
      showMedian={state.showMedian}
      showTarget={state.showTarget}
      handleSelection={handleSelection}
      report={report}
      theme={theme}
      title={title || series.series.name}
      selectedDealers={selectedDealers}
      series={series.series}
      classes={classes}
      menu={
        <>
          {isDesktopOrLaptop && !openDialog && (
            <Button onClick={() => setOpenDialog(openDialog ? false : true)}>{openDialog ? <ZoomOut /> : <ZoomIn />}</Button>
          )}
          {renderMenu && renderMenu()}
          <ReportMenu
            state={state}
            handleShowToggle={handleShowToggle}
            query={query}
            selectedDealers={selectedDealers}
            setSelectedDealers={setSelectedDealers}
          />
        </>
      }
      forcedHeight={state.forcedHeight}
      query={query}
      chartOptions={chartOptions}
    />
  );
};

export const GroupSeriesChart = withTheme()(withStyles(styles)(ChartUnstyled));
export const GroupSeriesChartAlt = withTheme()(withStyles(stylesAlt)(ChartUnstyled));

export type SelectableChartProps = {
  query: Query;
  title?: string;
  report: Report;
  handleSelection?(key: Report): void;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  chartOptions?: any;
  period?: string;
};

export const SelectableChart: React.FC<SelectableChartProps> = ({
  handleSelection,
  report,
  title,
  query,
  showMedian,
  showTarget,
  showBenchmark,
  chartOptions,
  period = "Monthly",
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedChart, setSelectedChart] = React.useState(period);
  const [menuAnchor, setMenuAnchor] = React.useState<any>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });

  const variantMap = {
    Monthly: "monthly",
    Quarterly: "quarter",
    Yearly: "yearly",
    "Full Year": "fullyear",
    YTD: "ytd",
  };

  return (
    <>
      <GroupSeriesChart
        handleSelection={handleSelection}
        report={report}
        title={title}
        query={{ ...query, meta: variantMap[selectedChart] ? { variant: variantMap[selectedChart] } : {} }}
        showMedian={showMedian}
        showTarget={showTarget}
        showBenchmark={showBenchmark}
        chartOptions={chartOptions}
        setOpenDialog={setOpenDialog}
        isDesktopOrLaptop={isDesktopOrLaptop}
        renderMenu={() => (
          <Button
            onClick={e => {
              setMenuOpen(true);
              setMenuAnchor(e.currentTarget);
            }}
          >
            <History /> ({selectedChart})
          </Button>
        )}
      />
      {
        <Menu anchorEl={menuAnchor} open={menuOpen} onClose={() => setMenuOpen(false)}>
          <MenuItem
            selected={selectedChart == "YTD"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("YTD");
            }}
          >
            YTD
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Monthly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Monthly");
            }}
          >
            Monthly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Quarterly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Quarterly");
            }}
          >
            Quarterly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Yearly"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Yearly");
            }}
          >
            Yearly
          </MenuItem>
          <MenuItem
            selected={selectedChart == "Full Year"}
            onClick={() => {
              setMenuOpen(false);
              setSelectedChart("Full Year");
            }}
          >
            Full Year
          </MenuItem>
        </Menu>
      }
      {isDesktopOrLaptop && (
        <ChartDialog
          handleSelection={handleSelection}
          report={report}
          title={title}
          query={{ ...query, meta: variantMap[selectedChart] ? { variant: variantMap[selectedChart] } : {} }}
          showMedian={showMedian}
          showTarget={showTarget}
          showBenchmark={showBenchmark}
          chartOptions={chartOptions}
          setMenuOpen={setMenuOpen}
          setMenuAnchor={setMenuAnchor}
          selectedChart={selectedChart}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          isDesktopOrLaptop={isDesktopOrLaptop}
          chart={props => {
            return (
              <GroupSeriesChart
                handleSelection={handleSelection}
                report={report}
                title={title}
                query={{ ...query, meta: variantMap[selectedChart] ? { variant: variantMap[selectedChart] } : {} }}
                showMedian={showMedian}
                showTarget={showTarget}
                showBenchmark={showBenchmark}
                chartOptions={chartOptions}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                isDesktopOrLaptop={isDesktopOrLaptop}
                renderMenu={() => (
                  <Button
                    onClick={e => {
                      setMenuOpen(true);
                      setMenuAnchor(e.currentTarget);
                    }}
                  >
                    <History /> ({selectedChart})
                  </Button>
                )}
              />
            );
          }}
        />
      )}
    </>
  );
};
