import Divider from "@material-ui/core/Divider";
import MuiDashboardDrawer from "@material-ui/core/Drawer";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useContext, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { history } from "../lib/history";
import { drawerWidth, toolbar } from "./variables";
import { locations } from "./Layout";
import { adminLocations } from "./Layout";
import { TabAccessContext } from "../contexts/TabAccessContext";
import Insights from "../pages/Insights.png";
import { UserContext } from "../contexts/UserContext";
import msx from "./msx.png";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Right from "@material-ui/icons/ChevronRight";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useViewport } from "../hooks/useViewport";
import { PermissionsContext } from "../contexts/PermissionsContext";
import { SelectAdminPagesContext } from "../contexts/SelectedAdminPagesContext";
import { translation } from "../translations/Translations";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { DealerContext } from "../contexts/DealerContext";

export const theme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "24px",
        textAlign: "center",
      },
    },
    MuiTypography: {
      subheading: {
        fontSize: "13px",
        textAlign: "left",
        fontWeight: "normal",
      },
    },
    MuiListItem: {
      root: {
        gridGap: "1rem",
        padding: "16px",
        paddingTop: "8px",
        paddingBottom: "8px",
        overflow: "hidden",
      },
      gutters: {
        paddingLeft: "21px",
        paddingRight: "21px",
      },
    },
    MuiListItemText: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "0",
        width: "100%",
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 0,
      },
    },
    MuiDrawer: {
      paper: {
        height: "100%",
        justifyContent: "center",
      },
    },
  },
});

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    drawerPaper: {
      position: "unset",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gridGap: "1rem",
      lineHeight: 0,
      padding: "16px",
      paddingBottom: "12px",
      paddingTop: "12px",

      "& img": {
        width: "110px",
        height: "95px",
      },
    },
    logoSmall: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "12px",
      paddingBottom: "12px",
      lineHeight: 0,

      "& img": {
        width: "42px",
        height: "42px",
      },
    },
    closeIcon: {
      cursor: "pointer",
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9,
      },
    },
    toolbar: toolbar(theme),
    selected: {
      backgroundColor: theme.palette.background.paper,
    },
    hideToolbar: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
    menu: {
      paddingTop: "6px",
      overflowY: "scroll",
    },
    drawer: {
      color: "#191919",

      "&:focus": {
        backgroundColor: "#191919",
        color: "white",
      },

      "&:active": {
        backgroundColor: "#191919",
        color: "white",
      },
    },
    menuItems: {
      paddingRight: "1rem",
    },
    collapseItem: {
      backgroundColor: "#efefef",
    },
    menuIcon: {
      display: "flex",
      paddingLeft: "16px",
      fontWeight: "bold",
      paddingBottom: "1rem",
      paddingTop: "1rem",
      color: "#191919",
    },
    subMenu: {
      paddingLeft: "50px",
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    nestedSubMenu: {
      paddingLeft: "4.5rem",
    },
    userProfile: {
      // position: "absolute",
      // bottom: 0,
      // width: "100%",
      // "@media (max-width: 1200px)": {
      //   position: "static",
      //   bottom: 0,
      //   left: 0,
      // },
      display: "flex",
      justifyContent: "center",
    },
    profileImage: {
      height: "24px",
      width: "24px",
      border: "solid 1px black",
    },
    userPopup: {
      zIndex: 9999999,
    },
    navMenu: {
      gridRow: "1/11",
    },
  });

export type DashboardDrawerProps = {
  setToggle: any;
  toggle: boolean;
  setPin: any;
  pin: boolean;
  open: boolean;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
} & WithStyles<typeof styles>;

type ItemProps = {
  src?: React.ReactElement<any>;
  label?: string;
  icon?: React.ReactElement<any>;
  location?: string;
  match?: any;
  onClick?(e?: any): void;
  disabled?: boolean;
  type?: "profile" | "sub-menu" | "default" | "nested-sub-menu";
  popperProps?: any[];
} & WithStyles<typeof styles>;

const defaultCollapsedGroups = {};
const defaultCollapsedAdminGroups = {};

const DashboardDrawerUnstlyed: React.FunctionComponent<DashboardDrawerProps> = ({
  classes,
  setToggle,
  toggle,
  pin,
  setPin,
  className,
  children,
  open,
  onClick,
}) => {
  const { tabs: accessTabs, loading } = React.useContext(TabAccessContext);
  const intl = useIntl();
  // Mobile
  const { width } = useViewport();
  const isMobile = width <= 920;
  // List item
  const Item: React.FunctionComponent<ItemProps> = ({ icon, onClick, match, location, label, disabled, classes, type = "default", popperProps = null }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const adjustedLocation = adjustLocation(location);

    return (
      <>
        <ListItem
          disabled={disabled}
          selected={
            (match.params.path == adjustedLocation.split("/")[1] && match.params.department == adjustedLocation.split("/")[2]) ||
            match.url == adjustedLocation ||
            match.params.dashboard == adjustedLocation ||
            false
          }
          button
          onClick={e => {
            if (popperProps) setAnchorEl(e.currentTarget);
            else {
              history.push(location);
              onClick();
            }
          }}
          className={
            (type == "sub-menu" && classes.subMenu) || (type == "profile" && classes.userProfile) || (type == "nested-sub-menu" && classes.nestedSubMenu)
          }
        >
          {
            <Tooltip title={label} placement="right">
              <ListItemIcon>{icon || null}</ListItemIcon>
            </Tooltip>
          }
          {toggle && (
            <div>
              <ListItemText primary={label} className={classes.menuItems} />
            </div>
          )}
          {popperProps && (
            <ListItemIcon>
              <Right />
            </ListItemIcon>
          )}
        </ListItem>
        {popperProps && (
          <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} className={classes.userPopup} transition placement="right-start">
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    {popperProps.map((item, i) => {
                      return (
                        <ListItem
                          // selected={match.url == location || match.params.dashboard == location || false}
                          button
                          onClick={e => {
                            history.push(item.to);
                            setSelectedAdminPages([...selectedAdminPages, item]);
                            isMobile ? setToggle(!toggle) : onClick;
                          }}
                        >
                          <div>
                            <ListItemText primary={intl.formatMessage({ id: item.label })} className={classes.menuItems} />
                          </div>
                        </ListItem>
                      );
                    })}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </>
    );
  };
  //
  const { user } = React.useContext(UserContext);
  const { getPermissions, loading: permissionsLoading } = React.useContext(PermissionsContext);
  const { selectedAdminPages, setSelectedAdminPages } = useContext(SelectAdminPagesContext);
  const permissionsData = getPermissions();
  const { selected } = useContext(DealerContext);

  Object.values(locations).map(location => {
    defaultCollapsedGroups[location.url.slice(1)] = false;
  });

  Object.values(adminLocations).map(location => {
    defaultCollapsedAdminGroups[location.url.slice(1)] = false;
  });

  const addPermissionsToChildren = (items, child) => {
    if (child.children.length > 0) {
      const hasPermission = doChildrenHavePermissions(child.children);
      items.children.push({
        ...child,
        hasPermission: child.id === "cube_reload" ? user.reloadAccess : hasPermission,
        ...(child.children || []).reduce(addPermissionsToChildren, {
          count: 0,
          children: [],
        }),
      });
      items.count = hasPermission ? items.count + 1 : items.count;
      return items;
    }
    const hasPermission = !!permissionsData.find(perm => perm.moduleName == child.id && (perm.canRead || perm.canExec || perm.canWrite));
    items.children.push({
      ...child,
      hasPermission: child.id === "cube_reload" ? user.reloadAccess : hasPermission,
      ...(child.children || []).reduce(addPermissionsToChildren, {
        count: 0,
        children: [],
      }),
    });
    items.count = hasPermission ? items.count + 1 : items.count;
    return items;
  };

  const doChildrenHavePermissions = children => {
    const flattenChildren = children.reduce((all, child) => {
      if (child.children.length > 0) {
        all.push(child.children.map(c => c.id));
      }
      all.push(child.id ? child.id : []);
      return all.flat();
    }, []);
    const hasPermission = flattenChildren.find(child => {
      return !!permissionsData.find(perm => perm.moduleName == child && (perm.canRead || perm.canExec || perm.canWrite));
    });

    return hasPermission == undefined ? false : true;
  };

  const availLocations = Object.values(accessTabs).map((tab: any) => tab.name);

  const addPermissions = adminLocations => {
    return Object.entries(adminLocations).reduce((locations, [location, locationConfig]: any) => {
      locations[location] = {
        ...locationConfig,
        hasPermission: doChildrenHavePermissions(locationConfig.children),
        ...locationConfig.children.reduce(addPermissionsToChildren, {
          count: 0,
          children: [],
        }),
      };
      return locations;
    }, {});
  };
  const adminLocationsWithPermissions = addPermissions(adminLocations);

  const [collapsed, setCollapsed] = React.useState<{}>({ ...defaultCollapsedGroups, [location.pathname.split("/")[1]]: true });
  const [collapsedAdmin, setCollapsedAdmin] = React.useState<{}>({ ...defaultCollapsedAdminGroups, [location.pathname.split("/")[1]]: true });

  return (
    <MuiDashboardDrawer
      variant="persistent"
      ModalProps={{
        hideBackdrop: true,
      }}
      anchor="left"
      open={open}
      className={classes.navMenu}
    >
      <MuiThemeProvider theme={theme}>
        {!toggle ? (
          <div className={classes.logoSmall}>
            <a href="/dashboard">
              <img src={msx} />
            </a>
          </div>
        ) : (
          <div className={classes.logo}>
            <div>
              <a href="/dashboard">
                <img src={Insights} />
              </a>
            </div>
          </div>
        )}
        <div className={classes.menu}>
          <Switch>
            <Route
              path="/admin/:rest?"
              render={({ match }) =>
                Object.entries(adminLocationsWithPermissions).map(([location, details]) => {
                  if ((details as any).hasPermission) {
                    const expandedLocation = (details as any).url.slice(1);
                    return (
                      <React.Fragment key={location}>
                        <Item
                          location={(details as any).to}
                          label={intl.formatMessage({ id: (details as any).label })}
                          icon={<span className={classes.drawer}>{(details as any).src as React.ReactElement}</span>}
                          match={match}
                          classes={classes}
                          onClick={() => {
                            setCollapsedAdmin({ ...collapsedAdmin, [expandedLocation]: !collapsedAdmin[expandedLocation] });
                          }}
                        />
                        {adminLocationsWithPermissions[location].children
                          .filter(a => a.hasPermission || a.count > 0)
                          .map(group => {
                            return (
                              <Collapse key={group.id} in={collapsedAdmin[expandedLocation]} timeout="auto" unmountOnExit>
                                <Item
                                  onClick={() => {
                                    setSelectedAdminPages([...selectedAdminPages, group]);
                                    isMobile ? setToggle(!toggle) : onClick;
                                  }}
                                  match={match}
                                  label={intl.formatMessage({ id: group.label })}
                                  location={group.to}
                                  classes={classes}
                                  type="sub-menu"
                                  popperProps={group.children.length && !isMobile ? group.children.filter(child => child.hasPermission) : null}
                                />

                                {isMobile && group.children.length > 0 && (
                                  <>
                                    <Divider />
                                    {group.children.map((child, i) => {
                                      return (
                                        <Item
                                          key={`${child.label} - ${i}`}
                                          label={translation[child.label] ? intl.formatMessage({ id: translation[child.label] }) : child.label}
                                          match={match}
                                          location={child.to}
                                          classes={classes}
                                          onClick={() => {
                                            setSelectedAdminPages([...selectedAdminPages, child]);
                                            isMobile ? setToggle(!toggle) : onClick;
                                          }}
                                          type="nested-sub-menu"
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </Collapse>
                            );
                          })}
                      </React.Fragment>
                    );
                  }
                })
              }
            />
            <Route
              render={({ match }) => {
                return Object.entries(locations).map(([location, details]) => {
                  if (
                    availLocations.find(loc => loc.includes(details.label)) ||
                    (availLocations.includes("Dashboard / Custom") && details.label == "Custom Dashboard")
                  ) {
                    const expandedLocation = details.url.slice(1);
                    return (
                      <React.Fragment key={location}>
                        <Item
                          location={details.to}
                          label={translation[details.label] ? intl.formatMessage({ id: translation[details.label] }) : details.label}
                          icon={<span className={classes.drawer}>{details.src as React.ReactElement}</span>}
                          match={match}
                          classes={classes}
                          onClick={() => {
                            if (isMobile) {
                              setToggle(true);
                            }
                            setCollapsed({ ...collapsed, [expandedLocation]: !collapsed[expandedLocation] });
                          }}
                        />

                        {locations[location].children.map(group => {
                          if (checkPermission(availLocations, group.id, group.children)) {
                            if (group.id == "Submission / Submit Data" && selected.disabledDealerUpload) {
                              return;
                            }
                            if (group.children.length > 0) {
                              defaultCollapsedGroups[group.label.toLowerCase().replace(" ", "-")] = false;
                            }

                            return (
                              toggle && (
                                <Collapse key={group.id} in={collapsed[expandedLocation]} timeout="auto" unmountOnExit>
                                  <Item
                                    onClick={() => {
                                      isMobile ? setToggle(!toggle) : onClick;
                                    }}
                                    match={match}
                                    label={translation[group.label] ? intl.formatMessage({ id: translation[group.label] }) : group.label}
                                    location={group.to}
                                    classes={classes}
                                    type="sub-menu"
                                    popperProps={group.children.length && !isMobile ? group.children.filter(child => availLocations.includes(child.id)) : null}
                                  />

                                  {isMobile && group.children.length > 0 && (
                                    <>
                                      <Divider />
                                      {group.children.map((child, i) => (
                                        <Item
                                          key={i}
                                          label={`${child.label}`}
                                          match={match}
                                          location={child.to}
                                          classes={classes}
                                          onClick={() => {
                                            isMobile ? setToggle(!toggle) : onClick;
                                          }}
                                          type="nested-sub-menu"
                                        />
                                      ))}
                                    </>
                                  )}
                                </Collapse>
                              )
                            );
                          }
                          return null;
                        })}
                      </React.Fragment>
                    );
                  }
                });
              }}
            />
          </Switch>
          {/* <Route
            path="/:dashboard/:rest?"
            render={({ match }) => (
              <div className={classes.userProfile}>
                <Item label={`${user.firstname} ${user.lastname}`} match={match} classes={classes} onClick={() => {}}>
                  <Avatar
                    className={classes.profileImage}
                    src={`/api/whoami/avatar.jpg?cachebuster=Thu%20Apr%2028%202022%2011:41:27%20GMT+0200%20(South%20Africa%20Standard%20Time)`}
                  />
                </Item>
              </div>
            )}
          /> */}
        </div>
      </MuiThemeProvider>
    </MuiDashboardDrawer>
  );
};

export const DashboardDrawer = withStyles(styles)(DashboardDrawerUnstlyed);
function handleWindowResize() {
  throw new Error("Function not implemented.");
}

const adjustLocation = (location: string) => {
  if (location == "/oem/fixed/home") {
    return "/oem/fixed";
  }

  if (location == "/oem/dynamic/home") {
    return "/oem/dynamic";
  }

  return location;
};

const checkPermission = (availLocations: string[], location: string, children?: any[]) => {
  if (children.length > 0) {
    const access = children.reduce((acc, child) => {
      if (availLocations.find(loc => child.id == loc)) {
        acc++;
        return acc;
      }
      return acc;
    }, 0);
    return access > 0;
  }
  return availLocations.find(loc => location == loc) != undefined;
};
