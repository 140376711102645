import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const fordSAScorecard: { [index: string]: CustomDashboardType } = {
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence Headcount": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence Headcount",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence Headcount",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Uncertified)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Uncertified)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Uncertified)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Uncertified | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Induction)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Induction)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Induction)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Induction | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Silver)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Silver)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Silver)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Silver | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Gold)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Gold)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Gold)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Gold | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Platinum)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Platinum)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Platinum)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Platinum | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Sales Executive": {
    name: "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Sales Executive",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Percentage of PBR Sessions Completed - Sales Executive",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Sales Executive | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Service Advisor": {
    name: "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Service Advisor",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Percentage of PBR Sessions Completed - Service Advisor",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Service Advisor | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Business Management > Retained Cash": {
    name: "Ford SA Scorecard > Business Management > Retained Cash",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "Retained Cash",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Bank Accounts | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Business Management > ROA": {
    name: "Ford SA Scorecard > Business Management > ROA",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "ROA",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Total Assets (ROA) | All Department | All Brand | All Model | | AVG",
  },
  "Ford SA Scorecard > Business Management > EBIT": {
    name: "Ford SA Scorecard > Business Management > EBIT",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "EBIT",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings Before Interest (EBIT) | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Business Management > Working Capital Days": {
    name: "Ford SA Scorecard > Business Management > Working Capital Days",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "Working Capital Days",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital Days | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > FSCD/Service > Average WIP Days CYTD": {
    name: "Ford SA Scorecard > FSCD/Service > Average WIP Days CYTD",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Average WIP Days CYTD",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data - Average WIP Days CYTD | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > FSCD/Service > Service Retention (%)": {
    name: "Ford SA Scorecard > FSCD/Service > Service Retention (%)",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Service Retention (%)",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Service Market > YTD Service Retention | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR against national average": {
    name: "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR against national average",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Ranger Accessory Sales PNUR against national average",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessory Sales PNUR % Network Achievement | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Market Share %": {
    name: "Ford SA Scorecard > Ford Credit > Market Share %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Market Share %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Ford Options Penetration % of new contracts": {
    name: "Ford SA Scorecard > Ford Credit > Ford Options Penetration % of new contracts",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Ford Options Penetration % of new contracts",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Option % Total Contracts | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > New Approval Rate %": {
    name: "Ford SA Scorecard > Ford Credit > New Approval Rate %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "New Approval Rate %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Finance Approval Rate % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > New Take-Up Ratio %": {
    name: "Ford SA Scorecard > Ford Credit > New Take-Up Ratio %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "New Take-Up Ratio %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance % Total Approved | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) only New": {
    name: "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) only New",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Commercial (Nr of Juristic contracts) only New",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Commercial Contracts | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Ford Lease (New)": {
    name: "Ford SA Scorecard > Ford Credit > Ford Lease (New)",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Ford Lease (New)",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Contracts | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Retention %": {
    name: "Ford SA Scorecard > Ford Credit > Retention %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Retention %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Customers Retention % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Wholesale Rebate %": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale Rebate %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Wholesale Rebate %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Wholesale Rebate % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Wholesale Audits (% violations)": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale Audits (% violations)",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Wholesale Audits (% violations)",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sold out of trust % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Credit > Current ratio": {
    name: "Ford SA Scorecard > Ford Credit > Current ratio",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Current ratio",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Ford Credit > Profit before tax": {
    name: "Ford SA Scorecard > Ford Credit > Profit before tax",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Profit before tax",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Ford Protect > Extended Business Penetration": {
    name: "Ford SA Scorecard > Ford Protect > Extended Business Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "Extended Business Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Optional Service Plan Penetration | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Protect > RTR Service and Maintenance Penetration": {
    name: "Ford SA Scorecard > Ford Protect > RTR Service and Maintenance Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "RTR Service and Maintenance Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > RTR Serv. & Maint. Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Ford Protect > Extended Warranty Penetration": {
    name: "Ford SA Scorecard > Ford Protect > Extended Warranty Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "Extended Warranty Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Extended Warranty Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Dealer Satisfaction - Sales": {
    name: "Ford SA Scorecard > OneCX > Dealer Satisfaction - Sales",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Dealer Satisfaction - Sales",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Sales (unit) | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Dealer Satisfaction - Service": {
    name: "Ford SA Scorecard > OneCX > Dealer Satisfaction - Service",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Dealer Satisfaction - Service",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Service (unit) | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Sales Experience Index": {
    name: "Ford SA Scorecard > OneCX > Sales Experience Index",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Experience Index",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales CEI (unit) | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Service Experience Index": {
    name: "Ford SA Scorecard > OneCX > Service Experience Index",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Experience Index",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service CEI (unit) | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > FRFT": {
    name: "Ford SA Scorecard > OneCX > FRFT",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "FRFT",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD CVP Service - FRFT% | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Sales Dealer Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Sales Dealer Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Dealer Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Sales Brand Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Sales Brand Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Brand Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Service Dealer Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Service Dealer Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Dealer Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > OneCX > Service Brand Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Service Brand Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Brand Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Sales > Gross Profit": {
    name: "Ford SA Scorecard > Sales > Gross Profit",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Gross Profit",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Sales > Return on Sales %": {
    name: "Ford SA Scorecard > Sales > Return on Sales %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Return on Sales %",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Sales > New Vehicle Sales - Act vs Obj": {
    name: "Ford SA Scorecard > Sales > New Vehicle Sales - Act vs Obj",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "New Vehicle Sales - Act vs Obj",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Target % Achieved - New Total Units | New | Ford | All Model | | SUM",
  },
  "Ford SA Scorecard > Sales > Used Vehicle Sales": {
    name: "Ford SA Scorecard > Sales > Used Vehicle Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Used Vehicle Sales",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Used | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Sales > Used to New Vehicle Ratio": {
    name: "Ford SA Scorecard > Sales > Used to New Vehicle Ratio",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Used to New Vehicle Ratio",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Used : New | All Department | All Brand | All Model | | SUM",
  },
  "Ford SA Scorecard > Sales > Sales conversion rates": {
    name: "Ford SA Scorecard > Sales > Sales conversion rates",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales conversion rates",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Conversion Rate | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Sales > Sales by Salesperson": {
    name: "Ford SA Scorecard > Sales > Sales by Salesperson",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales by Salesperson",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units per Productive | New | All Brand | All Model | | AVG",
  },
  "Ford SA Scorecard > Sales > Market Share": {
    name: "Ford SA Scorecard > Sales > Market Share",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Market Share",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New | All Brand | All Model | | CLOSE",
  },
  "Ford SA Scorecard > Sales > Dealer Share of Brand Sales": {
    name: "Ford SA Scorecard > Sales > Dealer Share of Brand Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Share of Brand Sales",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Share of Brand Sales | New | All Brand | All Model | | CLOSE",
  },
};
