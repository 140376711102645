import React, { createContext, useReducer, useMemo } from "react";
import { Metric, MetricReport, Query, stringifyQuery, metricFor, oemMetricFor } from "../lib/api/charts";
import { Loading } from "./base";

type MetricSet = { metric: Metric; loading: Loading };

export type MetricContextState = {
  metric: {
    [index: string]: MetricSet;
  };
  getMetric(report: MetricReport, query: Query): Partial<MetricSet>;
};

export const MetricContext = createContext<MetricContextState>({
  metric: {},
  getMetric: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<MetricSet>;
};

const defaultValue = {
  value: 0,
  formattedValue: "",
};

const defaultMetric: MetricSet = {
  metric: {
    title: "",
    actualTitle: "",
    classification: "income",
    benchmark: defaultValue,
    variance: defaultValue,
    actual: defaultValue,
    median: defaultValue,
    previous: defaultValue,
    target: defaultValue,
    targetVariance: defaultValue,
  },
  loading: { loaded: false, loading: true, error: null },
};

export const MetricProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query) => `${report}-${stringifyQuery(query)}`;

  const [metric, dispatchMetric] = useReducer((state: { [index: string]: MetricSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      metric,
      getMetric: (report: MetricReport, query: Query) => {
        const id = memo(report, query);
        const set = metric[id];

        if (set) {
          return set;
        }

        dispatchMetric({
          type: "PATCH_METRIC",
          id,
          data: defaultMetric,
        });

        oemMetricFor(query, report)
          .then(metric => {
            dispatchMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                metric: metric,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultMetric;
      },
    }),
    [metric],
  );
  return <MetricContext.Provider value={value}>{children}</MetricContext.Provider>;
};
